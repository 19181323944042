import ImgCard from "../../../componenets/common/ImgCard"
import Heading1 from "../../../componenets/semantic/Heading1"
import Typography from "../../../componenets/semantic/Typography";
import bg from '../../../config/bg-2.png'
import { HiUserGroup } from "react-icons/hi2";
import { MdOutlineSupportAgent } from "react-icons/md";
import { motion } from 'framer-motion'

const BestService = () => {
    return <div className="flex flex-col-reverse lg:flex-row items-center justify-between gap-[20px]">
        <motion.div
            initial={{
                opacity: 0,
                x: -50
            }}
            whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                    duration: 2
                }
            }}
            viewport={{ once: true }}
            className="lg:w-[35%]"
        >
            <Heading1 className="text-3xl lg:text-4xl font-bold">
                You Will Get Best<br />
                Quality <span className="relative">
                    Service
                    <span className="absolute bottom-[1px] opacity-80 right-0 left-0 h-2 bg-[#124E66] rounded-lg"></span>
                </span>
            </Heading1>
            <aside className="mt-10">
                <div className="flex items-center gap-8">
                    <div className="bg-[#124E66] text-[white] rounded-full p-2 justify-between flex items-center w-[40px] h-[40px]"><HiUserGroup className="text-2xl mx-[auto]" /></div>
                    <div>
                        <Typography className="font-bold text-xl">Dedicated Team</Typography>
                        <Typography className="font-light text-md">Our dedicated team of experts is committed to delivering tailored solutions, ensuring your project's success with precision and efficiency.</Typography>
                    </div>
                </div>
                <div className="flex items-center gap-8 mt-4">
                    <div className="bg-[#124E66] text-[white] rounded-full p-2 justify-between flex items-center w-[40px] h-[40px]"><MdOutlineSupportAgent className="text-2xl mx-[auto]" /></div>
                    <div>
                        <Typography className="font-bold text-xl">24/7 Hours Support</Typography>
                        <Typography className="font-light text-md">We provide round-the-clock support, ensuring your business operates smoothly with timely assistance and troubleshooting whenever you need it.</Typography>
                    </div>
                </div>
            </aside>
        </motion.div>
        <section className="lg:w-[60%]">
            <ImgCard className='' img={bg} />
        </section>
    </div>
}

export default BestService