import React from 'react';

interface YoutubeProps{
    videoId:string
}

const YouTubeVideo:React.FC<YoutubeProps> = ({ videoId }) => {
  return (
    <div>
      <iframe
        className='rounded-custom w-full h-[50rem]'
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeVideo;
