import React from 'react'
import Typography from '../../../componenets/semantic/Typography'
import { IoMdLink } from "react-icons/io";
import { FaTwitter } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { IoLogoFacebook } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa6";


interface EmployeeProps{
    pic:string,
    name:string,
    position:string
}

const Employee:React.FC<EmployeeProps> = ({pic,name,position}) => {
  return (
<div className='border border-1 flex justify-between my-6 gap-4 flex-col lg:flex-row border-gray-200 p-7 rounded-custom'>
        <div className='flex gap-5 items-center'>
            <img className='w-16 rounded-full' src={pic}/>
            <span>
            <Typography className='font-bold'>{name}</Typography>
            <Typography className='text-sm font-light'>{position}</Typography>
            </span>
        </div>
        <div className='flex gap-4 items-center text-2xl'>
            <a href='https://linkedin.com/' className='border border-1 p-5 rounded-full border-gray-200 hover:bg-hoverWhite'><FaLinkedin/></a>
            <a href='https://twitter.com/' className='border border-1 p-5 rounded-full border-gray-200 hover:bg-hoverWhite'><FaTwitter/></a>
            <a href='https://instagram.com/' className='border border-1 p-5 rounded-full border-gray-200 hover:bg-hoverWhite'><RiInstagramFill/></a>
            <a href='https://facebook.com/' className='border border-1 p-5 rounded-full border-gray-200 hover:bg-hoverWhite'><IoLogoFacebook/></a>
            </div>
    </div>
  )
}

export default Employee