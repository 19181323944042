import Typography from '../semantic/Typography'
import Faq from '../../pages/Contact/components/Faq'
import { faqs } from '../../config/config'
import faq from '../../assets/faq.jpg'
import { motion } from 'framer-motion'
import Bubbles from '../../pages/Homepage/components/Bubbles'

const FAQ = () => {
  return (
    <div>
      <div className='relative'>
      <div className='absolute hidden lg:block top-[150px] left-[-100px]'>
          <Bubbles />
        </div>
        <Typography className='text-3xl lg:text-4xl font-bold text-center mb-4 mt-40'>Frequently Asked {" "}
          <span className='relative'>
            Questions
            <span className="absolute bottom-[1px] opacity-80 right-0 left-0 h-2 bg-[#124E66] rounded-lg"></span>
          </span>
        </Typography>
        <div className='hidden lg:block absolute top-0 right-[0px]'>
          <Bubbles />
        </div>
      </div>
      <Typography className='font-light text-md text-center mt-4 mx-auto lg:w-[40%]'>Discover solutions to common queries about Code Samadhan's innovative services, ensuring clarity and support for all your needs.</Typography>
      <div className='mt-10 flex flex-col lg:flex-row justify-between w-full items-center gap-20'>
        <div className='hidden lg:block lg:w-[50%]'><img src={faq} /></div>
        <motion.div
          initial={{
            opacity: 0,
            x: 50
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 2
            }
          }}
          viewport={{ once: true }}
          className="lg:w-[50%]"
        >
          {
            faqs.map((faq: any, index: number) => {
              return <Faq quesion={faq.question} answer={faq.answer} />
            })
          }
        </motion.div>
      </div>
    </div>
  )
}

export default FAQ