import React from 'react'
import Button from '../semantic/Button'
interface BannerProps {
    img: string,
    title: string,
    desc: string,
    onClick?: any
}

const BannerCard: React.FC<BannerProps> = ({ img, title, desc, onClick }) => {
    return (
        <div className="w-full">
            <div className="w-full rounded-[19px] overflow-hidden border border-gray-200 pb-4">
                    <img className="w-[100%] mx-auto h-[auto]" src={img} alt="Blog image"></img>
                <div className="px-6 py-4 mt-4">
                    <div className="text-xl text-left mb-2 w-full text-ellipsis">{title}</div>
                    <div dangerouslySetInnerHTML={{ __html: desc }}></div>
                </div>
                <Button onClick={onClick} title='Learn More' className='m-4 !mb-0' />
            </div>
        </div>
    )
}

export default BannerCard