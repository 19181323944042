import Modal from "../../../componenets/common/Modal"
import InputButton from "../../../componenets/semantic/InputButton"
import Typography from "../../../componenets/semantic/Typography"
import support from '../../../config/support.png'
import { useForm } from "@formspree/react"
import ThankYouModal from "../../Contact/ThankYouModal"

const ConnectWithUs = () => {
    const [state, handleSubmit, ResetFunction] = useForm("mayrgjvg");

    return <>
        <Modal isOpen={state.succeeded} onClose={() => ResetFunction()}>
            <div className='flex justify-center items-center'>
                <ThankYouModal />
            </div>
        </Modal>
        <div className="bg-[#124E66] rounded-lg text-white w-full p-10 flex flex-col lg:flex-row justify-between items-center gap-10 mt-20">
            <form onSubmit={handleSubmit} className="lg:w-[50%]">
                <Typography className="font-bold text-3xl lg:text-4xl mb-10">How about getting<br /> in touch with us?</Typography>
                <Typography className="font-light text-sm mb-6">
                    At Code Samadhan, we are committed to providing you with top-notch solutions tailored to your business needs. If you have any questions, project inquiries, or wish to discuss how we can assist you, we invite you to connect with us. Simply provide your email address in the input box below, and our team will reach out to you shortly.
                    <br /><br />Our dedicated team of experts is eager to understand your requirements and offer personalized advice and solutions. By sharing your email, you allow us to initiate a conversation, ensuring that we can address your needs promptly and efficiently. We believe in fostering strong client relationships through clear communication and a focus on delivering exceptional results.
                    <br />Take the first step towards a successful partnership with Code Samadhan. Enter your email address, and let's start the conversation. We look forward to connecting with you and helping your business thrive.
                </Typography>
                <InputButton id='email' name='email' button='Send' className='w-64 lg:w-96' placeholder='Your Email Address' />
            </form>
            <div className="lg:w-[50%] relative">
                <img src={support} className="" />
            </div>
        </div>
    </>
}

export default ConnectWithUs