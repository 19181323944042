import React from 'react'
import Typography from '../semantic/Typography'
import { IoLogoFacebook, IoMdLink } from 'react-icons/io'
import { FaLinkedin, FaPhone, FaTwitter } from 'react-icons/fa6'
import { RiInstagramFill } from 'react-icons/ri'
import { FaYoutube } from "react-icons/fa";
import InputButton from '../semantic/InputButton'
import { MdOutlineMail } from 'react-icons/md'
import { GiWaterDrop } from 'react-icons/gi'
import { contactInfo } from '../../config/config'
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom'


const Footer = () => {
    return (
        <div className='flex flex-col lg:flex-row my-20 flex-wrap gap-8'>
            <div className='flex-1'>
                <div className='flex gap-3'>
                    <img src={logo} className='w-32 ml-[-10px]' />
                </div>
                <Typography className='text-sm text-gray-600 py-3 leading-6'>Elevate your enterprise with Code Samadhan's premier software engineering solutions. Tailored for both burgeoning startups and established mid-market firms, our services deliver scalable, cutting-edge innovations poised to vault you beyond industry rivals.</Typography>
                <div className='flex gap-4 items-center text-xl'>
                    <a href='https://www.linkedin.com/company/code-samadhan' className='border border-1 p-2 rounded-full border-gray-200 hover:bg-[#124E66] hover:text-[white]'><FaLinkedin /></a>
                    <a href='https://www.youtube.com/@codesamadhan' className='border border-1 p-2 rounded-full border-gray-200 hover:bg-[#124E66] hover:text-[white]'><FaYoutube /></a>
                </div>
            </div>

            <div className='flex-1 hidden lg:block text-center text-gray-600'>
                <Typography className='font-bold mb-4 text-black'>Pages</Typography>
                <Link to={'/home'}><Typography>Home</Typography></Link>
                <Link to='/about'><Typography>About</Typography></Link>
                {/* <Link to='/projects'><Typography>Project</Typography></Link> */}
                <Link to='/services'><Typography>Services</Typography></Link>
                <Link to='/blogs'><Typography>Blogs</Typography></Link>
                <Link to='/contact'><Typography>Contact</Typography></Link>
            </div>

            <div className='flex-1'>
                {/* <Typography className='text-xl'>How about contacting us?</Typography>
                <Typography className='text-sm text-gray-600 py-3 pb-4'>Reach out to us easily and conveniently for any inquiries or assistance</Typography>
                    <InputButton button='Send' placeholder='Your Email'/> */}
                <Typography className='flex justify-end items-center gap-3 mt-5 '><FaPhone />{contactInfo.phone}</Typography>
                <Typography className='flex justify-end items-center gap-3 py-4'><MdOutlineMail />{contactInfo.email}</Typography>
                <Typography className='flex justify-end items-center gap-3'><GiWaterDrop className='rotate-180' />{contactInfo.address}</Typography>
            </div>
        </div>
    )
}

export default Footer