import React, { ChangeEvent } from 'react';

export type InputProps = {
  label?: string;
  id?: string;
  name?: string;
  value?: string|number;
  placeholder?: string,
  type?: string,
  className?: string,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  keyDownValidation?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
  onclick?: (event: React.MouseEvent) => void,
  message? : string,
  validationType?:string;
  disabled?:boolean,
}

const CustomInput: React.FC<InputProps> = ({ id, label,validationType="",value,onChange,type,...rest }) => {



  return (
    <div>
         {label && <label className='font-semibold' htmlFor={rest.name}>{label}</label>}
    <input
      {...rest}
      id={id}
      value={value}
      onChange={onChange}
      type={type}
      className=" appearance-none border focus:ring-2 focus:outline-none  rounded-xl w-full py-5 px-3 text-gray-700 leading-tight focus:shadow-outline"
      />
      </div> 
  );
};

export default CustomInput;
