import React, { ReactNode } from 'react'
import Typography from '../semantic/Typography';
import LearnMore from './LearnMore';

interface ServiceCardProps{
    icon:ReactNode,
    title:string,
    desc:string,
    className?:string,
    navigation:any
}

const ServiceCard:React.FC<ServiceCardProps> = ({icon,title,desc,className ,navigation}) => {
  return (
    <div className={`rounded-custom w-full cursor-pointer bottom-1 border-gray=200 border transition-all delay-100 hover:text-white overflow-hidden hover:bg-[#124E66] py-7 px-10 pt-12 ${className}`}>
            {icon} 
        <Typography className='font-semibold mt-4 mb-2'>{title}</Typography>
        <Typography className='text-sm opacity-75  mb-2'>{desc}</Typography>
        <LearnMore onClick={navigation} />
    </div>
  )
}

export default ServiceCard