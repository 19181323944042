import React, { useEffect } from 'react';
import Prism from 'prismjs';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import allRoutes from './routes/routes';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

function App() {
  useEffect(() => {
    Prism.highlightAll();

    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      window.dataLayer.push(args);
    }
    gtag('js', new Date());
    gtag('config', 'G-7DLX7JZLVJ');
  }, []);

  const routes = createBrowserRouter(allRoutes);
  return <RouterProvider router={routes} />;
}

export default App;
