import React, { ChangeEvent } from 'react';
import { motion } from 'framer-motion'

export type InputProps = {
    label?: string;
    name?: string;
    value?: string | number;
    placeholder?: string,
    type?: string,
    className?: string,
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
    keyDownValidation?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    onclick?: (event: React.MouseEvent) => void,
    message?: string,
    validationType?: string;
    disabled?: boolean,
    button:string
    id?:string
}

const InputButton: React.FC<InputProps> = ({ label, validationType = "",className, value, onChange, type,button, ...rest }) => {



    return (
        <div className={`relative ${className}`}>
            <input  {...rest}
                value={value}
                onChange={onChange}
                type={type} className="block w-full p-4 ps-6 ms:ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 " required />
            <motion.button whileTap={{ scale: 1.1 }} type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-[#124E66] font-medium rounded-lg text-sm px-4 py-2 ">{button}</motion.button>
        </div>
    );
};

export default InputButton;
