import FAQ from '../../componenets/common/FAQ'
import BestService from './components/BestService'
import ConnectWithUs from './components/ConnectWithUs'
import InitScreenNew from './components/InitScreenNew'
import PerfectSolution from './components/PerfectSolution'
import ServicesScreenNew from './components/ServicesScreenNew'
import TechList from './components/TechList'

const Homepage = () => {
  return (
    <div>
        <InitScreenNew /> 
        <ServicesScreenNew />
        <PerfectSolution />
        <BestService />
        <TechList />
        <FAQ />
        <ConnectWithUs />
    </div>
  )
}

export default Homepage