import React, { useState } from "react";
interface ImgCardProps {
    img:string,
    className?:string
}

const ImgCard: React.FC<ImgCardProps> = ({img ,className}) => {
  return (
    <img src={img}  className={`${className}`}/>
  );
};

export default ImgCard;
