import React from 'react'
import Typography from '../../componenets/semantic/Typography'
import ImgCard from '../../componenets/common/ImgCard'
import GetInTouch from '../../componenets/common/GetInTouch'
import FAQ from '../../componenets/common/FAQ'
import { useLocation, useParams } from 'react-router-dom'
import Employee from '../Team/component/Employee'

const SingleProject = () => {

    const { state } = useLocation();

    return (
        <div>
            <div className='flex flex-col lg:flex-row gap-5 justify-between items-center mt-24 mb-20'>
                <div className='flex-1'>
                    <Typography variant='h3'>Projects</Typography>
                    <Typography className='text-5xl'>{state.projects.title}</Typography>
                </div>
                <div className='flex-1'>
                    <Typography>Unlock the power of visionary design with our architectural expertise, shaping spaces that inspire and endure.</Typography>
                </div>
            </div>
            <ImgCard img='https://framerusercontent.com/images/ojkQ1aDToLAAK0wWwC30cpkpxg.png?scale-down-to=2048' />
            <div className='mt-12 px-8 lg:px-20'>
                <Typography className='text-gray-600 my-4'>{state.projects.desc}</Typography>
            </div>
            {/* <Employee pic={"'https://framerusercontent.com/images/ojkQ1aDToLAAK0wWwC30cpkpxg.png?scale-down-to=2048'"} name={emp.name} position={emp.position}/> */}
        </div>
    )
}

export default SingleProject