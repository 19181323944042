import React, { useState } from 'react';
import Typography from '../semantic/Typography';
import LearnMore from './LearnMore';

interface ImgProps {
  cardInfo?:any,
  navigation?:any
}

const ImgCardText: React.FC<ImgProps> = ({cardInfo,navigation}) => {
  const [showContent, setShowContent] = useState<boolean>(false);

  return (
    <div className=' border-2 lg:border-0 rounded-custom'>
    <div
      className="relative cursor-pointer rounded-custom overflow-hidden w-full"
      onMouseEnter={() => setShowContent(true)}
      onMouseLeave={() => setShowContent(false)}
    >
      <img
        src={cardInfo.img}
        alt="projects"
        className={`object-cover w-full h-full`}
      />
      {showContent && (
        <div className="hidden absolute backdrop-brightness-50 inset-0 gap-3 text-white  lg:flex flex-col pl-6 pt-36">
          <Typography className='text-lg'>{cardInfo.title}</Typography>
          <Typography className='text-sm pr-2'>{cardInfo.desc}</Typography>
          <LearnMore onClick={navigation}/>
        </div>
      )}
    </div>
      <div className='px-5 py-5 block lg:hidden border-t-0 rounded-custom'>
      <Typography className='text-lg font-semibold'>{cardInfo.title}</Typography>
          <Typography className='text-sm pr-2'>{cardInfo.desc}</Typography>
          <LearnMore onClick={navigation}/>
        </div>
      </div>
  );
};

export default ImgCardText;
