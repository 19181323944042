import React, { ReactNode } from 'react'

interface ContainerProps{
    children:ReactNode,
    className?:string
}

const Container:React.FC<ContainerProps> = ({children,className}) => {
  return (
    <div className={`bg-[#124E66] rounded-custom w-full p-7 lg:p-12 text-white ${className}`}>
        {children}
    </div>
  )
}

export default Container