import React from 'react'
import Typography from '../../componenets/semantic/Typography'
import ImgCard from '../../componenets/common/ImgCard'
import { blogInfo } from '../../config/config'
import BannerCard from '../../componenets/common/BannerCard'
import GetInTouch from '../../componenets/common/GetInTouch'
import { useNavigate } from 'react-router-dom'

const Blog = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className='flex flex-col lg:flex-row justify-between items-center mt-24 mb-20'>
        <div className='flex-1'>
          <Typography variant='h3'>BLOG</Typography>
          <Typography className='text-5xl'>Aticles & News</Typography>
        </div>
        <div className='flex-1'>
          <Typography>Explore <strong>Code Samadhan's</strong> blog for insightful articles and expert advice on software development, DevOps, frontend optimization, and backend architecture.</Typography>
        </div>
      </div>
      <ImgCard img='https://framerusercontent.com/images/X8futFYVd1yXNJZoH33FcV0D3M.png?scale-down-to=2048' className='w-full mt-10' />
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-20'>
        {blogInfo.map((banner, i) => (
          <BannerCard onClick={() => navigate(`/blogs/${banner.id}`)} key={i} img={banner.img} title={banner.title} desc={banner.description} />
        ))}
      </div>
      {/* <GetInTouch /> */}
    </>
  )
}

export default Blog