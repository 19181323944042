import React from 'react'
import Typography from '../../componenets/semantic/Typography'
import ImgCard from '../../componenets/common/ImgCard'
import { pastProject } from '../../config/config'
import ImgCardText from '../../componenets/common/ImgCardText'
import { useNavigate } from 'react-router-dom'

const Projects = () => {
  const navigate =useNavigate()

  return (
    <>
      <div className='flex flex-col gap-7 lg:flex-row items-center justify-between mt-24 mb-20'>
        <div className='flex-1'>
          <Typography variant='h3'>PROJECTS</Typography>
          <Typography className='text-5xl'>How about taking a look at all the projects</Typography>
        </div>
        <div className='flex-1'>
          <Typography>From commercial buildings to residential developments, our construction company takes pride in delivering excellence, ensuring every project is a true testament to our commitment to quality and client satisfaction.</Typography>
        </div>
      </div>
      <ImgCard img='https://framerusercontent.com/images/X8futFYVd1yXNJZoH33FcV0D3M.png?scale-down-to=2048' className='w-full mt-10' />
      <div className='grid grid-col-1 lg:grid-cols-2 lg:grid-cols-3 gap-5 mt-8'>
    {pastProject.map(obj=> <ImgCardText cardInfo={obj} navigation={()=>navigate(`/projects/${obj.title}`,{state:{projects:{title:obj.title,desc:obj.fulldesc}}})}/>)}
        </div>  
    </>
    
  )
}

export default Projects