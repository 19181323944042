import React from 'react'
import Typography from '../../componenets/semantic/Typography'
import Employee from './component/Employee'
import { employeeDetail } from '../../config/config'

const Team = () => {
  return (
    <div>
      <Typography className='text-center text-3xl'>All Team</Typography>
      <Typography className='text-center text-gray-600'>Meet our talented team of construction professionals, united by a passion for excellence and a shared commitment to delivering exceptional projects that surpass expectations.</Typography>

    {employeeDetail.map((emp,i)=><Employee key={i} pic={emp.profilePic} name={emp.name} position={emp.position}/>)}
    </div>
  )
}

export default Team