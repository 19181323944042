import React, { useState } from "react";
import { motion } from 'framer-motion';
interface ButtonOutlineIconProps {
  title: any;
  icon?: React.ReactNode,
  color?: string,
  className?: string,
  onClick?: () => void,
  type?: "button" | "submit" | "reset"
}

const Button: React.FC<ButtonOutlineIconProps> = ({ title, icon, color, className, type, onClick }) => {

  return (
    <div className="flex items-center w-auto" >
      <motion.button
        whileTap={{ scale: 1.1 }}
        onClick={onClick}
        type={type || "button"}
        className={`hover:!bg-[#124E66] flex justify-center flex-row text-white bg-[#124E66] hover:bg-[#124E66] focus:bg-[#124E66] items-center gap-1 border font-semibold rounded-lg text-sm px-4 py-2 text-center me-2 ${className}`}>
        {icon}
        {title}
      </motion.button>
    </div>
  );
};

export default Button;
