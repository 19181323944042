import { useEffect, useState } from 'react'
import Typography from '../../componenets/semantic/Typography'
import GetInTouch from '../../componenets/common/GetInTouch'
import { useParams } from 'react-router-dom'
import { card } from '../../config/config'

const SingleService = () => {
    const [selectedState, setSelectedState] = useState<any>({})
    const params = useParams()

    useEffect(() => {
        const data = card.filter((c: any) => c.name === params.id);
        setSelectedState(data.length > 0 ? data[0] : {})
    }, [])

    return (
        <div>
            <div className='flex flex-col lg:flex-row gap-5 justify-between items-center mt-24 mb-20'>
                <div className='flex-1'>
                    <Typography variant='h3'>SERVICES</Typography>
                    <Typography className='text-5xl'>{selectedState.name}</Typography>
                </div>
                <div className='flex-1'>
                    <Typography>Unlock the power of visionary design with our architectural expertise, shaping spaces that inspire and endure.</Typography>
                </div>
            </div>
            <div className='mt-12 px-8 lg:px-20'>
                {
                    (selectedState?.childrenTech || []).map((tech: any, index: number) => {
                        return <>
                            <Typography className='font-bold text-gray-900 my-4'>{tech.title}</Typography>            
                            <div dangerouslySetInnerHTML={{ __html: tech.description }} style={{ opacity: 0.7 }}></div>
                        </>
                    })
                }
            </div>
            {/* <GetInTouch /> */}
        </div>
    )
}

export default SingleService