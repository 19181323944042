import Heading1 from "../../../componenets/semantic/Heading1"
import Typography from "../../../componenets/semantic/Typography"
import { methodsCard } from "../../../config/config"

const ServicesScreenNew = () => {
    return <div id='servicesScreen'>
        <div className="w-full mt-8">
            <section className="flex items-start justify-between w-full">
                <aside className="flex flex-col lg:flex-row justify-between items-start gap-4 lg:gap-20 w-full">
                    <Heading1 className="font-bold text-3xl lg:text-4xl w-full lg:w-[unset]">
                        Our <span className="relative">
                            Best Methods
                            <span className="absolute bottom-[1px] opacity-80 right-0 left-0 h-2 bg-[#124E66] rounded-lg"></span>
                        </span>
                        <br /> To Boost Your Business
                    </Heading1>
                    <Typography className="text-md w-full lg:w-[35%]">We specialize in transforming client challenges into high-quality products with enhanced aesthetics</Typography>
                </aside>
            </section>
            <section className="grid grid-col-1 lg:grid-cols-2 lg:grid-cols-3 gap-8 mt-20 mb-20 mb-4">
                {
                    methodsCard.map((card, index) => (
                        <aside key={index} className={`p-4 cursor-pointer rounded-lg mb-4 ${card.bgHover}`}>
                            <div className={`p-2 flex justify-center items-center mb-4 rounded-lg h-[50px] w-[50px] ${card.bg} ${card.col}`}>
                                {card.icon}
                            </div>
                            <Typography className="font-bold text-lg mb-2">{card.name}</Typography>
                            <Typography className="font-light text-md">{card.description}</Typography>
                        </aside>
                    ))
                }
            </section>
        </div>
    </div>
}

export default ServicesScreenNew