import React, { useState } from 'react'
import Typography from '../../../componenets/semantic/Typography'
import { IoMdAdd } from "react-icons/io";
import { GoDash } from "react-icons/go";

interface FaqProps{
    quesion:string,
    answer:string
}

const Faq:React.FC<FaqProps> = ({quesion,answer}) => {
    const [showAnswer,setShowAnswer]=useState<boolean>(false)
  return (
    <div className='border border-1 border-gray-200 mb-4 p-4 px-5 rounded-custom'>
        <Typography className='flex justify-between items-center cursor-pointer' onClick={()=>setShowAnswer(!showAnswer)}>{quesion}<span className=''>{showAnswer?<GoDash/>:<IoMdAdd/>}</span></Typography>
        <Typography className={`${showAnswer?"block":"hidden"} mt-2 text-gray-600 text-sm w-11/12`}>{answer}</Typography>
    </div>
  )
}

export default Faq