import React from 'react'
import Typography from '../../componenets/semantic/Typography'
import YouTubeVideo from '../../componenets/common/YoutubePlayer'
import { Cards, card, youtubeVideoId } from '../../config/config'
import ServiceCard from '../../componenets/common/ServiceCard'
import { useNavigate } from 'react-router-dom'

const Services = () => {

  const navigate = useNavigate()

  return (
    <>
    <div className='flex flex-col lg:flex-row gap-5 justify-between mt-24 mb-20'>
      <div className='flex-1'>
        <Typography variant='h3'>OUR SERVICES</Typography>
        <Typography className='text-sm'>Optimize your product development with our cutting-edge software engineering solutions
        </Typography>
      </div>
      <div className='flex-1'>
        <Typography>At <strong>Code Samadhan</strong>, we provide customized software engineering solutions designed to align perfectly with your requirements. From ideation to deployment, our skilled team ensures outstanding outcomes across various domains, including residential, commercial, and industrial sectors.</Typography>
      </div>
    </div>
    <div className='grid grid-col-1 lg:grid-cols-2 lg:grid-cols-3 gap-4 mt-20 mb-20'>
        {
          card.map((cards: any, index: number) => {
            return <ServiceCard icon={cards.icon} desc={cards.description} title={cards.name} navigation={() => navigate(`/services/${cards.name}`)} />
          })
        }
    </div>
    <YouTubeVideo videoId={youtubeVideoId}/>
    </>
  )
}

export default Services