import { motion } from 'framer-motion';
import main_screen from '../../../assets/main_screen.png';
import Heading1 from '../../../componenets/semantic/Heading1';
import Typography from '../../../componenets/semantic/Typography';
import Button from '../../../componenets/semantic/Button';
import { useNavigate } from 'react-router-dom';
import { FaArrowDown } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";


export const InitScreenNew = () => {
    const navigate = useNavigate()
    const handleClick = () => {
        const targetElement = document.getElementById('servicesScreen');
        if (targetElement) {
            const yOffset = -100;
            const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };
    return (
        <>
            <div className='flex flex-col-reverse lg:flex-row justify-between items-center gap-2 min-h-screen lg:min-h-[unset] lg:h-[80vh] w-full relative'>
                <div className='mt-4 w-full lg:w-[unset]'>
                    <Heading1 className='text-[#124E66] !text-sm font-bold mb-2'>Samadhan in Software and Excellence</Heading1>
                    <Heading1 className='!text-3xl lg:!text-4xl lg:text-6xl font-bold !leading-[50px] lg:!leading-[72px]'>
                        Your &nbsp;
                        <motion.span
                            className='w-full bg-[#124E6610] p-1 px-2 text-[#124E66] rounded-lg'
                            initial={{ clipPath: 'inset(0 100% 0 0)' }}
                            animate={{ clipPath: 'inset(0 0 0 0)' }}
                            transition={{ duration: 1 }}
                        >
                            IT Solutions
                        </motion.span>
                        &nbsp; <br /> Partner for <br /> Software Excellence
                    </Heading1>
                    <Typography className='max-w-full lg:max-w-[60%] mt-2'>
                        Transform your business with Code Samadhan's expert software engineering services. We cater specifically to early-stage and mid-market product companies, ensuring scalable and innovative solutions that propel you ahead of the competition.
                    </Typography>
                    <Button onClick={() => navigate('/contact')} className='mt-6 rounded-lg' title='Try For Free' />
                    <div className='flex flex-col lg:flex-row lg:items-center gap-4'>
                        <div className='mt-4 flex text-xs items-center font-medium gap-1'>
                            <span className='text-[#52B485]'><FaCheckCircle /></span>
                            Your first App solution is free.
                        </div>
                        <div className='mt-4 flex text-xs items-center font-medium gap-1'>
                            <span className='text-[#52B485]'><FaCheckCircle /></span>
                            Customized solutions tailored to your business needs.
                        </div>
                    </div>
                    <motion.div
                        onClick={handleClick}
                        className='bg-[#124E6610] mt-8 p-2 rounded-full w-[60px] h-[60px] flex justify-center items-center cursor-pointer'
                        animate={{ y: [0, -20, 0] }}
                        transition={{
                            duration: 2,
                            ease: "easeInOut",
                            repeat: Infinity,
                            repeatType: "mirror"
                        }}
                    >
                        <FaArrowDown className='text-2xl' />
                    </motion.div>
                </div>
                <motion.img
                    src={main_screen}
                    className='lg:h-[100%] lg:mr-[-50px]'
                    animate={{ y: [0, -5, 0] }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: 'loop',
                    }}
                />
            </div>
        </>
    );
};

export default InitScreenNew;
