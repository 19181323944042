import Typography from '../../componenets/semantic/Typography'
import conversation from './conversation.png'

const ThankYouModal = () => {
    return <div className="p-4 flex justify-center flex-col items-center">
        <Typography className='text-centertext-3xl lg:text-4xl mb-4'>Thank You</Typography>
        <div className='w-[80%]'>
            <Typography className='text-center text-xs'>
            Thank you for getting in touch with <strong>Code Samadhan!</strong>We appreciate you contacting us.<br /> One of our customer service representatives will get back to you within 24 hours.
            </Typography>
            <Typography className='text-center text-xs'>
                In the meantime, feel free to explore our website or follow us on our social media channels for the latest updates.
            </Typography>
        </div>
        <img src={conversation} className='h-[250px]' />
    </div>
}

export default ThankYouModal