import { ComponentType, useEffect } from "react";
import React from "react";
import Navbar from "../layout/navbar";
import Footer from "../layout/footer";


const withLayout = <P extends object>(WrappedComponent: ComponentType<P>,) => {

    const WithLayoutMain: React.FC<P> = ({ ...props }) => {
        return (
            <div className='px-6 lg:px-20'>
                    <Navbar />
                    <div className="mt-24 lg:mt-40"><WrappedComponent {...props as P} /></div>
                    <Footer/>
            </div>
        )
    }
    return WithLayoutMain;
};

export default withLayout;
