import { useNavigate } from "react-router-dom"
import Button from "../../../componenets/semantic/Button"
import Heading1 from "../../../componenets/semantic/Heading1"
import Typography from "../../../componenets/semantic/Typography"
import { FaFreeCodeCamp } from "react-icons/fa6";
import { motion } from 'framer-motion';

const SvgWrapper = () => {
    const blinkAnimation: any = {
        scaleY: [1, 0.1, 1],
        transition: {
            duration: 0.3,
            repeat: Infinity,
            repeatType: "mirror",
            repeatDelay: 3,
        },
    };

    return <motion.svg viewBox="0 0 1500 1125" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_201_585)">
            <rect rx="50" fill="white" />
            <path d="M1500 0H0V1125H1500V0Z" fill="white" />
            <path d="M722.664 357.862C729.976 355.05 729.058 337.537 710.72 340.05C695.176 342.169 680.72 345 684.976 354.375C684.976 354.375 676.201 361.219 680.514 377.437L686.945 385.725L722.664 357.862Z" fill="#F09400" />
            <path d="M725.495 391.031C725.045 405.713 718.576 418.519 701.533 409.613L698.87 418.144L694.314 432.806C694.314 432.806 676.97 450.3 676.801 449.381C676.633 448.463 665.551 421.444 665.551 421.444C665.551 420.975 677.701 391.2 678.676 388.256C678.976 387.263 673.314 385.95 673.464 380.119C673.614 374.288 682.839 369.825 686.589 380.119C686.589 380.119 696.114 368.606 695.045 356.775C695.045 356.775 721.764 356.325 722.851 357.863C723.939 359.4 725.908 376.05 725.495 391.031Z" fill="#FFDCAE" />
            <path d="M701.476 409.538L698.814 418.069C693.614 413.001 690.616 406.091 690.47 398.831C693.279 403.189 697.042 406.851 701.476 409.538Z" fill="black" />
            <path d="M948.058 817.35C949.633 809.85 948.508 801.938 927.976 801.938C927.845 802.688 922.558 824.756 922.558 824.756C922.558 824.756 947.008 822.375 948.058 817.35Z" fill="black" />
            <path d="M887.064 801.956H931.614C931.614 801.956 926.57 828.675 908.945 874.875C891.32 921.075 855.451 973.069 846.32 999.656L813.489 1004.68C813.489 1004.68 815.008 921.694 816.076 893.738C817.145 865.781 822.301 781.463 822.301 781.463L887.064 801.956Z" fill="#FFC36C" />
            <path d="M809.308 992.231C818.814 995.381 825.189 999.506 828.245 996.45C831.301 993.394 833.364 993.225 840.545 995.906C847.726 998.588 848.626 999.413 848.626 999.413C845.923 1003.38 844.892 1008.25 845.758 1012.97C847.201 1020.96 854.908 1025.79 854.908 1025.79H817.558L809.308 992.231Z" fill="#C15D31" />
            <path d="M720.451 529.069C721.745 535.8 741.264 533.344 741.264 533.344C741.264 533.344 735.958 518.344 733.22 515.738C732.695 515.25 717.901 515.737 720.451 529.069Z" fill="black" />
            <path d="M901.445 481.144H751.333V631.256H901.445V481.144Z" fill="#EA4338" />
            <path d="M948.058 817.35C948.058 817.35 953.158 805.05 924.358 807.263C888.902 810 871.727 801.094 871.727 801.094L814.202 783.469L825.452 603.638H902.177C902.177 603.638 906.939 626.569 919.314 674.175C931.689 721.781 954.527 809.55 948.058 817.35Z" fill="#EA4338" />
            <path d="M823.127 809.119C821.758 835.894 820.727 855.731 820.652 861.113C820.408 879.075 817.952 1000.39 817.952 1000.39C817.952 1000.39 814.014 1012.2 803.72 1017.26C793.427 1022.33 742.164 1014.88 742.164 1014.88L745.164 809.081L748.164 603.6H830.252C831.414 645.788 826.352 746.438 823.127 809.119Z" fill="#EA4338" />
            <path d="M823.127 809.119C821.758 835.894 820.727 855.731 820.652 861.113C820.408 879.075 817.952 1000.39 817.952 1000.39C817.952 1000.39 814.014 1012.2 803.72 1017.26C793.427 1022.33 742.164 1014.88 742.164 1014.88L745.164 809.081L823.127 809.119Z" fill="#FFC36C" />
            <path d="M734.158 992.231C747.283 1000.33 772.858 1011.21 800.064 1011.08C800.084 1009.18 800.335 1007.29 800.814 1005.45C801.451 1004.19 806.251 999.131 819.02 999.825C819.02 999.825 817.37 1006.14 819.901 1009.69C823.947 1014.9 829.259 1018.99 835.333 1021.58C838.699 1022.78 841.992 1024.19 845.195 1025.78H749.083L734.158 992.231Z" fill="#C15D31" />
            <path d="M845.214 1026.41C845.109 1026.44 845 1026.44 844.895 1026.41C841.715 1024.84 838.447 1023.45 835.108 1022.25C828.909 1019.62 823.487 1015.44 819.358 1010.12C817.201 1007.12 817.82 1002.41 818.176 1000.5H817.895C817.728 1000.47 817.577 1000.39 817.467 1000.26C817.358 1000.13 817.298 999.966 817.298 999.797C817.298 999.628 817.358 999.464 817.467 999.336C817.577 999.207 817.728 999.121 817.895 999.094H819.039C819.143 999.098 819.246 999.126 819.338 999.176C819.43 999.226 819.509 999.295 819.571 999.38C819.632 999.465 819.673 999.562 819.692 999.665C819.71 999.768 819.705 999.874 819.676 999.975C819.676 999.975 818.139 1006.03 820.445 1009.35C824.407 1014.46 829.609 1018.47 835.558 1020.99C838.956 1022.21 842.28 1023.63 845.514 1025.23C845.649 1025.31 845.756 1025.43 845.817 1025.57C845.878 1025.71 845.89 1025.87 845.852 1026.03C845.813 1026.18 845.727 1026.31 845.605 1026.41C845.483 1026.5 845.332 1026.56 845.176 1026.56L845.214 1026.41Z" fill="black" />
            <path d="M817.895 1000.48C817.802 1000.48 817.711 1000.46 817.625 1000.43C817.54 1000.39 817.463 1000.34 817.398 1000.27C817.333 1000.21 817.282 1000.13 817.249 1000.04C817.215 999.954 817.199 999.862 817.201 999.769C817.201 998.569 819.714 878.869 819.958 861.113C819.958 856.181 820.895 839.213 822.114 815.719C825.358 752.7 830.776 647.25 829.614 603.656C829.641 603.49 829.727 603.338 829.856 603.229C829.985 603.119 830.148 603.059 830.317 603.059C830.486 603.059 830.65 603.119 830.778 603.229C830.907 603.338 830.993 603.49 831.02 603.656C832.239 647.288 826.801 752.794 823.52 815.831C822.32 839.306 821.439 856.256 821.383 861.169C821.139 878.925 818.664 998.625 818.626 999.919C818.591 1000.08 818.498 1000.23 818.364 1000.33C818.23 1000.44 818.064 1000.49 817.895 1000.48Z" fill="black" />
            <path d="M926.908 475.913H884.251V501.525H926.908V475.913Z" fill="#B7AD7A" />
            <path d="M888.733 469.538C895.802 479.063 904.352 490.856 918.002 489.094L925.764 468.713C925.764 468.713 889.989 462.544 888.733 469.538Z" fill="black" />
            <path d="M884.251 461.175C895.82 471.188 903.62 479.531 927.376 472.181C927.376 472.181 939.058 420.769 910.501 415.594L891.47 427.444L884.251 461.175Z" fill="#FFC36C" />
            <path d="M946.745 459.356L923.214 508.238L908.214 505.406C908.214 505.406 923.57 477.394 924.414 459.506C925.258 441.619 923.57 423.169 911.739 419.231L895.689 429.075C895.689 429.075 896.401 495 895.408 508.8L858.114 506.55C858.114 506.55 858.114 499.05 857.983 491.888C857.983 488.85 857.851 485.869 857.739 483.544H790.745C790.745 483.544 790.745 486.9 790.745 491.888C790.632 501.956 790.408 518.625 790.014 527.513C789.451 540.863 783.489 603.6 783.489 603.6H748.164C748.164 603.6 747.245 552.281 748.351 535.331C749.458 518.381 746.289 476.794 746.289 476.794L710.27 445.163C710.27 445.163 727.839 388.463 733.239 365.531C738.639 342.6 743.308 323.569 765.883 316.106C777.364 312.253 789.167 309.435 801.151 307.688C808.855 306.57 816.637 306.075 824.42 306.206C834.395 306.413 843.451 306.206 851.664 306.206C870.883 306.206 885.62 306.825 897.339 314.456C914.101 325.369 914.72 336.694 922.201 360.206C929.683 383.719 946.745 459.356 946.745 459.356Z" fill="#4B764A" />
            <path d="M857.626 483.544H790.745C790.745 481.069 790.745 477.787 790.914 473.812H857.533C857.551 478.144 857.589 481.463 857.626 483.544Z" fill="white" />
            <path d="M838.201 286.238C838.201 286.238 838.483 290.85 838.783 296.119C838.97 299.55 839.176 303.263 839.289 306.169C839.608 313.538 837.732 319.781 828.489 319.781C819.245 319.781 813.264 311.944 813.114 307.669C812.964 303.394 813.114 278.55 813.114 278.55C810.643 274.531 808.845 270.136 807.789 265.538C798.901 262.894 800.401 252.413 805.595 252.525C805.595 252.525 802.482 222.263 824.082 221.475C835.726 221.044 842.064 225.713 845.27 235.088C847.314 240.994 848.12 248.775 848.232 258.319C848.532 282.6 838.201 286.238 838.201 286.238Z" fill="#FFC36C" />
            <path d="M801.151 307.669C794.045 355.181 791.064 459.188 790.745 483.544C790.745 483.544 790.501 514.219 789.92 527.55C789.339 540.881 783.395 603.638 783.395 603.638H748.164C748.164 603.638 747.245 552.319 748.351 535.369C749.458 518.419 746.289 476.831 746.289 476.831L710.27 445.163C710.27 445.163 727.839 388.463 733.239 365.531C738.639 342.6 743.308 323.569 765.883 316.106C777.363 312.246 789.167 309.423 801.151 307.669Z" fill="#B7AD7A" />
            <path d="M780.358 514.219C788.551 538.913 790.651 546.413 792.751 546.413C794.851 546.413 851.851 537.3 851.851 537.3L846.939 581.213C846.939 581.213 792.058 597.338 788.551 598.256C785.045 599.175 757.483 597.019 757.483 597.019L725.495 514.219C725.495 514.219 766.576 498.188 780.358 514.219Z" fill="#FFDCAE" />
            <path d="M764.289 481.913C764.137 481.907 763.991 481.853 763.873 481.758C763.754 481.664 763.668 481.534 763.628 481.387C763.589 481.241 763.597 481.085 763.651 480.944C763.706 480.802 763.805 480.682 763.933 480.6C771.713 476.113 779.147 471.049 786.17 465.45C786.24 465.39 786.322 465.344 786.41 465.315C786.497 465.286 786.59 465.275 786.682 465.282C786.775 465.289 786.865 465.314 786.947 465.356C787.03 465.397 787.104 465.455 787.164 465.525C787.224 465.595 787.27 465.677 787.299 465.765C787.328 465.852 787.339 465.945 787.332 466.037C787.325 466.13 787.3 466.22 787.258 466.302C787.217 466.385 787.159 466.459 787.089 466.519C779.983 472.182 772.462 477.302 764.589 481.838C764.496 481.884 764.393 481.91 764.289 481.913Z" fill="black" />
            <path d="M769.595 454.2C772.126 461.85 778.07 468.131 784.314 466.969C790.558 465.806 791.139 456.563 784.314 439.35L769.595 454.2Z" fill="black" />
            <path d="M795.864 355.425L846.489 394.5L850.014 402.394L841.839 400.294L791.964 360.413C791.636 360.149 791.363 359.823 791.163 359.453C790.962 359.082 790.837 358.676 790.795 358.257C790.753 357.838 790.795 357.415 790.919 357.012C791.042 356.61 791.245 356.236 791.514 355.913C792.037 355.287 792.781 354.888 793.592 354.797C794.402 354.706 795.216 354.931 795.864 355.425Z" fill="black" />
            <path d="M837.526 397.875C837.526 397.875 838.708 401.138 833.776 401.775C833.776 401.775 834.17 403.238 829.745 404.681C823.933 406.556 819.751 407.944 816.62 410.944C812.87 414.694 789.076 436.931 770.833 457.219C766.595 449.287 766.97 427.106 768.058 404.925C771.808 402.356 775.97 399.506 780.395 396.3C791.964 387.938 796.201 385.388 796.126 383.981C795.907 382.937 796.06 381.849 796.561 380.907C797.061 379.965 797.876 379.228 798.864 378.825C800.739 377.888 798.076 373.519 801.92 371.55C808.145 368.363 834.883 375.769 836.908 388.969C836.889 388.913 844.389 395.85 837.526 397.875Z" fill="#FFC36C" />
            <path d="M946.745 459.356L923.214 508.238L908.214 505.406C908.214 505.406 923.57 477.394 924.414 459.506C925.258 441.619 923.57 423.169 911.739 419.231L895.689 429.075C895.689 429.075 896.401 495 895.408 508.8L858.114 506.55C858.114 506.55 858.114 491.381 857.739 483.544C857.42 465.975 858.02 360.506 851.776 306.206C870.995 306.206 885.733 306.825 897.451 314.456C914.214 325.369 914.833 336.694 922.314 360.206C929.795 383.719 946.745 459.356 946.745 459.356Z" fill="#B7AD7A" />
            <path d="M931.745 424.725C931.596 424.725 931.45 424.678 931.329 424.59C931.208 424.503 931.118 424.379 931.07 424.237C930.133 421.425 921.602 412.987 914.852 411.787C914.761 411.77 914.674 411.735 914.596 411.684C914.519 411.634 914.452 411.568 914.4 411.491C914.347 411.415 914.311 411.329 914.292 411.238C914.273 411.147 914.272 411.054 914.289 410.962C914.306 410.871 914.341 410.785 914.392 410.707C914.443 410.629 914.509 410.563 914.585 410.51C914.662 410.458 914.748 410.422 914.839 410.403C914.929 410.384 915.023 410.383 915.114 410.4C922.183 411.713 931.295 420.394 932.42 423.788C932.474 423.964 932.457 424.155 932.373 424.319C932.289 424.483 932.145 424.609 931.97 424.669L931.745 424.725Z" fill="black" />
            <path d="M894.995 411.806C894.811 411.806 894.635 411.733 894.505 411.603C894.374 411.473 894.301 411.297 894.301 411.113C894.301 410.644 893.945 363.694 894.301 352.875C894.301 352.782 894.32 352.689 894.358 352.603C894.395 352.517 894.45 352.44 894.519 352.377C894.587 352.313 894.669 352.265 894.757 352.234C894.846 352.204 894.939 352.192 895.033 352.2C895.218 352.21 895.393 352.292 895.519 352.428C895.645 352.565 895.713 352.745 895.708 352.931C895.351 363.731 895.708 410.644 895.708 411.056C895.708 411.242 895.635 411.421 895.506 411.554C895.376 411.687 895.2 411.764 895.014 411.769L894.995 411.806Z" fill="black" />
            <path d="M806.683 439.838C807.02 439.5 879.451 401.588 879.451 401.588H946.745L870.245 441.075L806.683 439.838Z" fill="#B8C1D0" />
            <path d="M898.258 398.738L886.501 404.813H923.945L935.701 398.738H898.258Z" fill="#5B77C9" />
            <path d="M1406.85 482.363H1180.2C1169.13 482.363 1160.16 491.336 1160.16 502.406V683.719C1160.16 694.789 1169.13 703.763 1180.2 703.763H1406.85C1417.92 703.763 1426.9 694.789 1426.9 683.719V502.406C1426.9 491.336 1417.92 482.363 1406.85 482.363Z" fill="white" />
            <path d="M1397.91 631.688H1189.15C1188.96 631.688 1188.78 631.613 1188.65 631.482C1188.52 631.35 1188.44 631.171 1188.44 630.984C1188.44 630.798 1188.52 630.619 1188.65 630.487C1188.78 630.355 1188.96 630.281 1189.15 630.281H1397.91C1398.07 630.309 1398.23 630.394 1398.34 630.523C1398.44 630.652 1398.5 630.815 1398.5 630.984C1398.5 631.153 1398.44 631.317 1398.34 631.446C1398.23 631.574 1398.07 631.66 1397.91 631.688Z" fill="#E4D0C0" />
            <path d="M1293.53 540.994C1298.82 540.994 1303.11 536.704 1303.11 531.413C1303.11 526.121 1298.82 521.831 1293.53 521.831C1288.23 521.831 1283.95 526.121 1283.95 531.413C1283.95 536.704 1288.23 540.994 1293.53 540.994Z" fill="#E4D0C0" />
            <path d="M1282.97 483.056H1265.65C1265.54 483.057 1265.43 483.033 1265.33 482.986C1265.24 482.939 1265.15 482.87 1265.09 482.784C1265.02 482.699 1264.98 482.599 1264.96 482.494C1264.94 482.388 1264.94 482.279 1264.97 482.175L1287.23 401.044C1287.27 400.895 1287.35 400.763 1287.48 400.669C1287.6 400.574 1287.75 400.522 1287.9 400.519H1305.23C1305.33 400.518 1305.44 400.542 1305.54 400.589C1305.63 400.636 1305.72 400.705 1305.78 400.791C1305.85 400.876 1305.89 400.976 1305.91 401.082C1305.94 401.187 1305.93 401.296 1305.9 401.4L1283.65 482.531C1283.6 482.68 1283.52 482.812 1283.4 482.906C1283.27 483.001 1283.12 483.054 1282.97 483.056ZM1266.56 481.65H1282.37L1304.23 401.925H1288.37L1266.56 481.65Z" fill="#E4D0C0" />
            <path d="M1325.85 483.056H1305.23C1305.04 483.052 1304.87 482.977 1304.74 482.848C1304.61 482.719 1304.54 482.545 1304.53 482.363V380.794C1304.54 380.608 1304.61 380.432 1304.75 380.302C1304.88 380.173 1305.06 380.1 1305.25 380.1H1325.87C1326.06 380.1 1326.24 380.175 1326.37 380.309C1326.51 380.442 1326.58 380.624 1326.58 380.813V482.381C1326.57 482.567 1326.49 482.742 1326.35 482.868C1326.22 482.994 1326.04 483.061 1325.85 483.056ZM1305.92 481.65H1325.16V381.413H1305.92V481.65Z" fill="#E4D0C0" />
            <path d="M1346.48 483.056H1325.85C1325.67 483.052 1325.5 482.977 1325.37 482.848C1325.24 482.719 1325.16 482.545 1325.16 482.363V397.519C1325.16 397.333 1325.24 397.157 1325.37 397.027C1325.51 396.898 1325.68 396.825 1325.87 396.825H1346.5C1346.68 396.825 1346.87 396.9 1347 397.034C1347.13 397.167 1347.21 397.349 1347.21 397.538V482.381C1347.2 482.567 1347.12 482.742 1346.98 482.868C1346.84 482.994 1346.66 483.062 1346.48 483.056ZM1326.55 481.65H1345.78V398.288H1326.55V481.65Z" fill="#E4D0C0" />
            <path d="M1392.13 483.056H1346.48C1346.29 483.052 1346.12 482.977 1345.99 482.848C1345.86 482.719 1345.79 482.545 1345.78 482.363V385.613C1345.79 385.427 1345.86 385.251 1346 385.121C1346.13 384.991 1346.31 384.919 1346.5 384.919H1392.15C1392.34 384.919 1392.52 384.994 1392.66 385.128C1392.79 385.261 1392.86 385.442 1392.86 385.631V482.363C1392.86 482.456 1392.84 482.548 1392.8 482.634C1392.76 482.719 1392.71 482.797 1392.64 482.861C1392.57 482.926 1392.49 482.976 1392.41 483.009C1392.32 483.043 1392.23 483.059 1392.13 483.056ZM1347.13 481.65H1391.38V386.325H1347.13V481.65Z" fill="#E4D0C0" />
            <path d="M1407.15 757.425C1407.15 757.425 1400.06 755.362 1394.03 753.131C1388.4 751.012 1366.82 747.506 1349.03 752.269C1348.03 752.531 1347.15 752.812 1346.08 753.131C1327.8 759.094 1262.18 788.194 1262.18 788.194L1245.58 720.375L1310.5 693.019L1347.38 677.438L1354.26 686.006L1347.73 693.094L1359.86 691.781L1407.15 757.425Z" fill="#FFDCAE" />
            <path d="M1407.15 757.425C1407.15 757.425 1400.06 755.362 1394.03 753.131C1388.4 751.012 1366.82 747.506 1349.03 752.269C1345.28 745.781 1351.99 734.138 1356.36 727.894C1361.81 720.056 1356.15 721.8 1334.34 711.769C1319.34 704.869 1313.87 700.237 1310.33 693.019L1347.21 677.438L1354.09 686.006L1347.56 693.094L1359.69 691.781L1407.15 757.425Z" fill="#8EBF8C" />
            <path d="M1278.62 813.356C1278.62 813.356 1234.39 824.194 1193.5 834.6L1179.51 838.181L1182.49 746.081L1202.48 737.944L1245.6 720.375C1249.95 720.713 1261.63 750.75 1271.65 783.45C1281.66 816.15 1278.62 813.356 1278.62 813.356Z" fill="#5B77C9" />
            <path d="M1181.98 947.494C1179.9 957.356 1161.36 959.906 1138.86 960.619L1147.91 927.337C1147.91 927.337 1187.93 919.312 1181.98 947.494Z" fill="black" />
            <path d="M1070.85 931.894C1070.85 936.281 1069.37 940.556 1066.75 942.375C1052.38 943.856 1052.55 930.694 1057.28 923.625C1059.15 920.775 1061.76 918.863 1064.46 919.219C1068.85 919.763 1071 925.931 1070.85 931.894Z" fill="#4B764A" />
            <path d="M1070.85 931.894C1065.74 930.198 1061.1 927.342 1057.28 923.55C1059.15 920.7 1061.76 918.788 1064.46 919.144C1068.85 919.763 1071 925.931 1070.85 931.894Z" fill="black" />
            <path d="M1091.46 928.444C1084.78 924.694 1077.34 910.275 1088.21 906.506C1099.09 902.738 1100.72 915.956 1091.46 928.444Z" fill="#4B764A" />
            <path d="M1072.95 938.1C1088.23 927.506 1105.45 934.519 1103.12 943.987C1100.06 956.381 1079.87 949.163 1072.95 938.1Z" fill="#4B764A" />
            <path d="M1103.18 923.831C1117.93 929.813 1129.59 928.275 1126.35 936.206C1122.19 946.444 1102.8 945.038 1103.18 923.831Z" fill="#4B764A" />
            <path d="M1048.48 915.563C1060.41 907.125 1080.45 906.975 1080.45 921.488C1080.45 938.569 1059.64 919.95 1048.48 915.563Z" fill="#8EBF8C" />
            <path d="M1067.42 888.469C1086.71 879.881 1100.18 884.325 1096.31 894.956C1090.93 909.788 1078.58 894.506 1067.42 888.469Z" fill="#8EBF8C" />
            <path d="M1095.66 863.7C1097.93 880.087 1113.85 887.644 1121.05 877.387C1128.25 867.131 1108.58 861.975 1095.66 863.7Z" fill="#8EBF8C" />
            <path d="M1106.23 857.869C1096.11 844.538 1092.17 837.375 1100.96 833.663C1108.63 830.438 1117.52 839.156 1106.23 857.869Z" fill="#8EBF8C" />
            <path d="M1079.87 875.775C1063 864.806 1055.61 858.9 1061.91 852.563C1071.06 843.413 1082.87 845.138 1079.87 875.775Z" fill="#8EBF8C" />
            <path d="M1056.1 903.075C1053.58 900.076 1050.61 897.494 1047.28 895.425L1045.91 894.469L1043.42 892.725C1040.24 890.742 1037.44 888.201 1035.15 885.225C1034.95 884.892 1034.76 884.548 1034.59 884.194C1034 882.986 1033.8 881.623 1034.03 880.298C1034.26 878.973 1034.89 877.752 1035.85 876.806C1036.37 876.176 1036.95 875.587 1037.57 875.044C1038.4 874.158 1039.45 873.507 1040.61 873.154C1041.77 872.802 1043.01 872.762 1044.19 873.038C1045.24 873.352 1046.21 873.889 1047.04 874.613C1050.3 877.822 1052.61 881.871 1053.71 886.313C1054.11 887.606 1054.45 888.956 1054.76 890.381C1055.01 891.488 1055.23 892.613 1055.4 893.775C1055.87 896.853 1056.1 899.962 1056.1 903.075Z" fill="#8EBF8C" />
            <path d="M1044.25 873.019C1042.38 877.812 1039.26 882.02 1035.23 885.206C1035.02 884.873 1034.83 884.529 1034.66 884.175C1034.08 882.967 1033.88 881.605 1034.11 880.28C1034.33 878.955 1034.97 877.733 1035.92 876.788C1036.45 876.157 1037.03 875.568 1037.65 875.025C1038.47 874.143 1039.52 873.493 1040.68 873.141C1041.84 872.789 1043.07 872.747 1044.25 873.019Z" fill="black" />
            <path d="M1040.5 965.494C1040.37 965.494 1040.24 965.46 1040.13 965.393C1040.02 965.327 1039.93 965.232 1039.88 965.119C1037.62 960.201 1036.58 954.812 1036.84 949.406C1037.26 942.638 1038.74 935.977 1041.21 929.663C1041.73 928.275 1042.28 926.906 1042.86 925.5C1044 922.819 1045.3 920.063 1046.7 917.363L1047.81 915.281C1048.91 913.275 1049.93 911.531 1050.92 909.844C1052.27 907.575 1053.81 905.194 1055.46 902.775C1056.75 900.9 1057.97 899.213 1059.21 897.6C1061.25 894.844 1063.18 892.425 1065.08 890.1L1065.55 889.556C1065.98 889.05 1066.41 888.525 1066.88 888.038C1070.78 883.537 1074.96 879.279 1079.38 875.288C1084.32 870.81 1089.63 866.752 1095.25 863.156C1098.67 860.966 1102.22 858.993 1105.9 857.25C1111.65 854.503 1117.76 852.578 1124.05 851.531C1127.58 850.962 1131.17 850.761 1134.75 850.931C1134.94 850.941 1135.11 851.023 1135.24 851.16C1135.36 851.296 1135.43 851.477 1135.43 851.663C1135.41 851.847 1135.33 852.018 1135.19 852.144C1135.06 852.269 1134.88 852.338 1134.7 852.338C1131.21 852.172 1127.72 852.366 1124.27 852.919C1118.11 853.952 1112.13 855.846 1106.5 858.544C1102.88 860.26 1099.38 862.201 1096.01 864.356C1090.46 867.913 1085.22 871.927 1080.34 876.356C1075.95 880.312 1071.81 884.532 1067.95 888.994C1067.48 889.5 1067.06 889.988 1066.65 890.475L1066.18 891.019C1064.31 893.231 1062.43 895.631 1060.37 898.369C1059.17 899.963 1057.97 901.631 1056.62 903.469C1056.59 903.537 1056.54 903.6 1056.49 903.656C1054.93 905.944 1053.41 908.306 1052.06 910.575C1051.07 912.206 1050.08 913.969 1048.99 915.956L1047.88 918.038C1046.48 920.719 1045.2 923.419 1044.13 926.081C1043.55 927.469 1043.01 928.838 1042.5 930.206C1040.08 936.383 1038.64 942.899 1038.23 949.519C1037.99 954.675 1038.98 959.813 1041.1 964.519C1041.15 964.626 1041.17 964.745 1041.17 964.865C1041.17 964.985 1041.13 965.102 1041.07 965.204C1041.01 965.307 1040.92 965.393 1040.82 965.453C1040.71 965.514 1040.6 965.547 1040.48 965.55L1040.5 965.494Z" fill="black" />
            <path d="M1124.18 852.188C1133.68 859.688 1145.36 873.094 1150.56 859.219C1156.18 844.219 1140.96 839.381 1124.18 852.188Z" fill="#8EBF8C" />
            <path d="M1056.1 903.075C1053.58 900.076 1050.61 897.494 1047.28 895.425L1045.91 894.469C1048.31 892.139 1051.43 890.698 1054.76 890.381C1055.01 891.488 1055.23 892.613 1055.4 893.775C1055.87 896.853 1056.1 899.962 1056.1 903.075Z" fill="black" />
            <path d="M1033.71 911.775C1027.46 898.65 1011.38 889.95 1005.23 900.394C996.564 915.113 1018.63 912.506 1033.71 911.775Z" fill="#4B764A" />
            <path d="M1023.83 876.694C1011.41 862.556 991.052 865.65 990.077 875.794C988.464 891.413 1007.44 886.05 1023.83 876.694Z" fill="#4B764A" />
            <path d="M1008.36 845.438C997.895 860.813 977.289 863.119 976.052 850.669C974.814 838.219 997.952 837.581 1008.36 845.438Z" fill="#4B764A" />
            <path d="M1011.43 850.669C1027.39 844.031 1035.11 835.819 1029 828.469C1022.89 821.119 1010.79 828.825 1011.43 850.669Z" fill="#4B764A" />
            <path d="M1023.83 876.694C1043.53 878.756 1047.38 872.944 1044.99 864.3C1041.54 851.831 1028.38 855.544 1023.83 876.694Z" fill="#4B764A" />
            <path d="M1060.78 901.575C1061.05 906.263 1057.03 908.794 1051.56 910.163C1045.69 911.388 1039.7 911.929 1033.71 911.775C1035.53 907.888 1037.88 904.272 1040.7 901.031C1042.58 898.82 1044.8 896.921 1047.28 895.406C1048.45 894.592 1049.77 894.024 1051.17 893.737C1052.57 893.45 1054.01 893.45 1055.4 893.738C1056.41 894.046 1057.33 894.579 1058.1 895.297C1058.87 896.015 1059.47 896.899 1059.85 897.881C1060 898.181 1060.09 898.463 1060.22 898.781C1060.56 899.675 1060.75 900.619 1060.78 901.575Z" fill="#4B764A" />
            <path d="M1034.87 920.944C1034.87 920.944 1027.09 947.194 1015.03 940.219C1002.98 933.244 1020.7 915.563 1034.87 920.944Z" fill="#4B764A" />
            <path d="M1029.84 958.219C1029.74 958.244 1029.63 958.244 1029.53 958.219C1029.44 958.177 1029.37 958.12 1029.31 958.05C1029.25 957.98 1029.21 957.9 1029.18 957.812C1029.15 957.725 1029.14 957.633 1029.15 957.542C1029.16 957.451 1029.18 957.362 1029.23 957.281C1045.63 925.05 1022.08 857.906 991.632 823.913C991.508 823.773 991.444 823.59 991.455 823.404C991.465 823.218 991.549 823.043 991.689 822.919C991.828 822.794 992.011 822.731 992.197 822.741C992.384 822.752 992.558 822.836 992.682 822.975C1024.07 858.075 1047.41 924.675 1030.48 957.975C1030.41 958.067 1030.31 958.138 1030.2 958.181C1030.08 958.223 1029.96 958.237 1029.84 958.219Z" fill="black" />
            <path d="M1001.5 835.069C989.664 832.688 970.389 823.013 979.745 811.519C990.189 798.656 1001.5 811.95 1001.5 835.069Z" fill="#4B764A" />
            <path d="M1053.51 956.625C1053.38 956.62 1053.25 956.578 1053.14 956.504C1053.03 956.43 1052.94 956.327 1052.89 956.206C1052.83 956.086 1052.81 955.952 1052.83 955.821C1052.85 955.689 1052.9 955.565 1052.98 955.463C1064.23 942.45 1074.49 933.281 1091.25 927.769C1107.53 922.406 1114.67 916.744 1120.37 909.9C1120.5 909.784 1120.66 909.717 1120.83 909.713C1121.01 909.708 1121.17 909.767 1121.31 909.876C1121.44 909.986 1121.53 910.14 1121.55 910.31C1121.58 910.48 1121.55 910.654 1121.46 910.8C1115.59 917.831 1108.33 923.644 1091.7 929.1C1077.3 933.844 1067.08 941.25 1054.05 956.381C1053.98 956.46 1053.9 956.522 1053.81 956.565C1053.71 956.607 1053.61 956.627 1053.51 956.625Z" fill="black" />
            <path d="M1107.38 921.656C1113 906.75 1116.75 898.481 1124.83 901.538C1135.16 905.456 1130.05 923.513 1107.38 921.656Z" fill="#4B764A" />
            <path d="M1073.4 949.406H1011.53C986.401 965.606 989.139 1009.28 1021.67 1025.18H1063.24C1095.77 1009.28 1098.62 965.606 1073.4 949.406Z" fill="white" />
            <path d="M1008.08 981.694C1003.47 981.735 998.9 980.947 994.576 979.369C994.349 979.279 994.166 979.105 994.066 978.882C993.965 978.66 993.955 978.407 994.038 978.178C994.121 977.948 994.291 977.76 994.51 977.653C994.73 977.547 994.982 977.53 995.214 977.606C1005.81 981.506 1016.23 979.95 1031.74 975.731L1034.94 974.85C1054.41 969.525 1071.23 964.894 1087.76 966.713C1088.01 966.74 1088.24 966.865 1088.4 967.06C1088.55 967.255 1088.63 967.505 1088.6 967.753C1088.57 968.002 1088.45 968.229 1088.25 968.386C1088.06 968.542 1087.81 968.615 1087.56 968.588C1071.38 966.825 1054.73 971.4 1035.43 976.669L1032.21 977.55C1022.91 980.081 1015.16 981.694 1008.08 981.694Z" fill="#F6EEE8" />
            <path d="M1089.43 991.931C1089.32 991.952 1089.21 991.952 1089.1 991.931C1075.07 986.306 1061.23 986.606 1040.65 988.969C1017.9 991.575 1007.06 990.356 994.314 983.756C994.093 983.642 993.926 983.444 993.85 983.207C993.775 982.97 993.797 982.712 993.911 982.491C994.025 982.269 994.223 982.103 994.46 982.027C994.698 981.951 994.955 981.973 995.177 982.088C1007.55 988.5 1018.13 989.587 1040.44 987.112C1061.35 984.712 1075.41 984.488 1089.81 990.188C1090.06 990.232 1090.28 990.374 1090.42 990.581C1090.57 990.789 1090.62 991.045 1090.58 991.294C1090.53 991.542 1090.39 991.763 1090.18 991.907C1089.98 992.051 1089.72 992.107 1089.47 992.063L1089.43 991.931Z" fill="#F6EEE8" />
            <path d="M1158.08 926.625C1158.08 926.625 1149.04 966.563 1142.31 996C1138.41 1013.01 1135.28 1026.47 1135.13 1026.62C1134.98 1026.77 1124.33 1026.62 1124.33 1026.62L1122.45 1019.12L1116.66 1026.62C1116.66 1026.62 1036.67 1028.25 1035.88 1026.62C1035.1 1024.99 1039.78 1023.86 1049.95 1019.12C1060.11 1014.38 1067.14 1008.81 1068.55 1006.16C1070.11 1001.73 1071.37 997.196 1072.3 992.588C1074.86 981.7 1078.61 964.394 1083.55 940.669C1101.45 903.469 1158.08 926.625 1158.08 926.625Z" fill="#FFDCAE" />
            <path d="M1204.69 712.275C1201.43 755.4 1196.96 794.344 1193.25 838.556C1189.54 882.769 1186.13 931.463 1182 947.494C1182 947.494 1183.18 938.888 1169.98 937.106C1159.93 935.738 1120.11 938.738 1096.26 938.813C1091.91 938.638 1087.57 939.273 1083.45 940.688C1083.45 940.688 1091.83 902.925 1099.18 866.925C1102.13 852.619 1104.58 838.2 1104.58 838.2C1104.58 838.2 1071.28 836.663 1069.93 805.8C1068.73 778.594 1068.06 740.306 1068.06 722.85C1068.06 705.394 1068.69 576.469 1067.76 566.794C1067.48 563.944 1065.36 560.644 1062.13 557.119C1054.28 548.644 1039.63 538.838 1026.51 530.475C1012.74 521.588 992.045 515.288 984.02 496.725L956.27 497.1C956.27 497.1 957.658 453.038 962.589 441.113C967.52 429.188 977.233 421.706 1010.08 422.213C1050 422.85 1063.35 424.369 1073.59 438.319C1083.83 452.269 1094.04 473.831 1101.17 486.15C1108.29 498.469 1116.9 513.019 1124.48 517.163C1132.05 521.306 1145.25 524.288 1165.13 533.644C1185 543 1191.54 548.775 1199.27 564.356C1206.99 579.938 1206.77 591.956 1207.73 614.231C1208.68 636.506 1207.95 669.244 1204.69 712.275Z" fill="#F09400" />
            <path d="M1039.54 483C1039.54 483 1033.3 521.85 1030.67 543.581C1030.67 543.581 1045.03 542.906 1058.89 542.325C1072.75 541.744 1087.56 541.294 1088.5 548.888C1088.5 548.888 1085.12 512.344 1082.23 473.794L1039.54 483Z" fill="#F09400" />
            <path d="M1013.06 473.794C1013.06 473.794 1007.27 448.875 1006.41 444.656C1006.41 444.656 994.576 448.406 986.964 440.681C980.27 433.913 978.208 414.881 978.264 403.706C978.264 402.188 978.264 400.819 978.414 399.638C979.108 389.794 988.22 377.644 1003.13 381.169C1020.41 385.238 1021.28 409.069 1021.28 409.069C1022.67 408.943 1024.06 409.342 1025.18 410.188C1026.3 411.034 1027.06 412.266 1027.31 413.644C1027.91 419.869 1021.69 421.331 1021.69 421.331C1021.69 421.331 1028.61 431.325 1029.38 433.706C1030.15 436.088 1028.87 439.538 1013.06 473.794Z" fill="#FFDCAE" />
            <path d="M1013.57 449.606L1006.41 444.656C1008.66 443.626 1010.67 442.11 1012.27 440.217C1013.87 438.325 1015.04 436.102 1015.69 433.706C1016.15 439.097 1015.42 444.523 1013.57 449.606Z" fill="black" />
            <path d="M1035.56 370.744C1035.61 369.31 1035.33 367.885 1034.72 366.583C1034.12 365.281 1033.22 364.138 1032.1 363.247C1030.98 362.355 1029.66 361.739 1028.26 361.449C1026.85 361.159 1025.4 361.202 1024.01 361.575C1018.84 363.45 1017 366.075 1005.73 381.994C1004.88 381.659 1004.01 381.383 1003.13 381.169C988.127 377.644 979.108 389.794 978.414 399.638C978.414 400.819 978.283 402.188 978.264 403.706C989.102 402.225 994.633 397.088 1001.25 390.281C1001.25 390.281 1005.13 401.531 1017.98 411.431C1018.31 410.811 1018.78 410.276 1019.35 409.866C1019.92 409.456 1020.58 409.184 1021.28 409.069C1021.28 409.069 1020.58 390.319 1008.32 383.213C1028.74 380.044 1035.15 378.619 1035.56 370.744Z" fill="#EA4338" />
            <path d="M788.251 952.256C796.314 939.3 780.751 930.544 766.07 922.35L753.958 946.35C753.958 946.35 784.539 958.219 788.251 952.256Z" fill="black" />
            <path d="M833.72 948.544C833.101 937.125 857.158 931.856 857.158 931.856L864.245 958.556C864.245 958.556 834.358 960.413 833.72 948.544Z" fill="black" />
            <path d="M978.433 1026.56H883.314L877.239 1008.73L849.245 926.494C849.245 926.494 912.039 889.631 924.339 917.925C932.945 947.025 947.514 999.112 954.826 1008.06C955.07 1008.32 955.295 1008.56 955.501 1008.75C962.589 1015.16 974.57 1020.37 978.433 1026.56Z" fill="#FFDCAE" />
            <path d="M754.333 1014.92C759.958 1020.17 767.027 1026.56 767.027 1026.56H747.995C747.995 1026.56 736.745 1024.01 721.352 1012.73C705.564 1001.16 667.539 971.1 667.295 967.2C667.295 965.85 670.558 958.706 675.508 949.069C684.883 930.731 699.995 903.356 708.17 889.463C729.433 871.913 772.37 921.6 772.37 921.6C772.37 921.6 761.233 940.913 749.87 961.294C742.82 974.081 743.627 975.694 743.908 982.519C744.082 985.902 744.508 989.268 745.183 992.588C747.058 1001.48 750.339 1011.19 754.333 1014.92Z" fill="#FFDCAE" />
            <path d="M767.027 1026.56H747.995C747.995 1026.56 736.745 1024.01 721.352 1012.73C705.564 1001.16 667.539 971.1 667.295 967.2C667.295 965.85 670.558 958.706 675.508 949.069C705.602 987.863 722.983 996.75 727.895 995.475C732.227 994.35 735.883 989.681 745.352 992.588C747.077 1001.48 750.358 1011.19 754.352 1014.92C759.939 1020.17 767.027 1026.56 767.027 1026.56Z" fill="#4B764A" />
            <path d="M978.433 1026.56H883.314L877.239 1008.73C900.47 1015.59 939.376 1016.96 941.57 1014.92C945.212 1011.36 949.835 1008.97 954.845 1008.06C955.089 1008.32 955.314 1008.56 955.52 1008.75C962.589 1015.16 974.57 1020.38 978.433 1026.56Z" fill="#4B764A" />
            <path d="M395.158 98.4375C109.539 98.4375 178.37 489.094 169.408 1026.56H620.908C611.945 489.094 680.776 98.4375 395.158 98.4375Z" fill="white" />
            <path d="M395.158 1027.5C394.909 1027.5 394.671 1027.4 394.495 1027.23C394.319 1027.05 394.22 1026.81 394.22 1026.56V98.4375C394.22 98.1889 394.319 97.9504 394.495 97.7746C394.671 97.5988 394.909 97.5 395.158 97.5C395.406 97.5 395.645 97.5988 395.821 97.7746C395.996 97.9504 396.095 98.1889 396.095 98.4375V1026.56C396.095 1026.81 395.996 1027.05 395.821 1027.23C395.645 1027.4 395.406 1027.5 395.158 1027.5Z" fill="#F6EEE8" />
            <path d="M395.158 424.631H179.72C179.472 424.631 179.233 424.533 179.057 424.357C178.881 424.181 178.783 423.942 178.783 423.694C178.783 423.445 178.881 423.207 179.057 423.031C179.233 422.855 179.472 422.756 179.72 422.756H395.158C395.406 422.756 395.645 422.855 395.821 423.031C395.996 423.207 396.095 423.445 396.095 423.694C396.095 423.942 395.996 424.181 395.821 424.357C395.645 424.533 395.406 424.631 395.158 424.631Z" fill="#F6EEE8" />
            <path d="M395.158 717.581H168.62C168.371 717.581 168.133 717.483 167.957 717.307C167.781 717.131 167.683 716.892 167.683 716.644C167.683 716.395 167.781 716.157 167.957 715.981C168.133 715.805 168.371 715.706 168.62 715.706H395.12C395.369 715.706 395.607 715.805 395.783 715.981C395.959 716.157 396.058 716.395 396.058 716.644C396.058 716.892 395.959 717.131 395.783 717.307C395.607 717.483 395.369 717.581 395.12 717.581H395.158Z" fill="#F6EEE8" />
            <path d="M610.595 424.631H395.158C394.909 424.631 394.671 424.533 394.495 424.357C394.319 424.181 394.22 423.942 394.22 423.694C394.22 423.445 394.319 423.207 394.495 423.031C394.671 422.855 394.909 422.756 395.158 422.756H610.595C610.844 422.756 611.082 422.855 611.258 423.031C611.434 423.207 611.533 423.445 611.533 423.694C611.533 423.942 611.434 424.181 611.258 424.357C611.082 424.533 610.844 424.631 610.595 424.631Z" fill="#F6EEE8" />
            <path d="M621.658 717.581H395.158C394.909 717.581 394.671 717.483 394.495 717.307C394.319 717.131 394.22 716.892 394.22 716.644C394.22 716.395 394.319 716.157 394.495 715.981C394.671 715.805 394.909 715.706 395.158 715.706H621.658C621.906 715.706 622.145 715.805 622.321 715.981C622.496 716.157 622.595 716.395 622.595 716.644C622.595 716.892 622.496 717.131 622.321 717.307C622.145 717.483 621.906 717.581 621.658 717.581Z" fill="#F6EEE8" />
            <path d="M366.395 977.569C374.739 987.881 381.395 992.981 392.083 992.137L393.133 962.925C393.133 962.925 376.745 967.013 366.395 977.569Z" fill="black" />
            <path d="M395.158 968.7C382.726 972.788 379.276 975.469 370.614 987.45C367.987 991.019 365.631 994.78 363.564 998.7C359.983 1005.62 358.745 1011.21 360.526 1014.3C363.226 1018.93 368.908 1022.96 365.158 1025.19C361.408 1027.43 351.564 1024.73 348.114 1021.63C344.664 1018.54 334.22 1004.93 324.151 986.944C314.083 968.963 311.364 962.156 310.201 957.694C309.039 953.231 309.095 952.069 315.17 946.444L324.545 937.931C334.726 928.725 348.62 916.256 348.62 916.256C348.62 916.256 354.433 909.188 359.87 919.256C365.308 929.325 395.158 968.7 395.158 968.7Z" fill="#C15D31" />
            <path d="M470.814 971.569C471.039 976.463 459.376 979.275 447.939 980.044L449.701 965.513C449.701 965.513 470.214 958.706 470.814 971.569Z" fill="black" />
            <path d="M475.876 1026.56H386.12C386.12 1026.56 386.12 1024.31 386.495 1015.31C386.608 1012.84 386.739 1009.88 386.908 1006.31C387.714 988.8 387.583 967.931 387.583 967.931L454.351 957.713C454.351 969.75 452.251 995.738 453.751 1001.42C454.084 1002.65 454.589 1003.82 455.251 1004.91C459.189 1011.58 468.733 1017.43 475.876 1020.77C484.314 1024.54 482.795 1026.56 475.876 1026.56Z" fill="#C15D31" />
            <path d="M565.689 760.313C563.064 742.331 548.664 680.588 542.495 658.313C537.583 640.669 518.908 561.319 508.745 516.469C506.064 504.769 503.983 495.413 502.858 490.106L412.858 482.981L397.37 489.581C397.089 494.963 396.733 501.488 396.339 508.706C395.158 530.606 393.695 559.031 393.283 582.544C392.72 613.781 393.095 671.419 393.639 714.281C394.183 757.144 388.014 879.131 388.014 879.131L348.62 916.181C351.62 916.369 357.414 923.681 365.101 938.081C372.789 952.481 388.108 969.694 388.108 969.694C401.589 973.069 429.358 971.569 448.108 970.069C466.858 968.569 470.739 971.569 470.739 971.569V906.638C470.739 906.638 517.37 856.294 541.745 829.538C566.12 802.781 568.314 778.275 565.689 760.313ZM473.814 795.825C473.983 786.281 474.095 776.719 474.095 767.306C474.095 754.613 474.395 740.213 474.845 725.288L491.833 777.638C491.833 777.638 485.626 783.356 473.739 795.788L473.814 795.825Z" fill="#B8C1D0" />
            <path d="M508.708 516.413C478.708 524.363 432.17 524.1 396.414 508.65C396.808 501.431 397.164 494.906 397.445 489.525L412.802 482.925L502.802 490.05C503.945 495.413 506.027 504.769 508.708 516.413Z" fill="#124E66" />
            <path d="M496.539 519.788C496.374 519.786 496.215 519.727 496.088 519.621C495.962 519.515 495.876 519.369 495.845 519.206C495.842 519.169 495.842 519.131 495.845 519.094C495.845 518.175 493.652 504.094 492.245 494.719C492.218 494.535 492.265 494.347 492.376 494.198C492.486 494.049 492.652 493.949 492.836 493.922C493.02 493.895 493.207 493.941 493.357 494.052C493.506 494.163 493.606 494.329 493.633 494.513C493.633 494.513 494.552 500.625 495.508 506.756C497.383 519.675 497.383 519.675 496.708 519.881L496.539 519.788Z" fill="black" />
            <path d="M480.077 521.775C479.894 521.77 479.72 521.696 479.591 521.567C479.462 521.437 479.388 521.264 479.383 521.081V495.788C479.41 495.621 479.496 495.469 479.625 495.36C479.753 495.25 479.917 495.19 480.086 495.19C480.255 495.19 480.418 495.25 480.547 495.36C480.676 495.469 480.762 495.621 480.789 495.788V521.1C480.779 521.282 480.7 521.454 480.568 521.58C480.435 521.705 480.259 521.775 480.077 521.775Z" fill="black" />
            <path d="M468.452 522.338C468.275 522.334 468.107 522.264 467.978 522.143C467.85 522.022 467.772 521.857 467.758 521.681L465.752 495.788C465.741 495.602 465.804 495.419 465.927 495.279C466.049 495.139 466.222 495.052 466.408 495.038C466.594 495.027 466.776 495.09 466.917 495.213C467.057 495.336 467.143 495.508 467.158 495.694L469.164 521.569C469.174 521.755 469.112 521.938 468.989 522.078C468.866 522.218 468.694 522.305 468.508 522.319L468.452 522.338Z" fill="black" />
            <path d="M453.283 522.038C453.103 522.033 452.932 521.961 452.803 521.836C452.675 521.711 452.598 521.542 452.589 521.363L451.876 494.681C451.871 494.495 451.939 494.315 452.065 494.178C452.191 494.042 452.366 493.96 452.551 493.95C452.644 493.945 452.737 493.959 452.824 493.99C452.911 494.021 452.991 494.07 453.06 494.133C453.128 494.196 453.183 494.272 453.221 494.356C453.259 494.441 453.28 494.532 453.283 494.625L453.995 521.306C454 521.492 453.932 521.673 453.806 521.809C453.68 521.946 453.506 522.028 453.32 522.038H453.283Z" fill="black" />
            <path d="M440.12 520.8C439.939 520.8 439.766 520.73 439.636 520.604C439.507 520.478 439.431 520.306 439.426 520.125L438.526 496.5C438.521 496.408 438.535 496.315 438.566 496.228C438.598 496.14 438.646 496.06 438.709 495.992C438.772 495.924 438.848 495.869 438.933 495.831C439.017 495.792 439.109 495.771 439.201 495.769C439.295 495.761 439.389 495.773 439.477 495.803C439.566 495.834 439.647 495.882 439.716 495.946C439.784 496.009 439.839 496.086 439.876 496.172C439.914 496.258 439.933 496.35 439.933 496.444L440.833 520.069C440.838 520.162 440.824 520.254 440.793 520.342C440.761 520.429 440.713 520.509 440.65 520.577C440.587 520.645 440.511 520.7 440.426 520.739C440.342 520.777 440.25 520.798 440.158 520.8H440.12Z" fill="black" />
            <path d="M429.883 519.263C429.7 519.258 429.526 519.183 429.397 519.054C429.268 518.925 429.194 518.751 429.189 518.569L428.645 494.625C428.643 494.454 428.702 494.288 428.813 494.157C428.923 494.026 429.076 493.94 429.245 493.913C429.428 493.921 429.602 493.997 429.733 494.124C429.864 494.252 429.944 494.424 429.958 494.606L430.501 518.55C430.501 518.736 430.429 518.914 430.299 519.047C430.17 519.18 429.993 519.258 429.808 519.263H429.883Z" fill="black" />
            <path d="M416.439 516.281C416.346 516.279 416.255 516.258 416.17 516.22C416.086 516.181 416.01 516.126 415.947 516.058C415.884 515.99 415.835 515.91 415.804 515.823C415.772 515.735 415.759 515.643 415.764 515.55L416.776 492.281C416.776 492.095 416.85 491.916 416.982 491.784C417.114 491.652 417.293 491.578 417.479 491.578C417.666 491.578 417.845 491.652 417.977 491.784C418.108 491.916 418.182 492.095 418.182 492.281L417.17 515.55C417.175 515.647 417.16 515.745 417.125 515.836C417.091 515.927 417.037 516.01 416.968 516.079C416.899 516.148 416.816 516.202 416.725 516.237C416.634 516.271 416.536 516.287 416.439 516.281Z" fill="black" />
            <path d="M405.339 512.888C405.246 512.885 405.155 512.864 405.07 512.826C404.986 512.788 404.91 512.733 404.847 512.665C404.784 512.597 404.736 512.516 404.704 512.429C404.673 512.342 404.659 512.249 404.664 512.156L405.752 490.275C405.752 490.089 405.826 489.91 405.958 489.778C406.089 489.646 406.268 489.572 406.455 489.572C406.641 489.572 406.82 489.646 406.952 489.778C407.084 489.91 407.158 490.089 407.158 490.275L406.07 512.156C406.076 512.254 406.061 512.351 406.026 512.443C405.991 512.534 405.937 512.617 405.868 512.686C405.799 512.755 405.716 512.808 405.625 512.843C405.534 512.878 405.437 512.893 405.339 512.888Z" fill="black" />
            <path d="M704.87 421.369C716.12 421.369 728.72 421.181 736.127 422.7C756.264 426.825 761.345 438.6 766.82 462.075C772.445 486.45 776.102 495.694 780.433 514.219C772.708 514.5 765.902 514.856 743.402 518.1C720.902 521.344 720.527 529.088 720.527 529.088L716.195 506.588L691.445 607.087H578.945C578.945 607.087 590.57 508.575 592.37 496.463C592.37 496.463 588.47 453.938 598.745 440.456C609.02 426.975 616.895 421.35 653.852 421.35C653.852 421.35 655.295 414.6 656.42 413.963C657.545 413.325 663.32 413.963 672.17 413.963C681.02 413.963 681.114 419.588 681.114 435.094C681.114 435.094 687.189 420.825 688.614 417.694C690.039 414.563 697.989 415.669 702.695 415.819C705.808 415.95 704.87 421.369 704.87 421.369Z" fill="#5B77C9" />
            <path d="M610.951 558.019C605.326 557.044 597.245 554.419 596.533 548.906C596.533 548.906 596.533 548.794 596.533 548.756C596.533 548.381 597.433 547.256 603.395 545.325C604.483 544.969 605.758 544.594 607.145 544.181L610.951 558.019Z" fill="black" />
            <path d="M803.383 596.625L769.483 596.944C767.289 589.444 751.502 579.281 748.858 578.344C746.214 577.406 741.189 577.969 719.702 583.388C698.214 588.806 663.695 598.256 654.489 601.95C645.283 605.644 624.602 605.569 621.302 603.45C618.002 601.331 616.145 589.144 612.789 576.788C610.258 567.413 605.514 552.169 603.414 545.325C610.202 543.131 623.702 539.85 650.402 535.369L653.102 547.538C653.102 547.538 666.227 547.763 689.552 543.45C712.877 539.138 739.764 534.431 748.389 535.369C759.058 536.531 768.452 549.413 774.639 555.375C779.993 560.572 785.627 565.473 791.514 570.056C798.152 575.363 799.145 575.981 799.802 579.3C800.458 582.619 803.383 596.625 803.383 596.625Z" fill="#FFDCAE" />
            <path d="M632.439 601.312H563.514C563.514 601.312 552.47 599.438 549.152 588.488C547.808 583.394 546.868 578.203 546.339 572.963C546.133 571.256 545.964 569.55 545.795 567.863C545.627 566.175 545.458 564.319 545.327 562.631L545.214 561.019C545.214 559.95 545.064 558.938 545.008 557.963C545.008 557.475 545.008 557.006 545.008 556.556C545.017 556.463 545.017 556.369 545.008 556.275C545.008 555.619 545.008 554.981 545.008 554.4C546.02 554.4 550.277 554.269 555.733 554.081L566.72 553.725L577.783 553.35L587.158 553.031C587.832 558.677 589.134 564.23 591.039 569.587C592.543 574.348 595.182 578.672 598.727 582.187C604.783 587.419 613.352 588.488 620.12 589.913C626.889 591.338 628.67 593.663 628.67 595.013C629.9 595.508 630.934 596.391 631.614 597.529C632.294 598.666 632.584 599.995 632.439 601.312Z" fill="#C15D31" />
            <path d="M542.908 337.481C548.814 336.056 558.845 339.056 565.951 355.556C573.058 372.056 578.026 404.644 580.239 429.019C581.27 440.438 583.258 468.806 585.339 495.169C587.701 525.169 589.97 552.619 587.008 553.031L545.439 554.7C542.303 553.114 539.657 550.706 537.781 547.735C535.906 544.763 534.872 541.338 534.789 537.825C533.964 525 534.789 439.406 534.789 439.406C534.789 439.406 527.289 469.031 521.514 486.488C515.739 503.944 495.714 505.875 481.745 503.1C481.745 503.1 425.083 505.313 397.501 489.6C397.501 489.6 404.495 424.894 416.495 389.419C428.495 353.944 431.101 342.338 470.87 341.231C470.87 341.231 474.376 329.681 479.326 328.313C488.476 325.763 495.583 325.875 514.539 327.075L542.908 337.481Z" fill="#124E66" />
            <path d="M494.233 398.081C494.141 398.072 494.053 398.045 493.972 398.001C493.891 397.957 493.82 397.898 493.763 397.826C493.705 397.755 493.662 397.672 493.637 397.584C493.611 397.496 493.603 397.404 493.614 397.313C494.639 386.965 495.008 376.563 494.72 366.169C494.703 366.067 494.709 365.962 494.737 365.863C494.764 365.763 494.813 365.671 494.88 365.592C494.947 365.514 495.03 365.45 495.124 365.407C495.218 365.364 495.32 365.341 495.423 365.341C495.527 365.341 495.629 365.364 495.722 365.407C495.816 365.45 495.899 365.514 495.966 365.592C496.033 365.671 496.082 365.763 496.11 365.863C496.137 365.962 496.143 366.067 496.126 366.169C496.412 376.626 496.036 387.09 495.001 397.5C494.974 397.678 494.878 397.837 494.734 397.946C494.591 398.054 494.411 398.103 494.233 398.081Z" fill="black" />
            <path d="M501.414 324.994C503.458 312.356 512.533 296.381 516.283 291.375C516.726 290.713 517.31 290.157 517.993 289.748C518.677 289.339 519.443 289.087 520.236 289.009C521.029 288.932 521.829 289.032 522.578 289.302C523.328 289.571 524.008 290.004 524.57 290.569C529.445 284.944 540.32 287.044 541.014 293.906C547.314 291.15 555.152 297.769 552.095 304.931C555.527 305.063 556.127 311.494 550.22 311.456L521.383 336L501.414 324.994Z" fill="black" />
            <path d="M510.47 323.813C515.739 320.419 521.27 315.994 521.27 312.094C521.27 310.538 526.352 308.344 529.408 307.125C532.464 305.906 534.545 306.094 538.37 308.156C542.003 310.179 546.065 311.31 550.22 311.456C550.22 311.456 553.108 326.85 538.558 352.331C534.452 359.513 521.402 365.569 509.289 353.081L505.933 380.756C505.933 380.756 494.87 357.038 492.32 341.606C492.32 341.606 501.17 337.594 503.42 332.569C502.626 332.182 501.916 331.641 501.334 330.976C500.752 330.311 500.309 329.536 500.031 328.698C499.754 327.859 499.647 326.973 499.717 326.092C499.787 325.211 500.032 324.353 500.439 323.569C503.083 319.388 507.414 320.269 510.47 323.813Z" fill="#C15D31" />
            <path d="M769.483 597.656C769.33 597.658 769.181 597.609 769.059 597.518C768.937 597.426 768.849 597.297 768.808 597.15C766.708 589.969 751.389 579.994 748.614 579.019C748.461 578.94 748.342 578.809 748.279 578.649C748.216 578.489 748.212 578.312 748.269 578.149C748.326 577.987 748.44 577.851 748.59 577.766C748.739 577.681 748.914 577.654 749.083 577.688C751.989 578.719 767.195 588.563 769.989 596.25L802.52 595.95C801.883 592.875 799.652 582.281 799.07 579.469C798.489 576.656 797.777 576.019 792.152 571.519L791.045 570.638C785.12 566.043 779.449 561.129 774.058 555.919C772.783 554.681 771.377 553.144 769.877 551.513C764.252 545.381 756.508 537 748.277 536.1C743.525 535.898 738.766 536.155 734.064 536.869C733.971 536.882 733.877 536.876 733.787 536.852C733.696 536.828 733.612 536.785 733.538 536.728C733.465 536.67 733.404 536.598 733.359 536.516C733.314 536.434 733.286 536.343 733.277 536.25C733.255 536.064 733.308 535.877 733.424 535.729C733.54 535.582 733.709 535.486 733.895 535.463C738.701 534.722 743.569 534.458 748.427 534.675C757.164 535.631 765.114 544.238 770.927 550.538C772.408 552.15 773.814 553.669 775.07 554.888C780.426 560.065 786.06 564.947 791.945 569.513L793.052 570.394C798.789 574.969 799.82 575.794 800.552 579.169C801.283 582.544 804.17 596.494 804.17 596.494C804.193 596.595 804.192 596.7 804.169 596.801C804.145 596.902 804.099 596.996 804.035 597.077C803.97 597.158 803.888 597.224 803.795 597.269C803.701 597.314 803.599 597.337 803.495 597.338L769.595 597.656H769.483Z" fill="black" />
            <path d="M597.339 549.731C597.164 549.732 596.996 549.667 596.867 549.549C596.739 549.43 596.659 549.268 596.645 549.094L591.864 496.331C591.841 496.232 591.84 496.13 591.86 496.03C591.88 495.931 591.921 495.837 591.981 495.754C592.04 495.672 592.117 495.603 592.205 495.553C592.293 495.502 592.391 495.472 592.492 495.462C592.593 495.453 592.695 495.465 592.791 495.498C592.887 495.531 592.975 495.585 593.049 495.655C593.122 495.724 593.18 495.809 593.218 495.903C593.257 495.998 593.274 496.099 593.27 496.2L597.995 548.963C598.011 549.149 597.953 549.334 597.834 549.477C597.714 549.621 597.543 549.712 597.358 549.731H597.339Z" fill="black" />
            <path d="M650.364 536.081C650.199 536.08 650.04 536.021 649.913 535.915C649.787 535.809 649.701 535.662 649.67 535.5C647.795 525.356 646.052 517.65 644.402 510.206C642.845 503.231 641.402 496.669 640.033 489.169C640 489.072 639.989 488.968 640 488.866C640.01 488.764 640.043 488.666 640.095 488.577C640.148 488.489 640.219 488.413 640.303 488.355C640.388 488.297 640.484 488.257 640.585 488.24C640.686 488.222 640.79 488.226 640.889 488.252C640.988 488.278 641.081 488.325 641.16 488.39C641.239 488.456 641.304 488.537 641.349 488.629C641.394 488.722 641.418 488.822 641.42 488.925C642.77 496.425 644.233 502.969 645.77 509.906C647.42 517.406 649.145 525.094 651.058 535.238C651.077 535.329 651.077 535.423 651.059 535.514C651.041 535.606 651.004 535.693 650.952 535.77C650.899 535.847 650.832 535.912 650.753 535.963C650.675 536.013 650.587 536.047 650.495 536.063L650.364 536.081Z" fill="black" />
            <path d="M1204.69 712.275C1201.43 755.4 1196.96 794.344 1193.25 838.556C1189.54 882.769 1186.13 931.462 1182 947.494C1182 947.494 1183.18 938.888 1169.98 937.106C1159.93 935.738 1120.11 938.738 1096.26 938.813C1091.91 938.637 1087.57 939.273 1083.45 940.688C1083.45 940.688 1091.83 902.925 1099.18 866.925C1102.13 852.619 1104.58 838.2 1104.58 838.2C1104.58 838.2 1071.28 836.663 1069.93 805.8C1068.73 778.594 1068.06 740.306 1068.06 722.85C1068.06 705.394 1068.7 576.469 1067.76 566.794C1067.48 563.944 1065.36 560.644 1062.13 557.119C1074.3 559.969 1089.11 560.006 1107.41 546.806C1126.39 533.1 1124.57 517.144 1124.57 517.144C1132.07 521.306 1145.35 524.269 1165.22 533.625C1185.1 542.981 1191.64 548.756 1199.36 564.338C1207.09 579.919 1206.86 591.938 1207.82 614.213C1208.78 636.488 1207.95 669.244 1204.69 712.275Z" fill="#5B77C9" />
            <path d="M1238.48 328.613H1092.81V430.894H1238.48V328.613Z" fill="#E4D0C0" />
            <path d="M1227.08 336.638H1104.25V422.869H1227.08V336.638Z" fill="#F6EEE8" />
            <path d="M576.395 607.05C574.239 634.913 575.739 668.1 600.77 688.725C612.358 698.25 633.451 704.494 658.276 705.188C692.214 706.144 723.001 706.088 733.67 702.694C752.308 703.931 761.664 704.25 768.714 704.569L784.483 756.844C784.483 756.844 758.814 809.344 708.076 889.538C708.076 889.538 745.576 913.35 765.92 926.663C786.264 939.975 791.289 943.669 788.195 952.331C788.739 950.456 807.058 920.156 822.845 892.331L833.664 948.581C833.664 948.581 835.539 943.913 861.508 934.35C886.97 924.975 912.358 912.956 924.301 917.963L885.32 781.556C885.32 781.556 912.883 727.181 915.32 708.619C918.451 685.594 916.951 638.494 839.045 643.181C839.045 643.181 823.502 625.65 776.364 621.338C732.077 617.288 684.189 608.663 684.189 608.663L576.395 607.05Z" fill="#F88D7A" />
            <path d="M946.745 130.069C946.563 130.064 946.389 129.989 946.26 129.86C946.131 129.731 946.056 129.558 946.051 129.375V41.4188C946.079 41.2521 946.164 41.1004 946.293 40.991C946.422 40.8816 946.585 40.8215 946.754 40.8215C946.923 40.8215 947.087 40.8816 947.216 40.991C947.344 41.1004 947.43 41.2521 947.458 41.4188V129.356C947.458 129.545 947.382 129.727 947.249 129.86C947.115 129.994 946.934 130.069 946.745 130.069Z" fill="black" />
            <path d="M946.708 171.863C955.686 171.863 962.964 167.858 962.964 162.919C962.964 157.979 955.686 153.975 946.708 153.975C937.73 153.975 930.452 157.979 930.452 162.919C930.452 167.858 937.73 171.863 946.708 171.863Z" fill="white" />
            <path d="M992.177 142.969C963.189 135.319 952.089 129.506 946.689 120.956C941.289 129.506 930.189 135.319 901.202 142.969C871.202 150.9 866.964 162.863 866.889 162.9H1026.47C1026.47 162.9 1022.23 150.9 992.177 142.969Z" fill="#F09400" />
            <path d="M1222.03 233.625C1221.85 233.62 1221.68 233.546 1221.55 233.417C1221.42 233.288 1221.34 233.114 1221.34 232.931V77.5126C1221.37 77.3458 1221.45 77.1942 1221.58 77.0848C1221.71 76.9754 1221.87 76.9153 1222.04 76.9153C1222.21 76.9153 1222.37 76.9754 1222.5 77.0848C1222.63 77.1942 1222.72 77.3458 1222.75 77.5126V232.913C1222.75 233.102 1222.67 233.283 1222.54 233.416C1222.4 233.55 1222.22 233.625 1222.03 233.625Z" fill="black" />
            <path d="M1222.03 270.619C1230 270.619 1236.45 267.068 1236.45 262.688C1236.45 258.307 1230 254.756 1222.03 254.756C1214.07 254.756 1207.61 258.307 1207.61 262.688C1207.61 267.068 1214.07 270.619 1222.03 270.619Z" fill="white" />
            <path d="M1262.38 244.988C1236.66 238.2 1226.76 233.025 1222.03 225.45C1217.25 232.95 1207.41 238.2 1181.68 244.988C1155.02 252.019 1151.29 262.65 1151.23 262.688H1292.83C1292.83 262.688 1289.05 252.038 1262.38 244.988Z" fill="#F09400" />
            <path d="M227.964 329.775C227.798 329.777 227.637 329.72 227.509 329.613C227.382 329.507 227.297 329.358 227.27 329.194C227.27 329.063 225.02 315.225 224.495 304.463C224.49 304.37 224.504 304.277 224.535 304.19C224.567 304.103 224.615 304.023 224.678 303.954C224.741 303.886 224.817 303.831 224.902 303.793C224.986 303.755 225.077 303.734 225.17 303.731C225.355 303.731 225.533 303.8 225.668 303.925C225.804 304.051 225.887 304.222 225.901 304.406C226.426 315.094 228.639 328.781 228.658 328.988C228.674 329.079 228.671 329.172 228.651 329.263C228.63 329.353 228.591 329.438 228.537 329.513C228.483 329.589 228.414 329.652 228.335 329.7C228.256 329.748 228.168 329.78 228.076 329.794L227.964 329.775Z" fill="black" />
            <path d="M233.083 264.806C237.395 252.263 262.333 250.781 270.095 253.556C270.095 253.556 264.77 244.631 269.495 241.219C274.914 237.3 284.495 243.319 286.539 253.013C286.539 253.013 291.751 242.025 299.476 243.338C307.201 244.65 298.164 264.806 298.164 264.806H233.083Z" fill="#8EBF8C" />
            <path d="M255.826 251.269C256.144 254.9 255.423 258.547 253.746 261.784C252.07 265.021 249.507 267.715 246.358 269.55C235.426 276.075 233.083 269.55 233.083 269.55L241.651 260.306L255.826 251.269Z" fill="black" />
            <path d="M301.895 256.856C292.108 253.106 280.633 259.725 279.077 272.513L291.414 274.088L301.895 256.856Z" fill="black" />
            <path d="M277.858 263.044H210.92C223.052 307.95 262.952 307.819 277.858 307.819C292.764 307.819 332.664 307.95 344.795 263.044H277.858Z" fill="#E4D0C0" />
            <path d="M330.92 357.038C330.758 357.037 330.6 356.981 330.474 356.879C330.348 356.776 330.261 356.634 330.227 356.475C329.495 352.969 329.289 343.125 329.027 331.706C328.745 318.581 328.426 303.9 327.283 293.531C325.014 273 319.427 262.95 315.433 257.156C315.326 257.002 315.285 256.812 315.318 256.627C315.351 256.443 315.457 256.279 315.611 256.172C315.765 256.065 315.955 256.024 316.14 256.057C316.325 256.091 316.488 256.196 316.595 256.35C320.683 262.256 326.383 272.531 328.689 293.363C329.833 303.788 330.17 318.6 330.451 331.65C330.695 342.9 330.901 352.763 331.614 356.156C331.648 356.338 331.61 356.525 331.508 356.678C331.407 356.832 331.25 356.941 331.07 356.981L330.92 357.038Z" fill="black" />
            <path d="M219.526 268.687C223.014 261.187 201.133 248.887 195.639 258.806C188.439 271.762 215.87 276.562 219.526 268.687Z" fill="#4B764A" />
            <path d="M332.627 243.45C332.514 247.65 328.989 253.406 324.883 256.988C320.777 260.569 316.352 261.919 313.914 257.831C308.12 248.081 325.577 233.869 331.37 239.775C331.82 240.269 332.164 240.85 332.38 241.482C332.596 242.114 332.68 242.784 332.627 243.45Z" fill="#8EBF8C" />
            <path d="M344.87 297.038C342.695 299.081 339.001 298.35 335.383 296.306C329.401 292.913 323.608 285.938 325.22 281.981C325.481 281.342 325.924 280.794 326.495 280.406C335.626 273.694 350.833 291.281 344.87 297.038Z" fill="#4B764A" />
            <path d="M318.32 333.881C323.177 340.594 339.583 322.969 332.402 314.194C325.52 305.756 314.177 328.125 318.32 333.881Z" fill="#8EBF8C" />
            <path d="M331.351 351.581C325.726 357.019 316.67 360.056 314.927 355.331C313.389 351.169 320.177 342.206 326.495 338.606C327.617 337.926 328.847 337.444 330.133 337.181C330.81 337.005 331.52 336.995 332.202 337.151C332.885 337.308 333.519 337.627 334.052 338.081C334.617 338.598 335.054 339.24 335.327 339.956C337.089 343.819 334.876 348.169 331.351 351.581Z" fill="#8EBF8C" />
            <path d="M235.164 314.963C231.808 316.35 227.495 312.863 224.439 308.194C220.202 301.763 218.383 293.081 224.889 292.05C226.141 291.872 227.415 291.964 228.628 292.319C229.841 292.675 230.964 293.287 231.92 294.113C238.464 299.363 241.014 312.525 235.164 314.963Z" fill="#8EBF8C" />
            <path d="M204.245 326.625C207.995 330.375 223.408 322.331 216.376 313.5C211.539 307.238 199.295 321.844 204.245 326.625Z" fill="#8EBF8C" />
            <path d="M231.92 294.113C230.887 299.452 228.274 304.357 224.42 308.194C220.183 301.763 218.364 293.081 224.87 292.05C226.124 291.869 227.403 291.96 228.619 292.315C229.836 292.671 230.961 293.284 231.92 294.113Z" fill="black" />
            <path d="M332.626 243.45C332.514 247.65 328.989 253.406 324.883 256.988L323.008 257.513C322.726 257.494 320.495 247.931 332.626 243.45Z" fill="black" />
            <path d="M335.326 339.863C335.053 339.147 334.617 338.505 334.051 337.988L330.133 337.088C328.847 337.35 327.617 337.832 326.495 338.513C326.134 340.913 326.391 343.365 327.241 345.638C328.09 347.912 329.505 349.931 331.351 351.506C334.876 348.169 337.089 343.819 335.326 339.863Z" fill="black" />
            <path d="M335.308 296.231C329.326 292.838 323.533 285.862 325.145 281.906C328.178 282.844 330.818 284.756 332.655 287.346C334.492 289.935 335.425 293.058 335.308 296.231Z" fill="black" />
            <path d="M214.089 304.106C220.502 309.338 236.289 293.288 229.427 284.231C222.564 275.175 207.377 298.631 214.089 304.106Z" fill="#4B764A" />
            <path d="M347.87 253.294C346.745 245.081 323.851 246.206 322.708 257.494C321.564 268.781 349.033 261.881 347.87 253.294Z" fill="#4B764A" />
            <path d="M305.795 290.813C310.295 297.769 329.008 282.525 323.233 272.756C317.683 263.381 301.089 283.538 305.795 290.813Z" fill="#8EBF8C" />
            <path d="M327.095 379.181C337.951 383.119 343.37 354.806 331.858 353.475C323.458 352.537 320.495 376.762 327.095 379.181Z" fill="#4B764A" />
            <path d="M333.62 310.294C341.908 310.294 335.964 285.431 323.476 287.794C312.77 289.744 322.07 310.388 333.62 310.294Z" fill="#4B764A" />
            <path d="M343.277 351.544C351.152 350.625 341.72 326.419 330.152 330.019C320.327 333.094 332.308 352.819 343.277 351.544Z" fill="#4B764A" />
            <path d="M289.727 269.194C295.033 275.55 312.789 265.819 306.902 256.069C301.464 247.181 284.177 262.538 289.727 269.194Z" fill="#4B764A" />
            <path d="M261.564 247.05C260.177 238.875 229.539 247.444 231.133 260.794C232.895 275.513 263.027 255.581 261.564 247.05Z" fill="#4B764A" />
            <path d="M228.114 339.488C236.402 339.206 237.077 316.988 225.733 316.781C217.839 316.669 219.452 339.788 228.114 339.488Z" fill="#4B764A" />
            <path d="M277.858 308.513C277.675 308.508 277.502 308.433 277.373 308.304C277.244 308.175 277.169 308.001 277.164 307.819V35.5313C277.147 35.4294 277.153 35.325 277.181 35.2254C277.208 35.1259 277.257 35.0336 277.324 34.9548C277.391 34.8761 277.474 34.8128 277.568 34.7695C277.662 34.7261 277.764 34.7036 277.867 34.7036C277.971 34.7036 278.073 34.7261 278.166 34.7695C278.26 34.8128 278.343 34.8761 278.41 34.9548C278.477 35.0336 278.526 35.1259 278.554 35.2254C278.581 35.325 278.587 35.4294 278.57 35.5313V307.819C278.566 308.005 278.488 308.181 278.355 308.311C278.222 308.44 278.044 308.513 277.858 308.513Z" fill="black" />
            <path d="M211.858 269.606C211.725 269.601 211.597 269.559 211.487 269.485C211.377 269.411 211.29 269.308 211.236 269.188C211.181 269.067 211.161 268.933 211.178 268.802C211.196 268.67 211.249 268.546 211.333 268.444L277.37 192.263C277.435 192.187 277.516 192.125 277.606 192.083C277.697 192.041 277.795 192.019 277.895 192.019C277.995 192.02 278.093 192.043 278.184 192.085C278.274 192.127 278.355 192.187 278.42 192.263L344.045 268.05C344.123 268.117 344.185 268.2 344.228 268.293C344.27 268.386 344.292 268.488 344.292 268.59C344.292 268.693 344.27 268.794 344.227 268.887C344.184 268.98 344.122 269.063 344.044 269.13C343.966 269.196 343.875 269.246 343.777 269.274C343.678 269.302 343.575 269.309 343.474 269.294C343.372 269.279 343.275 269.242 343.19 269.186C343.104 269.129 343.031 269.056 342.977 268.969L277.895 193.8L212.42 269.363C212.352 269.444 212.265 269.508 212.168 269.55C212.07 269.592 211.964 269.611 211.858 269.606Z" fill="black" />
            <motion.path initial={{ scaleY: 1 }}
                animate={blinkAnimation}
                style={{ transformOrigin: "center" }} d="M710.437 377.154C710.629 375.842 710.179 374.69 709.431 374.581C708.683 374.471 707.921 375.445 707.728 376.756C707.536 378.068 707.986 379.22 708.734 379.33C709.482 379.439 710.244 378.465 710.437 377.154Z" fill="black" />
            <motion.path initial={{ scaleY: 1 }}
                animate={blinkAnimation}
                style={{ transformOrigin: "center" }} d="M1002.04 413.279C1002.23 411.968 1001.78 410.816 1001.03 410.706C1000.28 410.596 999.521 411.57 999.329 412.882C999.137 414.193 999.587 415.345 1000.33 415.455C1001.08 415.565 1001.85 414.591 1002.04 413.279Z" fill="black" />
            <path d="M683.589 384.263C683.441 384.264 683.296 384.218 683.176 384.131L677.832 380.138C677.741 380.09 677.661 380.023 677.598 379.941C677.535 379.859 677.49 379.764 677.467 379.664C677.444 379.563 677.444 379.458 677.465 379.357C677.487 379.256 677.53 379.161 677.592 379.078C677.654 378.996 677.733 378.928 677.824 378.879C677.915 378.83 678.016 378.801 678.119 378.795C678.222 378.789 678.325 378.805 678.422 378.842C678.518 378.88 678.605 378.938 678.676 379.013L684.02 383.006C684.137 383.097 684.222 383.222 684.265 383.364C684.308 383.506 684.305 383.657 684.258 383.797C684.21 383.937 684.12 384.059 684 384.146C683.881 384.233 683.737 384.28 683.589 384.281V384.263Z" fill="black" />
            <path d="M710.27 395.663H710.12C707.142 394.819 704.595 392.875 702.995 390.225C702.952 390.144 702.925 390.055 702.917 389.963C702.908 389.872 702.917 389.779 702.944 389.691C702.971 389.603 703.015 389.522 703.074 389.451C703.133 389.38 703.205 389.321 703.286 389.278C703.367 389.235 703.456 389.208 703.548 389.2C703.639 389.191 703.732 389.2 703.819 389.227C703.907 389.254 703.989 389.298 704.06 389.357C704.131 389.416 704.19 389.488 704.233 389.569C705.623 391.827 707.79 393.5 710.326 394.275C710.483 394.325 710.617 394.428 710.706 394.565C710.795 394.703 710.834 394.867 710.817 395.03C710.799 395.193 710.726 395.345 710.609 395.46C710.492 395.575 710.34 395.647 710.176 395.663H710.27Z" fill="black" />
            <path d="M715.595 389.869C715.409 389.869 715.23 389.795 715.098 389.663C714.966 389.531 714.892 389.352 714.892 389.166C714.892 388.979 714.966 388.8 715.098 388.669C715.23 388.537 715.409 388.463 715.595 388.463C716.151 388.508 716.71 388.441 717.239 388.263C717.767 388.086 718.255 387.803 718.67 387.431C718.827 387.204 718.932 386.945 718.978 386.673C719.023 386.401 719.008 386.122 718.933 385.856C718.07 381.619 717.77 371.775 718.933 369.544C720.339 366.994 722.683 365.513 724.558 366.038C724.646 366.062 724.729 366.104 724.802 366.161C724.874 366.217 724.935 366.288 724.98 366.368C725.025 366.448 725.054 366.536 725.066 366.628C725.077 366.719 725.07 366.811 725.045 366.9C725.021 366.989 724.979 367.072 724.922 367.144C724.865 367.217 724.795 367.277 724.715 367.322C724.635 367.368 724.547 367.397 724.455 367.408C724.364 367.419 724.271 367.412 724.183 367.388C723.189 367.106 721.389 368.119 720.226 370.219C719.251 371.981 719.439 381.469 720.226 385.575C720.346 386.039 720.361 386.523 720.271 386.994C720.18 387.464 719.985 387.908 719.701 388.294C719.169 388.839 718.524 389.262 717.812 389.534C717.1 389.806 716.337 389.92 715.576 389.869H715.595Z" fill="black" />
            <path d="M703.407 368.906C703.27 368.913 703.134 368.879 703.016 368.807C702.898 368.736 702.804 368.632 702.746 368.507C702.688 368.383 702.668 368.244 702.689 368.108C702.71 367.972 702.771 367.845 702.864 367.744C705.264 364.875 709.707 363.994 711.526 365.456C711.655 365.577 711.733 365.742 711.748 365.918C711.762 366.093 711.71 366.268 711.603 366.408C711.495 366.548 711.34 366.643 711.167 366.675C710.993 366.707 710.814 366.674 710.664 366.581C709.52 365.7 705.901 366.319 703.932 368.663C703.867 368.738 703.786 368.798 703.696 368.84C703.605 368.882 703.507 368.905 703.407 368.906Z" fill="black" />
            <path d="M1205.96 352.875H1125.36V406.613H1205.96V352.875Z" fill="white" />
            <path d="M983.514 421.088C981.834 421.088 980.192 420.589 978.795 419.656C977.398 418.723 976.309 417.396 975.667 415.844C975.024 414.292 974.856 412.584 975.183 410.937C975.511 409.289 976.32 407.776 977.508 406.588C978.696 405.4 980.209 404.591 981.857 404.263C983.504 403.936 985.212 404.104 986.764 404.747C988.316 405.39 989.643 406.478 990.576 407.875C991.509 409.272 992.008 410.914 992.008 412.594C992.008 414.847 991.113 417.007 989.52 418.6C987.927 420.193 985.766 421.088 983.514 421.088ZM983.514 405.506C982.112 405.506 980.742 405.922 979.576 406.701C978.411 407.48 977.502 408.587 976.966 409.882C976.429 411.177 976.289 412.602 976.562 413.977C976.836 415.351 977.511 416.614 978.502 417.605C979.493 418.597 980.756 419.272 982.131 419.545C983.506 419.819 984.931 419.678 986.226 419.142C987.521 418.605 988.628 417.697 989.407 416.531C990.186 415.366 990.601 413.996 990.601 412.594C990.596 410.717 989.847 408.919 988.519 407.594C987.19 406.269 985.39 405.525 983.514 405.525V405.506Z" fill="black" />
            <path d="M1002.7 421.088C1001.02 421.088 999.373 420.589 997.976 419.656C996.58 418.723 995.491 417.396 994.848 415.844C994.205 414.292 994.037 412.584 994.365 410.937C994.693 409.289 995.502 407.776 996.689 406.588C997.877 405.4 999.391 404.591 1001.04 404.263C1002.69 403.936 1004.39 404.104 1005.95 404.747C1007.5 405.39 1008.82 406.478 1009.76 407.875C1010.69 409.272 1011.19 410.914 1011.19 412.594C1011.19 414.847 1010.29 417.007 1008.7 418.6C1007.11 420.193 1004.95 421.088 1002.7 421.088ZM1002.7 405.506C1001.29 405.506 999.923 405.922 998.758 406.701C997.592 407.48 996.684 408.587 996.147 409.882C995.611 411.177 995.471 412.602 995.744 413.977C996.018 415.351 996.693 416.614 997.684 417.605C998.675 418.597 999.938 419.272 1001.31 419.545C1002.69 419.819 1004.11 419.678 1005.41 419.142C1006.7 418.605 1007.81 417.697 1008.59 416.531C1009.37 415.366 1009.78 413.996 1009.78 412.594C1009.78 410.717 1009.03 408.919 1007.7 407.594C1006.37 406.269 1004.57 405.525 1002.7 405.525V405.506Z" fill="black" />
            <path d="M1019.44 416.55C1019.28 416.561 1019.13 416.519 1019 416.43C1018.87 416.341 1018.77 416.21 1018.73 416.06C1018.68 415.91 1018.69 415.749 1018.75 415.603C1018.8 415.457 1018.91 415.335 1019.05 415.256L1023.15 412.481C1023.23 412.43 1023.31 412.394 1023.4 412.375C1023.49 412.356 1023.59 412.356 1023.68 412.373C1023.77 412.391 1023.85 412.426 1023.93 412.476C1024.01 412.527 1024.07 412.593 1024.13 412.669C1024.18 412.745 1024.21 412.831 1024.23 412.921C1024.25 413.012 1024.25 413.105 1024.23 413.195C1024.22 413.286 1024.18 413.372 1024.13 413.449C1024.08 413.526 1024.02 413.592 1023.94 413.644L1019.87 416.438C1019.74 416.522 1019.59 416.561 1019.44 416.55Z" fill="black" />
            <path d="M1002.7 433.181C1002.53 433.174 1002.38 433.112 1002.26 433.005C1002.14 432.899 1002.05 432.754 1002.03 432.594C1002 432.435 1002.02 432.271 1002.1 432.129C1002.18 431.987 1002.3 431.875 1002.45 431.813C1003.56 431.465 1004.53 430.783 1005.24 429.859C1005.94 428.936 1006.35 427.817 1006.39 426.656C1006.37 426.554 1006.38 426.45 1006.41 426.35C1006.43 426.251 1006.48 426.159 1006.55 426.08C1006.62 426.001 1006.7 425.938 1006.79 425.894C1006.89 425.851 1006.99 425.829 1007.09 425.829C1007.2 425.829 1007.3 425.851 1007.39 425.894C1007.48 425.938 1007.57 426.001 1007.64 426.08C1007.7 426.159 1007.75 426.251 1007.78 426.35C1007.81 426.45 1007.81 426.554 1007.8 426.656C1007.75 428.103 1007.26 429.499 1006.39 430.656C1005.52 431.814 1004.31 432.676 1002.94 433.125L1002.7 433.181Z" fill="black" />
            <path d="M995.645 427.875H995.383C993.49 427.106 991.861 425.803 990.695 424.125C990.21 423.197 990.076 422.125 990.32 421.106C990.32 419.775 990.564 417.544 990.583 413.381C990.583 413.289 990.602 413.197 990.638 413.111C990.674 413.026 990.726 412.949 990.793 412.884C990.859 412.819 990.938 412.769 991.024 412.735C991.111 412.701 991.203 412.685 991.295 412.688C991.481 412.692 991.658 412.77 991.787 412.903C991.917 413.036 991.989 413.214 991.989 413.4C991.989 417.619 991.82 419.888 991.727 421.238C991.633 422.588 991.614 422.925 991.914 423.45C992.913 424.882 994.298 426 995.908 426.675C996.067 426.729 996.202 426.838 996.286 426.984C996.371 427.129 996.4 427.3 996.368 427.465C996.335 427.63 996.245 427.778 996.112 427.881C995.979 427.984 995.813 428.035 995.645 428.025V427.875Z" fill="black" />
            <path d="M1005.23 402.488H1004.98C1002.99 401.798 1000.83 401.732 998.795 402.3C998.618 402.362 998.424 402.352 998.256 402.271C998.087 402.19 997.957 402.046 997.895 401.869C997.833 401.692 997.843 401.498 997.924 401.33C998.005 401.161 998.15 401.031 998.326 400.969C1000.67 400.282 1003.17 400.348 1005.47 401.156C1005.62 401.219 1005.74 401.331 1005.82 401.473C1005.9 401.615 1005.92 401.779 1005.9 401.938C1005.87 402.098 1005.79 402.243 1005.66 402.349C1005.54 402.456 1005.39 402.518 1005.23 402.525V402.488Z" fill="black" />
            <path d="M921.939 497.044C933.02 458.55 940.576 433.294 943.633 422.156C946.689 411.019 950.476 398.869 951.676 393.844C953.345 386.925 955.333 385.931 956.345 390.281C956.345 390.281 957.133 383.681 958.52 382.106C959.908 380.531 963.414 383.756 961.895 394.463C961.895 394.463 966.733 383.981 970.183 380.794C973.633 377.606 972.527 386.419 970.933 390.6C969.339 394.781 963.264 409.781 963.264 409.781C966.326 406.291 970.28 403.698 974.701 402.281C982.033 399.956 985.145 403.706 981.508 405.3C972.264 409.331 963.245 419.888 967.989 421.838C974.383 424.481 980.908 420.319 983.664 422.194C985.933 423.731 983.495 425.119 978.92 427.688C974.991 429.811 971.873 433.173 970.051 437.25C967.108 442.725 964.97 451.125 959.458 490.575L921.939 497.044Z" fill="#FFDCAE" />
            <path d="M978.939 428.288C978.779 428.298 978.62 428.253 978.49 428.16C978.36 428.067 978.266 427.932 978.224 427.777C978.182 427.623 978.195 427.458 978.26 427.312C978.325 427.166 978.439 427.047 978.582 426.975C981.151 425.531 983.795 424.031 983.814 423.225C983.814 423.075 983.607 422.85 983.27 422.606C982.295 421.95 980.439 422.269 978.282 422.606C974.807 423.536 971.135 423.419 967.726 422.269C967.207 422.078 966.759 421.73 966.447 421.273C966.134 420.816 965.972 420.272 965.982 419.719C965.982 415.463 973.633 407.756 981.226 404.438C981.864 404.156 982.464 403.688 982.295 403.219C981.976 402.356 979.408 401.344 974.926 402.731C970.637 404.128 966.803 406.654 963.826 410.044C963.702 410.166 963.538 410.238 963.364 410.247C963.19 410.256 963.019 410.201 962.883 410.092C962.747 409.984 962.656 409.83 962.625 409.658C962.595 409.487 962.629 409.311 962.72 409.163C965.856 405.537 969.93 402.844 974.495 401.381C979.501 399.788 982.932 400.875 983.607 402.713C983.945 403.594 983.607 404.906 981.732 405.713C974.232 408.975 967.295 416.344 967.332 419.681C967.314 419.959 967.389 420.236 967.546 420.466C967.702 420.697 967.93 420.87 968.195 420.956C971.354 422.005 974.753 422.097 977.964 421.219C980.42 420.788 982.539 420.394 984.001 421.388C984.345 421.568 984.633 421.837 984.838 422.167C985.042 422.496 985.155 422.875 985.164 423.263C985.164 424.781 983.007 425.981 979.22 428.119C979.141 428.197 979.045 428.255 978.939 428.288Z" fill="black" />
            <path d="M209.42 914.794C205.22 926.156 185.045 949.125 185.045 949.125L175.876 947.25L184.089 925.462L195.151 896.137C201.395 895.181 205.989 896.512 208.558 899.494C211.426 902.869 211.858 908.287 209.42 914.794Z" fill="#8EBF8C" />
            <motion.path initial={{ rotate: 0 }} animate={{
            rotate: [0, 6, -2, 5, -2, 0],
            transition: {
                duration: 10,
                repeat: Infinity,
                repeatType: "loop",
            },
            }} d="M217.033 848.287C212.327 877.706 180.489 944.287 179.87 944.287C179.514 943.706 172.164 943.35 172.164 943.35L182.158 869.156L189.02 818.194C195.845 815.85 201.639 815.062 206.214 816.037C216.02 818.044 220.258 828.112 217.033 848.287Z" fill="#8EBF8C" />
            <path d="M172.145 914.1C172.02 914.102 171.897 914.069 171.789 914.006C171.633 913.909 171.52 913.756 171.475 913.577C171.429 913.399 171.455 913.21 171.545 913.05C171.826 912.488 196.895 856.613 200.083 845.4C200.156 845.248 200.28 845.126 200.435 845.057C200.589 844.988 200.763 844.976 200.925 845.024C201.087 845.071 201.227 845.174 201.32 845.316C201.413 845.457 201.453 845.626 201.433 845.794C198.17 857.288 172.933 913.5 172.745 913.781C172.68 913.88 172.59 913.961 172.486 914.017C172.381 914.073 172.264 914.101 172.145 914.1Z" fill="black" />
            <motion.path initial={{ rotate: 0 }} animate={{
            rotate: [0, 6, -2, 5, -2, 0],
            transition: {
                duration: 5,
                repeat: Infinity,
                repeatType: "loop",
            },
            }} d="M162.733 949.125L135.751 878.962L128.776 860.812C123.714 841.162 119.908 822.431 120.62 808.144C121.145 796.594 124.595 787.931 132.62 783.937C137.87 781.312 143.233 783.619 148.051 790.969C155.814 802.819 162.133 827.794 164.233 866.325C164.47 870.637 164.664 874.763 164.814 878.7C166.858 930.038 162.733 949.125 162.733 949.125Z" fill="#8EBF8C" />
            <path d="M161.701 947.1C161.539 947.099 161.382 947.043 161.255 946.941C161.129 946.839 161.042 946.696 161.008 946.538C160.82 945.563 141.958 849.431 136.633 810.619C136.619 810.528 136.625 810.435 136.648 810.345C136.671 810.256 136.712 810.173 136.767 810.099C136.823 810.026 136.893 809.964 136.973 809.918C137.053 809.872 137.141 809.843 137.233 809.831C137.324 809.818 137.417 809.823 137.506 809.846C137.595 809.87 137.679 809.91 137.752 809.966C137.826 810.022 137.887 810.092 137.933 810.172C137.979 810.252 138.009 810.34 138.02 810.431C143.364 849.206 162.226 945.3 162.395 946.275C162.428 946.459 162.388 946.648 162.283 946.803C162.177 946.957 162.016 947.064 161.833 947.1H161.701Z" fill="black" />
            {/* <path d="M206.214 816.019C201.02 832.556 192.077 855.938 182.139 869.119L189.002 818.156C195.845 815.831 201.639 815.044 206.214 816.019Z" fill="black" /> */}
            <motion.path initial={{ translateY: 0 }} animate={{
            rotate: [0, 6, -2, 5, -2, 0],
            transition: {
                duration: 20,
                repeat: Infinity,
                repeatType: "loop",
            },
            }} style={{ transformOrigin: 'bottom center' }} d="M162.733 941.325H176.701C176.701 941.325 195.808 831.863 200.758 797.7C207.695 749.85 161.57 735.019 155.045 815.588C152.195 850.781 162.733 941.325 162.733 941.325Z" fill="#4B764A" />
            <motion.path initial={{ rotate: 0 }} animate={{
            rotate: [0, 6, -2, 5, -2, 0],
            transition: {
                duration: 15,
                repeat: Infinity,
                repeatType: "loop",
            },
            }} d="M169.952 953.287C169.772 953.283 169.601 953.211 169.473 953.086C169.344 952.961 169.267 952.792 169.258 952.612C167.027 907.481 177.227 804.694 179.983 794.475C180.008 794.386 180.049 794.303 180.106 794.231C180.163 794.158 180.233 794.098 180.313 794.053C180.393 794.007 180.482 793.978 180.573 793.967C180.664 793.956 180.757 793.963 180.845 793.987C180.934 794.012 181.017 794.054 181.089 794.111C181.162 794.167 181.223 794.238 181.268 794.318C181.313 794.398 181.342 794.486 181.353 794.577C181.364 794.669 181.358 794.761 181.333 794.85C179.083 803.156 168.32 905.475 170.645 952.537C170.651 952.723 170.583 952.904 170.457 953.04C170.331 953.177 170.156 953.259 169.97 953.269L169.952 953.287Z" fill="black" />
            <motion.path initial={{ rotate: 0 }} animate={{
            rotate: [0, 6, -2, 5, -2, 0],
            transition: {
                duration: 10,
                repeat: Infinity,
                repeatType: "loop",
            },
            }} d="M145.352 942.881C123.845 911.719 98.1767 862.575 99.9579 840.375C101.833 817.744 128.983 820.988 141.527 866.625C152.664 907.05 166.464 947.531 166.464 947.531L145.352 942.881Z" fill="#4B764A" />
            <path d="M157.708 951.806C157.558 951.806 157.413 951.759 157.291 951.671C157.17 951.584 157.08 951.461 157.033 951.319C153.901 941.325 134.345 889.2 121.858 866.156C121.813 866.075 121.785 865.986 121.775 865.894C121.766 865.802 121.774 865.709 121.8 865.62C121.826 865.531 121.87 865.448 121.928 865.376C121.986 865.304 122.058 865.244 122.139 865.2C122.22 865.156 122.309 865.128 122.401 865.118C122.493 865.108 122.586 865.117 122.675 865.143C122.764 865.169 122.847 865.212 122.919 865.27C122.991 865.328 123.051 865.4 123.095 865.481C135.639 888.6 155.233 940.875 158.383 950.888C158.411 950.976 158.422 951.07 158.414 951.162C158.406 951.255 158.379 951.345 158.335 951.428C158.291 951.51 158.232 951.583 158.159 951.641C158.087 951.7 158.003 951.743 157.914 951.769L157.708 951.806Z" fill="black" />
            <path d="M198.152 937.969H129.414C104.87 956.062 99.2454 997.669 135.995 1026.56H191.589C228.283 997.669 222.639 956.062 198.152 937.969Z" fill="#E4D0C0" />
            <path d="M215.476 961.875H112.089V963.281H215.476V961.875Z" fill="#F6EEE8" />
            <path d="M110.184 970.785L110.11 972.189L217.754 977.869L217.829 976.464L110.184 970.785Z" fill="#F6EEE8" />
            <path d="M217.278 984.541L110.55 986.348L110.574 987.754L217.302 985.947L217.278 984.541Z" fill="#F6EEE8" />
            <motion.path initial={{ scaleY: 1 }}
                animate={blinkAnimation}
                style={{ transformOrigin: "center" }} d="M723.556 377.143C723.748 375.831 723.298 374.679 722.55 374.57C721.802 374.46 721.04 375.434 720.847 376.745C720.655 378.057 721.105 379.209 721.853 379.319C722.601 379.428 723.363 378.454 723.556 377.143Z" fill="black" />
            <motion.path initial={{ scaleY: 1 }}
                animate={blinkAnimation}
                style={{ transformOrigin: "center" }} d="M986.917 413.728C987.109 412.417 986.659 411.265 985.911 411.155C985.163 411.045 984.401 412.019 984.208 413.33C984.016 414.642 984.466 415.794 985.214 415.904C985.962 416.014 986.724 415.039 986.917 413.728Z" fill="black" />
            <motion.path initial={{ scaleY: 1 }}
                animate={blinkAnimation}
                style={{ transformOrigin: "center" }} d="M842.826 256.29C843.018 254.978 842.568 253.826 841.82 253.716C841.072 253.606 840.31 254.581 840.117 255.892C839.925 257.203 840.375 258.356 841.123 258.465C841.871 258.575 842.633 257.601 842.826 256.29Z" fill="black" />
            <motion.path initial={{ scaleY: 1 }}
                animate={blinkAnimation}
                style={{ transformOrigin: "center" }} d="M828.827 256.484C829.02 255.173 828.57 254.021 827.822 253.911C827.074 253.801 826.312 254.775 826.119 256.087C825.927 257.398 826.377 258.55 827.125 258.66C827.873 258.77 828.635 257.796 828.827 256.484Z" fill="black" />
            <path d="M1041 460.388H1040.83C1040.65 460.343 1040.5 460.23 1040.4 460.072C1040.3 459.915 1040.27 459.725 1040.31 459.544C1044.06 443.475 1048.11 433.988 1057.61 433.05C1057.8 433.033 1057.99 433.09 1058.13 433.21C1058.27 433.329 1058.36 433.501 1058.38 433.688C1058.4 433.874 1058.34 434.06 1058.22 434.204C1058.1 434.348 1057.93 434.439 1057.74 434.456C1050.08 435.225 1045.88 441.956 1041.66 459.881C1041.61 460.024 1041.53 460.149 1041.41 460.24C1041.29 460.331 1041.15 460.382 1041 460.388Z" fill="black" />
            <path d="M982.37 497.663C982.184 497.653 982.01 497.571 981.884 497.434C981.757 497.298 981.69 497.117 981.695 496.931C981.995 486.356 978.47 465.506 978.432 465.281C978.416 465.19 978.419 465.097 978.439 465.007C978.459 464.916 978.496 464.831 978.549 464.755C978.603 464.679 978.67 464.615 978.748 464.565C978.826 464.516 978.913 464.482 979.004 464.466C979.095 464.45 979.189 464.452 979.279 464.472C979.369 464.492 979.455 464.53 979.531 464.583C979.606 464.636 979.671 464.703 979.721 464.781C979.77 464.859 979.804 464.946 979.82 465.038C979.82 465.244 983.401 486.244 983.101 496.913C983.106 497.011 983.091 497.109 983.057 497.201C983.022 497.293 982.969 497.377 982.9 497.448C982.831 497.518 982.749 497.574 982.657 497.611C982.566 497.648 982.468 497.665 982.37 497.663Z" fill="black" />
            <path d="M1087.37 550.031C1087.22 550.039 1087.07 549.996 1086.94 549.91C1086.82 549.823 1086.72 549.697 1086.67 549.552C1086.63 549.407 1086.63 549.25 1086.68 549.106C1086.73 548.962 1086.83 548.839 1086.96 548.756C1089.32 547.013 1086.96 524.381 1084.33 500.494C1083.41 491.906 1082.46 483.038 1081.58 473.85C1081.56 473.664 1081.62 473.478 1081.74 473.334C1081.86 473.19 1082.03 473.099 1082.21 473.081C1082.4 473.064 1082.59 473.121 1082.73 473.241C1082.87 473.361 1082.97 473.532 1082.98 473.719C1083.88 482.906 1084.86 491.775 1085.74 500.363C1089.08 531.45 1090.69 547.781 1087.8 549.9C1087.68 549.991 1087.52 550.037 1087.37 550.031Z" fill="black" />
            <path d="M1030.69 544.294C1030.6 544.285 1030.51 544.257 1030.42 544.212C1030.34 544.167 1030.27 544.106 1030.21 544.032C1030.15 543.959 1030.11 543.874 1030.09 543.784C1030.06 543.693 1030.06 543.599 1030.07 543.506C1032.66 522 1038.86 483.281 1038.94 482.888C1038.97 482.704 1039.07 482.539 1039.22 482.43C1039.37 482.321 1039.56 482.277 1039.75 482.306C1039.93 482.336 1040.09 482.438 1040.2 482.589C1040.31 482.74 1040.36 482.929 1040.33 483.113C1040.33 483.506 1034.05 522.206 1031.48 543.675C1031.45 543.861 1031.36 544.03 1031.21 544.146C1031.06 544.262 1030.88 544.315 1030.69 544.294Z" fill="black" />
            <path d="M1088.51 548.888C1088.68 553.106 1072.69 553.65 1072.69 553.369C1072.69 553.088 1073.68 547.088 1074.56 542.119C1082.06 542.55 1087.91 544.144 1088.55 548.719C1088.56 548.619 1088.56 548.519 1088.55 548.419C1088.55 548.576 1088.54 548.733 1088.51 548.888Z" fill="black" />
            <path d="M949.426 452.663C949.296 452.667 949.167 452.635 949.054 452.569C948.941 452.504 948.849 452.407 948.789 452.292C948.728 452.176 948.701 452.046 948.711 451.916C948.721 451.786 948.768 451.661 948.845 451.556C956.739 439.894 954.133 429.206 952.876 424.069C952.745 423.506 952.614 423.019 952.539 422.606C951.996 420.257 952.119 417.802 952.895 415.519C953.176 414.356 953.533 412.894 953.926 410.906L954.133 409.838C955.691 403.456 956.204 396.864 955.651 390.319C955.664 390.154 955.733 389.999 955.847 389.88C955.961 389.761 956.112 389.685 956.276 389.665C956.44 389.645 956.605 389.682 956.744 389.771C956.883 389.859 956.988 389.993 957.039 390.15C957.614 396.846 957.095 403.59 955.501 410.119L955.295 411.169C954.901 413.194 954.545 414.675 954.245 415.856C953.52 417.937 953.403 420.181 953.908 422.325C953.908 422.719 954.114 423.188 954.245 423.731C955.539 429.056 958.258 440.156 950.008 452.325C949.947 452.426 949.862 452.51 949.761 452.569C949.659 452.628 949.544 452.66 949.426 452.663Z" fill="black" />
            <path d="M958.295 418.256H958.145C958.053 418.239 957.965 418.202 957.888 418.15C957.81 418.097 957.743 418.03 957.693 417.951C957.642 417.872 957.608 417.784 957.592 417.691C957.576 417.599 957.579 417.504 957.601 417.413C959.256 409.829 960.452 402.153 961.183 394.425C961.183 394.239 961.257 394.06 961.389 393.928C961.52 393.796 961.699 393.722 961.886 393.722C962.072 393.722 962.251 393.796 962.383 393.928C962.515 394.06 962.589 394.239 962.589 394.425C961.857 402.222 960.655 409.967 958.989 417.619C958.975 417.793 958.895 417.955 958.767 418.073C958.638 418.192 958.47 418.257 958.295 418.256Z" fill="black" />
            <path d="M961.895 413.775C961.78 413.774 961.666 413.745 961.564 413.69C961.462 413.635 961.375 413.557 961.31 413.461C961.246 413.365 961.206 413.254 961.193 413.139C961.181 413.024 961.196 412.908 961.239 412.8L962.627 409.519C962.662 409.434 962.714 409.357 962.78 409.292C962.845 409.227 962.923 409.176 963.008 409.141C963.094 409.106 963.185 409.089 963.277 409.089C963.369 409.09 963.46 409.108 963.545 409.144C963.63 409.18 963.707 409.232 963.772 409.297C963.837 409.363 963.888 409.44 963.923 409.526C963.958 409.611 963.976 409.702 963.975 409.794C963.975 409.887 963.956 409.978 963.92 410.063L962.533 413.344C962.481 413.471 962.393 413.579 962.279 413.656C962.166 413.733 962.032 413.774 961.895 413.775Z" fill="black" />
            <path d="M1076.78 542.344C1075.82 549.394 1073.48 565.969 1071.15 577.05C1068.21 591.45 1056.55 597.038 1033.97 601.781C1011.4 606.525 949.145 601.781 949.145 601.781L954.095 560.119L1029.09 557.063L1030.71 543.581C1030.71 543.581 1045.07 542.906 1058.93 542.325C1064.27 542.1 1069.76 541.913 1074.53 542.175C1075.28 542.213 1076.12 542.269 1076.78 542.344Z" fill="#FFDCAE" />
            <path d="M507.995 329.831C507.895 329.831 507.796 329.809 507.706 329.767C507.615 329.725 507.535 329.664 507.47 329.588L503.87 325.444C503.81 325.374 503.764 325.292 503.735 325.204C503.706 325.116 503.695 325.024 503.702 324.931C503.709 324.839 503.734 324.749 503.775 324.667C503.817 324.584 503.875 324.51 503.945 324.45C504.015 324.39 504.097 324.344 504.184 324.315C504.272 324.286 504.365 324.275 504.457 324.282C504.55 324.289 504.64 324.314 504.722 324.356C504.805 324.397 504.878 324.455 504.939 324.525L508.539 328.669C508.623 328.772 508.676 328.896 508.693 329.027C508.71 329.158 508.691 329.292 508.636 329.413C508.582 329.533 508.494 329.637 508.385 329.711C508.275 329.784 508.146 329.826 508.014 329.831H507.995Z" fill="black" />
            <path d="M532.239 343.144C532.076 343.128 531.923 343.057 531.806 342.941C531.69 342.826 531.616 342.674 531.599 342.511C531.581 342.348 531.62 342.184 531.71 342.047C531.799 341.909 531.933 341.807 532.089 341.756L533.083 341.569C535.445 341.138 535.802 341.063 536.983 336.581C538.558 330.638 540.527 325.838 548.777 327.394C548.94 327.448 549.078 327.56 549.166 327.709C549.253 327.858 549.283 328.033 549.251 328.202C549.219 328.372 549.127 328.524 548.991 328.63C548.856 328.737 548.686 328.79 548.514 328.781C541.427 327.45 539.814 331.331 538.333 336.956C536.983 342.094 536.177 342.431 533.327 342.975L532.352 343.163L532.239 343.144Z" fill="black" />
            <path d="M536.12 324.056C536.011 324.057 535.903 324.032 535.805 323.983C535.708 323.934 535.623 323.863 535.558 323.775C534.547 322.891 533.311 322.304 531.988 322.079C530.664 321.854 529.304 322 528.058 322.5C527.892 322.535 527.72 322.51 527.571 322.429C527.422 322.348 527.307 322.217 527.246 322.059C527.185 321.901 527.182 321.727 527.238 321.567C527.294 321.407 527.405 321.273 527.552 321.188C529.072 320.585 530.733 320.43 532.338 320.742C533.943 321.055 535.425 321.821 536.608 322.95C536.683 323.055 536.727 323.179 536.737 323.308C536.747 323.437 536.722 323.566 536.664 323.681C536.607 323.797 536.518 323.895 536.409 323.964C536.3 324.033 536.174 324.072 536.045 324.075L536.12 324.056Z" fill="black" />
            <path d="M522.114 346.012C522.32 346.894 523.801 347.456 525.208 347.138C526.614 346.819 528.245 345.262 528.039 344.456C527.833 343.65 526.07 343.313 524.645 343.65C523.22 343.988 521.926 345.131 522.114 346.012Z" fill="white" />
            <path d="M545.908 351.413C544.638 355.516 543.129 359.542 541.389 363.469C541.389 363.469 547.483 362.006 543.658 374.269C543.658 374.269 546.395 374.775 543.658 381.113C540.838 387.221 538.657 393.605 537.152 400.163C536.027 404.831 534.864 410.7 533.72 418.2C532.675 416.808 531.229 415.768 529.577 415.219C521.402 412.369 496.914 407.306 496.914 407.306C498.133 402.881 499.558 397.538 501.189 391.238L501.414 390.356C507.039 368.175 504.433 347.981 507.62 346.8C510.808 345.619 512.364 355.65 516.039 360.75C519.714 365.85 530.045 361.65 535.558 354.075C541.07 346.5 542.758 341.156 544.708 339.806C546.658 338.456 548.777 342.731 545.908 351.413Z" fill="#C15D31" />
            <path d="M537.152 400.163C536.027 404.831 534.864 410.7 533.72 418.2C532.675 416.808 531.229 415.767 529.577 415.219C521.402 412.369 496.914 407.306 496.914 407.306C498.133 402.881 499.558 397.538 501.189 391.238L537.152 400.163Z" fill="#124E66" />
            <path d="M501.414 391.144H501.245C501.064 391.095 500.91 390.978 500.815 390.816C500.721 390.655 500.693 390.463 500.739 390.281C503.193 379.887 504.582 369.27 504.883 358.594C505.314 350.306 505.576 346.913 507.376 346.256C509.908 345.319 511.37 348.938 513.001 353.138C513.912 355.701 515.107 358.154 516.564 360.45C517.07 361.092 517.727 361.598 518.476 361.926C519.225 362.253 520.043 362.39 520.858 362.325C525.395 362.325 531.32 358.706 534.939 353.7C537.124 350.65 539.109 347.463 540.883 344.156C541.672 342.323 542.82 340.666 544.258 339.281C544.332 339.209 544.422 339.155 544.52 339.121C544.619 339.087 544.723 339.075 544.826 339.086C544.93 339.097 545.029 339.13 545.118 339.183C545.207 339.237 545.284 339.309 545.342 339.395C545.4 339.481 545.439 339.579 545.455 339.681C545.472 339.784 545.466 339.889 545.438 339.988C545.409 340.088 545.36 340.181 545.292 340.26C545.224 340.338 545.14 340.401 545.045 340.444C543.816 341.731 542.82 343.222 542.101 344.85C540.301 348.209 538.284 351.447 536.064 354.544C532.201 359.869 525.789 363.75 520.839 363.75C519.794 363.807 518.752 363.605 517.804 363.164C516.856 362.723 516.031 362.055 515.401 361.219C513.866 358.833 512.607 356.278 511.651 353.606C510.358 350.4 509.026 347.063 507.77 347.531C506.514 348 506.476 353.363 506.195 358.631C505.887 369.371 504.486 380.051 502.014 390.506C502.002 390.665 501.936 390.816 501.827 390.932C501.718 391.048 501.572 391.123 501.414 391.144Z" fill="black" />
            <path d="M542.177 379.894H541.933C541.757 379.83 541.614 379.7 541.533 379.532C541.453 379.364 541.441 379.17 541.502 378.994C542.076 377.422 542.564 375.819 542.964 374.194C543.011 374.012 543.129 373.857 543.29 373.762C543.452 373.667 543.645 373.64 543.827 373.688C544.008 373.735 544.163 373.852 544.258 374.014C544.353 374.176 544.38 374.369 544.333 374.55C543.923 376.22 543.422 377.866 542.833 379.481C542.775 379.606 542.682 379.711 542.566 379.785C542.449 379.858 542.314 379.896 542.177 379.894Z" fill="black" />
            <path d="M537.451 373.219C537.329 373.217 537.209 373.184 537.103 373.123C536.997 373.061 536.908 372.974 536.846 372.868C536.784 372.763 536.75 372.643 536.748 372.52C536.745 372.398 536.775 372.277 536.833 372.169C538.316 369.293 539.613 366.324 540.714 363.281C540.781 363.107 540.915 362.967 541.085 362.891C541.256 362.816 541.449 362.811 541.623 362.878C541.797 362.945 541.938 363.079 542.013 363.249C542.089 363.42 542.093 363.614 542.026 363.788C540.905 366.898 539.577 369.93 538.051 372.863C537.992 372.97 537.905 373.059 537.799 373.122C537.694 373.184 537.574 373.218 537.451 373.219Z" fill="black" />
            <path d="M483.114 452.175H482.908C482.73 452.119 482.581 451.995 482.493 451.83C482.405 451.666 482.386 451.473 482.439 451.294L496.22 407.306C496.236 407.212 496.271 407.122 496.322 407.042C496.374 406.961 496.441 406.892 496.52 406.838C496.596 406.785 496.681 406.748 496.771 406.729C496.861 406.709 496.955 406.708 497.045 406.725C498.039 406.931 521.664 411.825 529.801 414.656C531.588 415.251 533.151 416.376 534.283 417.881C538.314 423.15 537.151 432.581 535.464 439.556C535.419 439.738 535.304 439.894 535.144 439.991C534.984 440.088 534.792 440.117 534.611 440.072C534.429 440.027 534.273 439.912 534.176 439.752C534.08 439.592 534.05 439.4 534.095 439.219C535.708 432.544 536.851 423.563 533.158 418.725C532.211 417.456 530.904 416.503 529.408 415.988C522.095 413.438 501.283 409.031 497.533 408.225L483.92 451.706C483.864 451.868 483.752 452.005 483.604 452.091C483.456 452.177 483.282 452.207 483.114 452.175Z" fill="black" />
            <path d="M506.008 410.063H505.839C505.659 410.018 505.503 409.905 505.405 409.747C505.307 409.59 505.274 409.4 505.314 409.219L509.064 393.3C509.106 393.119 509.219 392.961 509.377 392.863C509.535 392.764 509.726 392.733 509.908 392.775C510.089 392.817 510.246 392.93 510.345 393.088C510.443 393.247 510.475 393.437 510.433 393.619L506.683 409.538C506.645 409.688 506.558 409.821 506.436 409.917C506.313 410.012 506.163 410.063 506.008 410.063Z" fill="black" />
            <path d="M516.358 412.406H516.208C516.115 412.389 516.028 412.353 515.95 412.3C515.872 412.248 515.806 412.18 515.755 412.101C515.704 412.022 515.67 411.934 515.654 411.841C515.639 411.749 515.642 411.654 515.664 411.563L519.114 395.813C519.154 395.629 519.266 395.469 519.424 395.367C519.582 395.265 519.774 395.23 519.958 395.269C520.05 395.287 520.137 395.323 520.215 395.375C520.293 395.428 520.359 395.496 520.41 395.574C520.461 395.653 520.495 395.742 520.511 395.834C520.527 395.927 520.523 396.021 520.501 396.113L517.051 411.863C517.017 412.02 516.928 412.16 516.802 412.259C516.675 412.358 516.518 412.41 516.358 412.406Z" fill="black" />
            <path d="M526.126 414.938H525.995C525.812 414.901 525.65 414.794 525.545 414.64C525.44 414.486 525.4 414.296 525.433 414.113L528.545 398.156C528.582 397.973 528.688 397.812 528.843 397.706C528.997 397.601 529.186 397.561 529.37 397.594C529.553 397.63 529.715 397.737 529.82 397.891C529.925 398.046 529.966 398.235 529.933 398.419L526.745 414.356C526.718 414.507 526.643 414.644 526.532 414.749C526.42 414.854 526.278 414.92 526.126 414.938Z" fill="black" />
            <path d="M483.77 361.875C483.666 361.875 483.563 361.852 483.468 361.806C483.374 361.761 483.292 361.695 483.227 361.613C478.272 355.316 473.88 348.596 470.102 341.531C470.015 341.365 469.997 341.17 470.053 340.991C470.11 340.812 470.235 340.662 470.402 340.575C470.568 340.488 470.762 340.471 470.942 340.527C471.121 340.583 471.271 340.709 471.358 340.875C475.085 347.879 479.421 354.542 484.314 360.788C484.401 360.89 484.456 361.014 484.474 361.147C484.491 361.28 484.47 361.415 484.413 361.536C484.355 361.657 484.264 361.758 484.15 361.829C484.036 361.899 483.904 361.934 483.77 361.931V361.875Z" fill="black" />
            <path d="M494.176 400.275C495.688 400.275 496.914 399.049 496.914 397.538C496.914 396.026 495.688 394.8 494.176 394.8C492.665 394.8 491.439 396.026 491.439 397.538C491.439 399.049 492.665 400.275 494.176 400.275Z" fill="#4B764A" />
            <path d="M490.033 430.162C489.903 430.161 489.777 430.124 489.667 430.054C489.558 429.984 489.47 429.886 489.414 429.769C482.195 415.519 472.802 392.681 471.227 386.981C471.181 386.887 471.156 386.784 471.154 386.68C471.153 386.575 471.174 386.472 471.217 386.376C471.26 386.281 471.323 386.196 471.403 386.128C471.482 386.06 471.575 386.01 471.676 385.982C471.777 385.954 471.883 385.949 471.986 385.966C472.089 385.983 472.187 386.023 472.273 386.083C472.359 386.143 472.43 386.22 472.483 386.311C472.535 386.401 472.567 386.502 472.577 386.606C474.133 392.231 483.47 414.956 490.652 429.131C490.701 429.238 490.724 429.355 490.717 429.472C490.71 429.589 490.675 429.703 490.614 429.803C490.552 429.903 490.467 429.987 490.366 430.046C490.265 430.106 490.15 430.139 490.033 430.144V430.162Z" fill="black" />
            <path d="M481.745 503.794H481.614C462.677 500.194 447.227 474.056 418.052 396.187C417.987 396.013 417.994 395.821 418.071 395.652C418.149 395.483 418.29 395.352 418.464 395.287C418.638 395.223 418.831 395.23 419 395.307C419.168 395.385 419.299 395.526 419.364 395.7C448.333 473.006 463.52 498.937 481.877 502.406C482.032 502.455 482.166 502.556 482.257 502.692C482.347 502.828 482.388 502.991 482.372 503.153C482.357 503.315 482.286 503.468 482.172 503.584C482.058 503.701 481.907 503.775 481.745 503.794Z" fill="black" />
            <path d="M388.108 970.406C387.925 970.402 387.752 970.327 387.623 970.198C387.493 970.069 387.419 969.895 387.414 969.713V879.15C387.441 878.983 387.527 878.832 387.656 878.722C387.785 878.613 387.948 878.553 388.117 878.553C388.286 878.553 388.45 878.613 388.578 878.722C388.707 878.832 388.793 878.983 388.82 879.15V969.713C388.815 969.898 388.738 970.075 388.605 970.204C388.472 970.334 388.294 970.406 388.108 970.406Z" fill="black" />
            <path d="M470.814 907.35C470.63 907.35 470.453 907.277 470.323 907.147C470.193 907.017 470.12 906.84 470.12 906.656C470.12 892.631 470.72 873.919 471.433 852.281C472.014 834.938 472.67 815.269 473.045 795.863C473.05 795.677 473.127 795.501 473.26 795.371C473.393 795.241 473.572 795.169 473.758 795.169C473.85 795.169 473.942 795.187 474.028 795.223C474.113 795.26 474.19 795.312 474.255 795.379C474.32 795.445 474.37 795.524 474.404 795.61C474.438 795.697 474.454 795.789 474.451 795.881C474.095 815.306 473.42 834.975 472.839 852.319C472.108 873.975 471.489 892.669 471.526 906.694C471.529 906.787 471.513 906.879 471.479 906.965C471.445 907.052 471.395 907.13 471.33 907.197C471.265 907.263 471.188 907.316 471.103 907.352C471.017 907.388 470.925 907.406 470.833 907.406L470.814 907.35Z" fill="black" />
            <path d="M474.77 726C474.584 725.99 474.41 725.908 474.284 725.772C474.158 725.635 474.09 725.455 474.095 725.269C474.695 705.413 475.557 684.319 476.326 665.719C477.357 640.931 478.201 619.519 478.201 607.331C478.201 576.488 480.17 562.781 485.232 558.45C485.374 558.328 485.559 558.268 485.745 558.282C485.931 558.296 486.104 558.383 486.226 558.525C486.348 558.667 486.409 558.851 486.395 559.037C486.381 559.224 486.293 559.397 486.151 559.519C481.501 563.513 479.607 577.369 479.607 607.35C479.607 619.556 478.726 640.988 477.732 665.794C476.964 684.394 476.101 705.488 475.501 725.325C475.492 725.511 475.41 725.685 475.273 725.811C475.136 725.938 474.956 726.005 474.77 726Z" fill="black" />
            <path d="M475.876 1026.56H386.12C386.12 1026.56 386.12 1024.31 386.495 1015.31C403.97 1016.03 422.345 1015.88 426.564 1013.44C431.308 1010.66 435.395 1004.94 441.939 1004.94H455.251C456.44 1006.89 457.898 1008.66 459.583 1010.19L449.908 1011.81L458.87 1018.91C458.87 1018.91 465.958 1018.71 471.433 1018.5C472.97 1019.34 474.47 1020.09 475.876 1020.75C484.314 1024.54 482.795 1026.56 475.876 1026.56Z" fill="#F09400" />
            <path d="M365.158 1025.18C361.408 1027.41 351.564 1024.71 348.114 1021.61C344.664 1018.52 334.22 1004.91 324.151 986.925C314.083 968.944 311.364 962.138 310.201 957.675C309.039 953.213 309.095 952.05 315.17 946.425L324.545 937.913C324.545 943.838 326.983 951.506 332.195 961.106C338.495 972.731 349.201 989.419 349.201 989.419C351.464 989.161 353.753 989.295 355.97 989.813C357.564 990.338 361.07 995.138 363.47 998.738C362.431 1000.7 361.548 1002.73 360.826 1004.83L353.964 1000.71L353.12 1010.08L360.189 1013.83C360.251 1014.01 360.333 1014.19 360.433 1014.36C363.226 1018.91 368.908 1022.94 365.158 1025.18Z" fill="#F09400" />
            <motion.path
                d="M531.036 329.833C531.45 328.952 531.375 328.045 530.869 327.807C530.363 327.569 529.617 328.091 529.203 328.972C528.789 329.853 528.863 330.76 529.369 330.997C529.875 331.235 530.621 330.714 531.036 329.833Z"
                fill="black"
                initial={{ scaleY: 1 }}
                animate={blinkAnimation}
                style={{ transformOrigin: "center" }}
            />
            <motion.path
                d="M543.465 333.639C543.879 332.758 543.804 331.851 543.298 331.613C542.792 331.375 542.046 331.896 541.632 332.777C541.218 333.658 541.293 334.565 541.799 334.803C542.305 335.041 543.051 334.52 543.465 333.639Z"
                fill="black"
                initial={{ scaleY: 1 }}
                animate={blinkAnimation}
                style={{ transformOrigin: "center" }}
            />
            {/* <path d="M531.036 329.833C531.45 328.952 531.375 328.045 530.869 327.807C530.363 327.569 529.617 328.091 529.203 328.972C528.789 329.853 528.863 330.76 529.369 330.997C529.875 331.235 530.621 330.714 531.036 329.833Z" fill="black" />
            <path d="M543.465 333.639C543.879 332.758 543.804 331.851 543.298 331.613C542.792 331.375 542.046 331.896 541.632 332.777C541.218 333.658 541.293 334.565 541.799 334.803C542.305 335.041 543.051 334.52 543.465 333.639Z" fill="black" /> */}
            <path d="M586.745 602.025C586.645 602.025 586.547 602.003 586.456 601.961C586.365 601.919 586.285 601.857 586.22 601.781C581.158 595.894 563.158 590.813 562.97 590.756C562.821 590.683 562.701 590.56 562.633 590.408C562.565 590.257 562.552 590.086 562.596 589.925C562.641 589.765 562.74 589.625 562.877 589.531C563.014 589.436 563.179 589.392 563.345 589.406C564.095 589.613 581.945 594.656 587.289 600.881C587.355 600.946 587.408 601.023 587.444 601.109C587.48 601.194 587.499 601.286 587.499 601.378C587.499 601.471 587.48 601.562 587.444 601.648C587.408 601.733 587.355 601.81 587.289 601.875C587.214 601.937 587.127 601.982 587.033 602.008C586.939 602.034 586.841 602.04 586.745 602.025Z" fill="black" />
            <path d="M621.283 602.025C621.162 602.024 621.044 601.992 620.939 601.933C620.834 601.874 620.746 601.79 620.683 601.687C619.633 600 611.533 598.237 604.389 596.681C599.551 595.631 594.526 594.525 590.664 593.306C590.576 593.278 590.495 593.233 590.425 593.173C590.355 593.113 590.298 593.041 590.256 592.959C590.214 592.877 590.189 592.788 590.182 592.696C590.175 592.604 590.185 592.512 590.214 592.425C590.242 592.338 590.287 592.257 590.347 592.187C590.407 592.117 590.479 592.059 590.561 592.017C590.643 591.976 590.732 591.95 590.824 591.943C590.915 591.936 591.008 591.947 591.095 591.975C594.845 593.175 599.889 594.262 604.689 595.312C614.514 597.45 620.589 598.875 621.882 600.937C621.981 601.097 622.012 601.289 621.97 601.471C621.928 601.654 621.816 601.812 621.657 601.912C621.546 601.985 621.416 602.024 621.283 602.025Z" fill="black" />
            <path d="M628.52 595.725H628.389C622.933 594.675 619.239 594.225 615.995 593.85C609.916 593.268 603.947 591.848 598.258 589.631C598.099 589.554 597.974 589.421 597.908 589.257C597.842 589.094 597.839 588.911 597.9 588.746C597.961 588.58 598.081 588.443 598.237 588.36C598.393 588.278 598.574 588.257 598.745 588.3C604.339 590.479 610.207 591.873 616.183 592.444C619.464 592.856 623.158 593.325 628.67 594.319C628.854 594.336 629.024 594.426 629.141 594.569C629.259 594.711 629.316 594.894 629.298 595.078C629.281 595.262 629.191 595.432 629.049 595.55C628.906 595.667 628.723 595.724 628.539 595.706L628.52 595.725Z" fill="black" />
            <path d="M716.12 507.263H715.914C715.736 507.206 715.587 507.083 715.499 506.918C715.412 506.753 715.392 506.56 715.445 506.381L726.076 471.338C726.149 471.181 726.275 471.057 726.432 470.987C726.589 470.916 726.767 470.905 726.931 470.956C727.096 471.006 727.237 471.114 727.328 471.26C727.419 471.406 727.454 471.58 727.426 471.75L716.795 506.794C716.747 506.934 716.656 507.055 716.535 507.139C716.413 507.223 716.268 507.267 716.12 507.263Z" fill="black" />
            <path d="M803.383 597.337C803.284 597.338 803.187 597.319 803.097 597.28C803.006 597.241 802.925 597.184 802.858 597.112C802.858 597.112 799.108 592.875 794.758 586.95C792.035 583.325 789.059 579.898 785.851 576.694C783.545 574.256 780.92 571.5 777.583 567.787C777.503 567.722 777.438 567.641 777.393 567.548C777.348 567.455 777.324 567.354 777.321 567.251C777.319 567.148 777.339 567.046 777.38 566.952C777.421 566.857 777.482 566.773 777.559 566.704C777.636 566.636 777.727 566.585 777.825 566.555C777.924 566.525 778.027 566.516 778.129 566.53C778.231 566.544 778.329 566.579 778.416 566.635C778.503 566.69 778.577 566.763 778.633 566.85C781.97 570.6 784.576 573.3 786.883 575.737C790.138 578.987 793.158 582.465 795.92 586.144C800.158 592.012 803.889 596.137 803.926 596.175C804.025 596.274 804.091 596.401 804.116 596.538C804.141 596.676 804.124 596.817 804.067 596.945C804.011 597.073 803.917 597.18 803.798 597.253C803.679 597.327 803.541 597.363 803.401 597.356L803.383 597.337Z" fill="black" />
            <path d="M793.22 597.581C793.115 597.581 793.012 597.558 792.918 597.512C792.824 597.467 792.741 597.401 792.676 597.319C789.807 593.813 777.507 579.225 770.551 572.175C770.453 572.038 770.407 571.87 770.421 571.702C770.435 571.535 770.508 571.377 770.628 571.258C770.747 571.139 770.904 571.066 771.072 571.052C771.24 571.038 771.408 571.084 771.545 571.181C778.52 578.269 790.876 592.894 793.745 596.419C793.806 596.49 793.851 596.572 793.88 596.661C793.908 596.749 793.918 596.843 793.909 596.936C793.9 597.028 793.873 597.118 793.829 597.2C793.784 597.282 793.724 597.355 793.651 597.413C793.531 597.517 793.379 597.576 793.22 597.581Z" fill="black" />
            <path d="M780.358 597.338C780.23 597.336 780.106 597.3 779.997 597.235C779.888 597.169 779.799 597.075 779.739 596.963C777.995 593.738 771.02 586.481 765.433 580.65L761.57 576.619C761.46 576.479 761.406 576.303 761.419 576.126C761.432 575.949 761.511 575.783 761.64 575.66C761.77 575.538 761.94 575.469 762.118 575.466C762.296 575.463 762.468 575.526 762.601 575.644L766.445 579.675C772.389 585.881 779.139 592.913 780.976 596.288C781.036 596.394 781.066 596.514 781.064 596.636C781.062 596.758 781.028 596.878 780.965 596.982C780.903 597.087 780.813 597.173 780.707 597.232C780.6 597.291 780.48 597.321 780.358 597.319V597.338Z" fill="black" />
            <path d="M691.745 356.4C691.652 356.419 691.557 356.419 691.464 356.4H691.014C688.864 356.387 686.739 355.934 684.77 355.069C684.628 354.974 684.525 354.831 684.48 354.667C684.434 354.503 684.449 354.327 684.521 354.173C684.594 354.019 684.72 353.896 684.875 353.826C685.031 353.756 685.207 353.745 685.37 353.794C687.146 354.566 689.059 354.974 690.995 354.994C691.726 354.994 692.158 354.994 692.326 355.556C692.356 355.644 692.367 355.736 692.36 355.828C692.353 355.92 692.328 356.009 692.286 356.091C692.244 356.173 692.186 356.246 692.116 356.306C692.046 356.365 691.964 356.41 691.876 356.437L691.745 356.4Z" fill="black" />
            <path d="M885.376 782.231C885.224 782.233 885.075 782.184 884.953 782.093C884.831 782.001 884.742 781.872 884.701 781.725C882.826 775.144 880.633 767.175 878.101 757.95C866.533 715.763 849.039 651.994 838.726 643.706C838.619 643.58 838.56 643.42 838.558 643.254C838.556 643.088 838.613 642.927 838.717 642.798C838.821 642.669 838.967 642.581 839.13 642.548C839.293 642.515 839.461 642.54 839.607 642.619C850.239 651.188 867.114 712.688 879.432 757.594C881.964 766.819 884.139 774.769 886.032 781.35C886.058 781.439 886.066 781.532 886.055 781.623C886.044 781.715 886.015 781.803 885.97 781.884C885.924 781.964 885.863 782.034 885.791 782.091C885.718 782.147 885.634 782.189 885.545 782.213L885.376 782.231Z" fill="black" />
            <path d="M768.77 705.206C768.627 705.21 768.487 705.169 768.368 705.089C768.25 705.009 768.159 704.895 768.107 704.762C768.056 704.629 768.047 704.483 768.082 704.344C768.117 704.205 768.193 704.081 768.301 703.988L776.083 696.881C776.15 696.819 776.23 696.77 776.316 696.738C776.403 696.706 776.495 696.691 776.587 696.694C776.68 696.698 776.77 696.719 776.854 696.758C776.938 696.797 777.014 696.851 777.076 696.919C777.139 696.987 777.188 697.066 777.22 697.153C777.252 697.239 777.267 697.331 777.264 697.424C777.26 697.516 777.238 697.607 777.2 697.69C777.161 697.774 777.107 697.85 777.039 697.913L769.258 705.019C769.126 705.143 768.951 705.21 768.77 705.206Z" fill="black" />
            <path d="M822.901 892.95C822.749 892.952 822.6 892.903 822.478 892.811C822.356 892.72 822.268 892.591 822.226 892.444C821.964 891.488 795.789 797.138 783.883 756.975C783.857 756.887 783.849 756.794 783.859 756.702C783.869 756.61 783.897 756.521 783.941 756.44C783.986 756.359 784.045 756.288 784.117 756.23C784.189 756.172 784.272 756.129 784.361 756.103C784.449 756.077 784.542 756.069 784.634 756.079C784.726 756.089 784.815 756.117 784.896 756.162C784.977 756.206 785.048 756.266 785.106 756.338C785.164 756.41 785.207 756.493 785.233 756.581C797.158 796.744 823.333 891.113 823.595 892.069C823.642 892.248 823.617 892.438 823.526 892.599C823.435 892.76 823.285 892.879 823.108 892.931L822.901 892.95Z" fill="black" />
            <path d="M732.226 702.525H571.051C567.427 702.525 564.489 705.463 564.489 709.088C564.489 712.712 567.427 715.65 571.051 715.65H732.226C735.851 715.65 738.789 712.712 738.789 709.088C738.789 705.463 735.851 702.525 732.226 702.525Z" fill="#E4D0C0" />
            <path d="M651.639 1015.61C651.457 1015.61 651.283 1015.53 651.154 1015.4C651.025 1015.28 650.95 1015.1 650.945 1014.92V712.331C650.929 712.229 650.934 712.125 650.962 712.026C650.99 711.926 651.039 711.834 651.105 711.755C651.172 711.676 651.256 711.613 651.349 711.57C651.443 711.526 651.545 711.504 651.649 711.504C651.752 711.504 651.854 711.526 651.948 711.57C652.041 711.613 652.125 711.676 652.192 711.755C652.258 711.834 652.307 711.926 652.335 712.026C652.363 712.125 652.368 712.229 652.352 712.331V1014.92C652.347 1015.1 652.27 1015.28 652.136 1015.41C652.003 1015.54 651.825 1015.61 651.639 1015.61Z" fill="black" />
            <path d="M689.495 1013.42C665.364 1008.38 656.12 1004.53 651.639 998.888C647.158 1004.51 637.914 1008.38 613.783 1013.42C588.77 1018.65 585.264 1026.54 585.208 1026.54H717.995C717.995 1026.54 714.508 1018.65 689.495 1013.42Z" fill="#E4D0C0" />
            <path d="M845.926 1014.54L837.47 1013.61C837.284 1013.58 837.115 1013.49 836.999 1013.34C836.883 1013.19 836.83 1013.01 836.851 1012.82C836.875 1012.64 836.968 1012.47 837.111 1012.35C837.254 1012.24 837.437 1012.18 837.62 1012.2L846.076 1013.14C846.243 1013.16 846.395 1013.25 846.504 1013.38C846.613 1013.51 846.674 1013.67 846.674 1013.84C846.674 1014.01 846.613 1014.17 846.504 1014.3C846.395 1014.43 846.243 1014.52 846.076 1014.54H845.926Z" fill="black" />
            <path d="M848.251 1019.79L841.089 1019.12C840.903 1019.1 840.732 1019.01 840.613 1018.86C840.494 1018.72 840.436 1018.54 840.451 1018.35C840.47 1018.16 840.561 1017.99 840.705 1017.87C840.849 1017.75 841.034 1017.7 841.22 1017.71L848.383 1018.39C848.569 1018.39 848.748 1018.46 848.88 1018.59C849.012 1018.73 849.086 1018.9 849.086 1019.09C849.086 1019.28 849.012 1019.46 848.88 1019.59C848.748 1019.72 848.569 1019.79 848.383 1019.79H848.251Z" fill="black" />
            <path d="M821.795 1012.67H812.589C812.422 1012.64 812.271 1012.56 812.161 1012.43C812.052 1012.3 811.992 1012.13 811.992 1011.97C811.992 1011.8 812.052 1011.63 812.161 1011.5C812.271 1011.38 812.422 1011.29 812.589 1011.26H821.795C821.962 1011.29 822.114 1011.38 822.223 1011.5C822.332 1011.63 822.393 1011.8 822.393 1011.97C822.393 1012.13 822.332 1012.3 822.223 1012.43C822.114 1012.56 821.962 1012.64 821.795 1012.67Z" fill="black" />
            <path d="M826.745 1017.23H817.914C817.812 1017.24 817.708 1017.24 817.608 1017.21C817.509 1017.18 817.416 1017.13 817.338 1017.07C817.259 1017 817.196 1016.91 817.152 1016.82C817.109 1016.73 817.086 1016.63 817.086 1016.52C817.086 1016.42 817.109 1016.32 817.152 1016.22C817.196 1016.13 817.259 1016.05 817.338 1015.98C817.416 1015.91 817.509 1015.86 817.608 1015.84C817.708 1015.81 817.812 1015.8 817.914 1015.82H826.745C826.912 1015.85 827.064 1015.93 827.173 1016.06C827.283 1016.19 827.343 1016.35 827.343 1016.52C827.343 1016.69 827.283 1016.85 827.173 1016.98C827.064 1017.11 826.912 1017.2 826.745 1017.23Z" fill="black" />
            <path d="M820.464 484.725C820.281 484.72 820.108 484.646 819.978 484.517C819.849 484.388 819.775 484.214 819.77 484.031V473.831C819.77 473.645 819.844 473.466 819.976 473.334C820.108 473.202 820.287 473.128 820.473 473.128C820.66 473.128 820.838 473.202 820.97 473.334C821.102 473.466 821.176 473.645 821.176 473.831V484.031C821.171 484.217 821.094 484.393 820.961 484.523C820.828 484.653 820.65 484.725 820.464 484.725Z" fill="black" />
            <path d="M839.87 484.725C839.688 484.72 839.514 484.646 839.385 484.517C839.256 484.388 839.181 484.214 839.176 484.031V473.831C839.204 473.665 839.289 473.513 839.418 473.404C839.547 473.294 839.71 473.234 839.879 473.234C840.048 473.234 840.212 473.294 840.341 473.404C840.469 473.513 840.555 473.665 840.583 473.831V484.031C840.578 484.217 840.5 484.394 840.367 484.523C840.234 484.653 840.056 484.725 839.87 484.725Z" fill="black" />
            <path d="M910.201 424.013C914.57 423.581 919.183 418.388 915.47 417.169C911.758 415.95 872.064 415.688 876.695 423.263C869.42 423.375 864.264 428.325 871.951 430.275C871.951 430.275 870.076 433.838 875.214 436.013C875.214 436.013 874.614 438.019 877.858 440.119C885.845 445.331 900.526 441.338 897.601 436.275C897.601 436.275 905.251 435.713 905.101 430.106C905.026 430.163 911.608 428.194 910.201 424.013Z" fill="#FFC36C" />
            <path d="M895.67 441.281C895.518 441.276 895.373 441.222 895.254 441.127C895.135 441.033 895.05 440.903 895.01 440.756C894.97 440.61 894.978 440.454 895.033 440.313C895.087 440.171 895.186 440.051 895.314 439.969C896.551 439.256 897.826 438.094 896.983 436.594C896.926 436.49 896.896 436.374 896.896 436.256C896.896 436.138 896.925 436.021 896.981 435.917C897.036 435.813 897.117 435.724 897.216 435.659C897.314 435.594 897.428 435.554 897.545 435.544C897.826 435.544 904.501 434.963 904.333 430.088C904.33 429.933 904.378 429.781 904.47 429.656C904.561 429.531 904.691 429.439 904.839 429.394C906.657 428.892 908.263 427.813 909.414 426.319C909.582 426.001 909.681 425.651 909.704 425.292C909.726 424.934 909.672 424.574 909.545 424.238C909.51 424.138 909.499 424.031 909.512 423.926C909.525 423.821 909.562 423.72 909.62 423.631C909.678 423.542 909.755 423.468 909.846 423.414C909.937 423.36 910.04 423.327 910.145 423.319C912.958 423.038 915.939 420.544 916.145 418.894C916.145 418.556 916.145 418.106 915.264 417.825C915.115 417.745 915 417.616 914.938 417.459C914.876 417.302 914.872 417.129 914.926 416.969C914.98 416.81 915.089 416.674 915.233 416.587C915.377 416.5 915.548 416.467 915.714 416.494C916.29 416.612 916.802 416.94 917.148 417.416C917.495 417.891 917.652 418.478 917.589 419.063C917.326 421.331 914.214 423.938 911.139 424.594C911.264 425.417 911.119 426.259 910.726 426.994C909.539 428.719 907.8 429.988 905.795 430.594C905.608 434.681 901.501 436.388 898.67 436.856C899.064 438.431 898.164 440.006 896.12 441.206C895.982 441.28 895.824 441.306 895.67 441.281Z" fill="black" />
            <path d="M896.27 425.269C889.691 425.311 883.117 424.873 876.602 423.956C876.418 423.929 876.252 423.83 876.141 423.68C876.031 423.531 875.984 423.344 876.011 423.16C876.038 422.976 876.138 422.81 876.287 422.699C876.437 422.588 876.624 422.542 876.808 422.569C887.857 423.987 899.025 424.238 910.127 423.319C910.219 423.309 910.313 423.317 910.402 423.344C910.492 423.37 910.575 423.415 910.646 423.474C910.718 423.534 910.777 423.607 910.82 423.69C910.863 423.773 910.888 423.863 910.895 423.956C910.906 424.049 910.897 424.143 910.871 424.232C910.844 424.321 910.799 424.404 910.74 424.476C910.68 424.548 910.607 424.607 910.524 424.65C910.441 424.693 910.351 424.718 910.258 424.725C910.164 424.725 904.277 425.269 896.27 425.269Z" fill="black" />
            <path d="M886.633 431.775C881.688 431.864 876.744 431.594 871.839 430.969C871.747 430.955 871.659 430.923 871.58 430.875C871.501 430.827 871.432 430.763 871.378 430.688C871.324 430.613 871.285 430.528 871.265 430.438C871.244 430.347 871.241 430.254 871.258 430.163C871.271 430.071 871.303 429.983 871.351 429.904C871.399 429.825 871.463 429.756 871.538 429.702C871.613 429.648 871.698 429.609 871.789 429.588C871.879 429.568 871.973 429.565 872.064 429.581C880.408 430.988 900.995 430.313 904.839 429.413C905.02 429.37 905.211 429.402 905.369 429.5C905.528 429.599 905.64 429.756 905.683 429.938C905.725 430.119 905.693 430.31 905.595 430.468C905.496 430.626 905.339 430.739 905.158 430.781C899.015 431.565 892.824 431.897 886.633 431.775Z" fill="black" />
            <path d="M885.301 437.813C881.841 437.905 878.384 437.527 875.026 436.688C874.847 436.635 874.696 436.514 874.607 436.351C874.517 436.187 874.496 435.995 874.548 435.816C874.6 435.637 874.722 435.486 874.885 435.396C875.049 435.307 875.241 435.285 875.42 435.338C882.92 437.569 897.358 435.6 897.489 435.581C897.655 435.59 897.812 435.657 897.934 435.769C898.056 435.882 898.135 436.033 898.157 436.198C898.179 436.362 898.144 436.529 898.056 436.67C897.968 436.811 897.834 436.917 897.676 436.969C893.573 437.505 889.44 437.787 885.301 437.813Z" fill="black" />
            <path d="M836.889 389.681C836.756 389.681 836.625 389.642 836.514 389.569C830.72 385.819 823.633 382.406 811.108 383.175C810.923 383.181 810.743 383.116 810.603 382.995C810.464 382.873 810.376 382.703 810.358 382.519C810.347 382.333 810.41 382.15 810.533 382.01C810.655 381.87 810.828 381.783 811.014 381.769C820.267 380.858 829.549 383.198 837.264 388.388C837.386 388.474 837.478 388.597 837.526 388.738C837.575 388.88 837.577 389.033 837.534 389.177C837.49 389.32 837.403 389.446 837.283 389.536C837.164 389.627 837.02 389.678 836.87 389.681H836.889Z" fill="black" />
            <path d="M802.689 408.619C802.584 408.643 802.475 408.643 802.37 408.619C800.027 407.419 799.97 405.206 799.933 403.256C800.069 402.062 799.791 400.858 799.145 399.844C797.589 398.119 796.652 394.538 799.052 390.113C799.087 390.017 799.142 389.929 799.215 389.857C799.287 389.784 799.374 389.728 799.47 389.692C799.565 389.656 799.668 389.641 799.77 389.648C799.872 389.655 799.972 389.684 800.061 389.733C800.151 389.782 800.23 389.85 800.291 389.932C800.352 390.014 800.395 390.108 800.417 390.208C800.438 390.308 800.438 390.412 800.416 390.512C800.394 390.612 800.351 390.706 800.289 390.788C798.227 394.538 798.958 397.519 800.195 398.906C801.094 400.161 801.501 401.703 801.339 403.238C801.339 405.113 801.339 406.575 803.008 407.381C803.142 407.458 803.248 407.576 803.309 407.717C803.371 407.859 803.385 408.017 803.349 408.167C803.313 408.317 803.229 408.451 803.11 408.55C802.991 408.648 802.843 408.705 802.689 408.713V408.619Z" fill="black" />
            <path d="M837.527 398.588C837.387 398.591 837.25 398.551 837.133 398.475C830.739 394.219 818.495 392.306 811.52 393.263C811.429 393.275 811.337 393.269 811.248 393.246C811.159 393.222 811.076 393.181 811.002 393.126C810.929 393.07 810.868 393 810.822 392.921C810.775 392.842 810.745 392.754 810.733 392.663C810.721 392.572 810.726 392.479 810.75 392.39C810.773 392.301 810.814 392.218 810.87 392.145C810.926 392.071 810.995 392.01 811.075 391.964C811.154 391.918 811.242 391.887 811.333 391.875C818.552 390.9 831.245 392.888 837.902 397.313C838.024 397.399 838.116 397.522 838.164 397.664C838.212 397.805 838.215 397.958 838.172 398.102C838.128 398.245 838.04 398.371 837.921 398.461C837.802 398.552 837.657 398.603 837.508 398.606L837.527 398.588Z" fill="black" />
            <path d="M833.345 402.488C833.234 402.515 833.119 402.515 833.008 402.488C829.633 400.613 819.376 399.863 813.133 400.369C813.04 400.376 812.948 400.365 812.859 400.337C812.771 400.308 812.69 400.263 812.619 400.203C812.549 400.143 812.491 400.069 812.448 399.987C812.406 399.904 812.381 399.814 812.373 399.722C812.366 399.63 812.377 399.537 812.405 399.449C812.434 399.361 812.479 399.279 812.54 399.208C812.6 399.138 812.673 399.08 812.755 399.038C812.838 398.996 812.928 398.97 813.02 398.963C819.583 398.419 830.045 399.3 833.645 401.25C833.781 401.327 833.887 401.447 833.948 401.59C834.009 401.734 834.021 401.893 833.983 402.044C833.945 402.195 833.858 402.33 833.736 402.427C833.614 402.524 833.464 402.578 833.308 402.581L833.345 402.488Z" fill="black" />
            <path d="M845.27 235.163C841.14 236.245 836.883 236.768 832.614 236.719C827.663 236.719 822.726 236.216 817.876 235.219C817.108 235.219 813.826 238.969 813.564 239.738C813.518 241.968 813.637 244.199 813.92 246.413C814.07 248.981 810.17 255.619 810.17 255.619C810.17 255.619 807.658 252.375 805.595 252.6C805.595 252.6 802.483 222.338 824.083 221.55C835.726 221.044 842.064 225.788 845.27 235.163Z" fill="#C15D31" />
            <path d="M807.995 259.913C807.87 259.914 807.747 259.882 807.639 259.819L804.358 257.944C804.196 257.849 804.079 257.695 804.031 257.514C803.984 257.332 804.01 257.14 804.105 256.978C804.199 256.817 804.354 256.699 804.535 256.652C804.716 256.604 804.909 256.631 805.07 256.725L808.352 258.6C808.495 258.672 808.609 258.791 808.674 258.937C808.74 259.083 808.752 259.248 808.71 259.402C808.668 259.557 808.574 259.692 808.444 259.785C808.314 259.878 808.155 259.923 807.995 259.913Z" fill="black" />
            <path d="M821.889 251.831C821.753 251.829 821.621 251.788 821.507 251.713C821.394 251.638 821.305 251.532 821.249 251.408C821.194 251.284 821.176 251.147 821.196 251.012C821.216 250.878 821.275 250.752 821.364 250.65C822.412 249.575 823.713 248.781 825.148 248.338C826.582 247.896 828.105 247.821 829.576 248.119C829.713 248.228 829.804 248.383 829.833 248.555C829.862 248.727 829.827 248.904 829.735 249.052C829.642 249.2 829.499 249.309 829.331 249.358C829.164 249.407 828.984 249.393 828.826 249.319C827.654 249.223 826.474 249.377 825.365 249.771C824.256 250.166 823.244 250.791 822.395 251.606C822.331 251.677 822.253 251.733 822.166 251.771C822.078 251.81 821.984 251.831 821.889 251.831Z" fill="black" />
            <path d="M835.352 267.9C835.197 267.893 835.05 267.836 834.931 267.737C834.812 267.639 834.728 267.504 834.692 267.354C834.656 267.204 834.67 267.046 834.731 266.904C834.793 266.763 834.899 266.645 835.033 266.569C836.533 265.819 838.239 264.581 838.333 263.962C838.215 263.144 838.014 262.34 837.733 261.562C837.172 259.811 836.721 258.026 836.383 256.219C836.155 255.025 836.205 253.795 836.531 252.624C836.856 251.454 837.447 250.374 838.258 249.469C839.089 248.657 840.107 248.062 841.221 247.735C842.336 247.409 843.514 247.36 844.652 247.594C844.743 247.606 844.831 247.636 844.91 247.682C844.989 247.729 845.059 247.79 845.115 247.863C845.171 247.936 845.211 248.02 845.235 248.109C845.258 248.197 845.264 248.29 845.252 248.381C845.239 248.472 845.209 248.56 845.163 248.64C845.117 248.719 845.055 248.789 844.982 248.844C844.909 248.9 844.826 248.941 844.737 248.964C844.648 248.988 844.555 248.994 844.464 248.981C843.543 248.791 842.59 248.826 841.685 249.084C840.781 249.342 839.953 249.815 839.27 250.462C838.612 251.207 838.135 252.094 837.874 253.053C837.614 254.012 837.579 255.018 837.77 255.994C838.1 257.744 838.539 259.473 839.083 261.169C839.488 262.137 839.705 263.175 839.72 264.225C839.42 265.894 836.289 267.544 835.652 267.862C835.556 267.896 835.453 267.909 835.352 267.9Z" fill="black" />
            <path d="M830.158 273.263C827.556 272.644 825.273 271.089 823.745 268.894C823.697 268.815 823.665 268.728 823.651 268.636C823.637 268.545 823.641 268.452 823.662 268.363C823.684 268.273 823.723 268.188 823.778 268.114C823.832 268.039 823.901 267.976 823.98 267.928C824.058 267.88 824.146 267.848 824.237 267.834C824.328 267.82 824.421 267.824 824.511 267.846C824.601 267.867 824.685 267.906 824.76 267.961C824.834 268.015 824.897 268.084 824.945 268.163C826.242 269.982 828.12 271.304 830.27 271.913C830.437 271.94 830.589 272.026 830.698 272.155C830.807 272.283 830.867 272.447 830.867 272.616C830.867 272.785 830.807 272.948 830.698 273.077C830.589 273.206 830.437 273.292 830.27 273.319L830.158 273.263Z" fill="black" />
            <path d="M838.783 296.119C822.47 291.825 819.789 284.756 819.639 284.25C820.164 284.569 829.37 289.875 838.202 286.238C838.202 286.238 838.483 290.85 838.783 296.119Z" fill="black" />
            <path d="M1104.56 838.913H1104.45C1104.27 838.885 1104.1 838.784 1103.99 838.633C1103.87 838.482 1103.82 838.293 1103.85 838.106C1119.47 733.838 1117.46 643.106 1108.56 599.363C1108.54 599.188 1108.59 599.015 1108.7 598.875C1108.8 598.735 1108.95 598.638 1109.13 598.603C1109.3 598.569 1109.48 598.598 1109.63 598.686C1109.78 598.774 1109.89 598.915 1109.95 599.081C1118.89 642.9 1120.9 733.875 1105.26 838.313C1105.23 838.478 1105.15 838.629 1105.02 838.739C1104.89 838.849 1104.73 838.91 1104.56 838.913Z" fill="black" />
            <path d="M1142.31 995.944C1138.41 1012.95 1135.28 1026.41 1135.13 1026.56C1134.98 1026.71 1124.33 1026.56 1124.33 1026.56L1122.45 1019.06L1116.66 1026.56C1116.66 1026.56 1036.67 1028.19 1035.88 1026.56C1035.1 1024.93 1039.78 1023.81 1049.95 1019.06C1060.11 1014.32 1067.14 1008.75 1068.55 1006.11C1070.11 1001.68 1071.37 997.139 1072.3 992.531C1073.13 991.779 1074.19 991.335 1075.31 991.274C1076.43 991.212 1077.54 991.537 1078.45 992.194C1084.69 996.731 1089.38 1010.68 1092.51 1010.94C1098.28 1011.41 1109.38 1006.26 1122.04 1001.1C1129.13 998.288 1136.33 996.019 1142.31 995.944Z" fill="#8EBF8C" />
            <path d="M455.495 1027.28H455.345C455.162 1027.23 455.002 1027.12 454.9 1026.97C454.798 1026.81 454.763 1026.62 454.802 1026.43L538.277 634.294C539.827 627.133 543.777 620.717 549.471 616.107C555.165 611.497 562.263 608.97 569.589 608.944H619.202C619.388 608.944 619.567 609.018 619.699 609.15C619.831 609.282 619.905 609.46 619.905 609.647C619.905 609.833 619.831 610.012 619.699 610.144C619.567 610.276 619.388 610.35 619.202 610.35H569.627C562.613 610.361 555.814 612.77 550.358 617.176C544.901 621.582 541.115 627.721 539.627 634.575L456.227 1026.71C456.191 1026.88 456.098 1027.03 455.964 1027.13C455.83 1027.23 455.664 1027.28 455.495 1027.28Z" fill="black" />
            <path d="M1149.11 1027.28C1148.95 1027.27 1148.79 1027.22 1148.67 1027.12C1148.54 1027.01 1148.45 1026.87 1148.42 1026.71L1064.87 634.538C1063.38 627.684 1059.6 621.545 1054.14 617.139C1048.68 612.732 1041.88 610.324 1034.87 610.312H985.389C985.222 610.285 985.07 610.199 984.961 610.071C984.852 609.942 984.792 609.778 984.792 609.609C984.792 609.44 984.852 609.277 984.961 609.148C985.07 609.019 985.222 608.934 985.389 608.906H1035C1042.33 608.936 1049.42 611.464 1055.12 616.074C1060.81 620.683 1064.76 627.097 1066.31 634.256L1149.79 1026.41C1149.83 1026.6 1149.79 1026.79 1149.69 1026.95C1149.59 1027.1 1149.43 1027.22 1149.25 1027.26L1149.11 1027.28Z" fill="black" />
            <path d="M1193.59 835.313C1193.4 835.294 1193.23 835.202 1193.11 835.059C1192.99 834.915 1192.94 834.73 1192.95 834.544C1194.49 816.581 1196.16 799.256 1197.77 782.494C1199.18 767.944 1200.62 752.888 1201.93 737.869C1201.95 737.683 1202.04 737.512 1202.19 737.393C1202.33 737.274 1202.52 737.216 1202.7 737.231C1202.89 737.25 1203.06 737.342 1203.18 737.485C1203.3 737.629 1203.35 737.814 1203.34 738C1202.03 753 1200.58 768 1199.18 782.625C1197.56 799.369 1195.9 816.694 1194.36 834.656C1194.35 834.751 1194.33 834.844 1194.29 834.929C1194.25 835.014 1194.19 835.089 1194.12 835.151C1194.04 835.212 1193.96 835.258 1193.87 835.286C1193.78 835.314 1193.68 835.323 1193.59 835.313Z" fill="black" />
            <path d="M770.814 457.931C770.686 457.932 770.561 457.897 770.452 457.831C770.343 457.765 770.254 457.67 770.195 457.556C765.808 449.344 766.295 426.319 767.345 404.906C767.589 400.013 767.87 395.138 768.151 390.413C768.564 383.344 768.939 376.688 769.164 370.8C769.166 370.707 769.187 370.616 769.225 370.531C769.264 370.447 769.319 370.371 769.387 370.308C769.455 370.245 769.535 370.196 769.622 370.165C769.71 370.134 769.802 370.12 769.895 370.125C769.988 370.127 770.079 370.148 770.164 370.187C770.248 370.225 770.324 370.28 770.387 370.348C770.45 370.416 770.499 370.496 770.53 370.584C770.562 370.671 770.575 370.764 770.57 370.856C770.326 376.744 769.951 383.419 769.558 390.488C769.295 395.213 769.014 400.088 768.751 404.981C767.701 426.225 767.214 448.988 771.451 456.9C771.497 456.981 771.526 457.07 771.537 457.163C771.548 457.255 771.54 457.349 771.513 457.438C771.487 457.527 771.443 457.61 771.384 457.682C771.325 457.754 771.252 457.813 771.17 457.856C771.06 457.913 770.937 457.938 770.814 457.931Z" fill="black" />
            <path d="M723.077 593.456H849.245V604.913H711.602C711.602 603.407 711.899 601.915 712.475 600.524C713.052 599.133 713.898 597.869 714.964 596.805C716.029 595.741 717.295 594.898 718.687 594.323C720.079 593.748 721.571 593.454 723.077 593.456Z" fill="black" />
            <path d="M960.076 603.637H820.464L852.339 491.606C852.722 490.261 853.534 489.077 854.65 488.235C855.767 487.392 857.128 486.937 858.526 486.938H986.851C987.601 486.939 988.339 487.113 989.01 487.447C989.681 487.781 990.265 488.266 990.717 488.863C991.169 489.46 991.477 490.154 991.616 490.89C991.756 491.627 991.723 492.385 991.52 493.106L960.076 603.637Z" fill="black" />
            <path d="M1093.5 598.444H511.145C507.179 598.444 503.964 601.659 503.964 605.625V605.644C503.964 609.61 507.179 612.825 511.145 612.825H1093.5C1097.47 612.825 1100.68 609.61 1100.68 605.644V605.625C1100.68 601.659 1097.47 598.444 1093.5 598.444Z" fill="#E4D0C0" />
            <path d="M1212.08 1027.5H61.5952C61.3466 1027.5 61.1081 1027.4 60.9323 1027.23C60.7565 1027.05 60.6577 1026.81 60.6577 1026.56C60.6577 1026.31 60.7565 1026.08 60.9323 1025.9C61.1081 1025.72 61.3466 1025.62 61.5952 1025.62H1212.08C1212.33 1025.62 1212.56 1025.72 1212.74 1025.9C1212.92 1026.08 1213.01 1026.31 1213.01 1026.56C1213.01 1026.81 1212.92 1027.05 1212.74 1027.23C1212.56 1027.4 1212.33 1027.5 1212.08 1027.5Z" fill="#E4D0C0" />
            <path d="M590.889 569.587L580.089 570.412L567.789 571.331L557.402 572.119L546.339 572.963C546.133 571.256 545.964 569.55 545.795 567.863C545.627 566.175 545.458 564.319 545.327 562.631L545.214 561.019C545.214 559.95 545.064 558.938 545.008 557.963C545.008 557.475 545.008 557.006 545.008 556.556C545.017 556.463 545.017 556.369 545.008 556.275C545.008 555.619 545.008 554.981 545.008 554.4C546.02 554.4 550.277 554.269 555.733 554.081L566.72 553.725L577.783 553.35L587.158 553.031C587.784 558.671 589.035 564.224 590.889 569.587Z" fill="#124E66" />
            <path d="M557.401 572.831C557.235 572.834 557.074 572.776 556.947 572.669C556.819 572.563 556.735 572.414 556.708 572.25C555.685 566.248 555.059 560.185 554.833 554.1C554.816 553.998 554.822 553.894 554.849 553.794C554.877 553.695 554.926 553.602 554.993 553.524C555.06 553.445 555.143 553.382 555.237 553.338C555.33 553.295 555.432 553.272 555.536 553.272C555.639 553.272 555.741 553.295 555.835 553.338C555.929 553.382 556.012 553.445 556.079 553.524C556.146 553.602 556.195 553.695 556.222 553.794C556.25 553.894 556.256 553.998 556.239 554.1C556.47 560.11 557.096 566.098 558.114 572.025C558.133 572.116 558.133 572.211 558.115 572.302C558.097 572.393 558.06 572.48 558.008 572.557C557.955 572.634 557.888 572.7 557.809 572.75C557.731 572.8 557.643 572.834 557.551 572.85L557.401 572.831Z" fill="black" />
            <path d="M567.789 572.044C567.627 572.043 567.469 571.987 567.343 571.885C567.217 571.782 567.129 571.64 567.095 571.481C565.981 565.626 565.547 559.661 565.801 553.706C565.798 553.612 565.815 553.518 565.852 553.431C565.888 553.343 565.943 553.265 566.012 553.201C566.082 553.137 566.164 553.088 566.254 553.059C566.344 553.03 566.439 553.02 566.533 553.031C566.718 553.041 566.893 553.123 567.019 553.259C567.145 553.396 567.213 553.577 567.208 553.762C566.954 559.604 567.376 565.455 568.464 571.2C568.503 571.384 568.468 571.576 568.366 571.734C568.264 571.892 568.104 572.003 567.92 572.044H567.789Z" fill="black" />
            <path d="M580.089 571.125C579.939 571.128 579.792 571.082 579.67 570.994C579.549 570.906 579.459 570.781 579.414 570.638C577.776 565.025 576.918 559.215 576.864 553.369C576.862 553.276 576.878 553.184 576.911 553.098C576.945 553.011 576.996 552.933 577.06 552.866C577.125 552.8 577.202 552.747 577.288 552.711C577.373 552.675 577.465 552.656 577.558 552.656C577.65 552.654 577.743 552.67 577.829 552.704C577.915 552.737 577.994 552.788 578.06 552.853C578.127 552.917 578.18 552.995 578.216 553.08C578.252 553.166 578.27 553.257 578.27 553.35C578.319 559.062 579.152 564.74 580.745 570.225C580.774 570.312 580.786 570.405 580.779 570.497C580.772 570.588 580.747 570.678 580.705 570.76C580.663 570.842 580.605 570.915 580.535 570.974C580.465 571.034 580.383 571.079 580.295 571.106L580.089 571.125Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_201_585">
                <rect width="1500" height="1125" rx="50" fill="white" />
            </clipPath>
        </defs>
    </motion.svg>
}

const PerfectSolution = () => {
    const navigate = useNavigate()
    return <div className="flex flex-col lg:flex-row items-center justify-between mt-10 lg:ml-[-100px] gap-[20px]">
        <SvgWrapper />
        <motion.div
            initial={{
                opacity: 0,
                x: 50
            }}
            whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                    duration: 2
                }
            }}
            viewport={{ once: true }}
            className="lg:w-[50%]"
        >
            <Heading1 className="text-4xl font-bold">
                Perfect <span className="relative">
                    Solution
                    <span className="absolute bottom-[1px] opacity-80 right-0 left-0 h-2 bg-[#124E66] rounded-lg"></span>
                </span>
                <br /> For Your Company
            </Heading1>
            <Typography className="font-light text-md mt-4">
                At Code Samadhan, we specialize in delivering innovative, efficient, and reliable solutions tailored to meet your business needs. Our expert team excels in task and project management, time optimization, and cutting-edge web and mobile design. We prioritize seamless collaboration and robust development practices, ensuring your projects are completed on time and within budget. Whether you require a sleek website, a user-friendly mobile app, or a comprehensive software solution, Code Samadhan is your trusted partner. We strive to enhance productivity and drive success, providing the perfect solutions to propel your business forward. Trust Code Samadhan for unparalleled results.
            </Typography>
            <Button onClick={() => navigate('/contact')} className="mt-8"
                title={<Typography className="flex items-center gap-2"><FaFreeCodeCamp /> Get Started For Free</Typography>}
            />
        </motion.div>
    </div>
}

export default PerfectSolution