import React from 'react'
import { IoIosArrowRoundForward } from 'react-icons/io'
import Typography from '../semantic/Typography'
import { useNavigate } from 'react-router-dom'


interface LearnMoreProps {
  onClick?:any
}

const LearnMore:React.FC<LearnMoreProps> = ({onClick}) => {
  const navigate = useNavigate()
  return (
    <Typography onClick={onClick} className='cursor-pointer flex items-center gap-2'>Learn More <IoIosArrowRoundForward className='text-2xl hover:translate-x-1 duration-500 cursor-pointer'/></Typography>
    )
}

export default LearnMore