import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { blogInfo } from '../../config/config'
import Prism from 'prismjs';
import Typography from '../../componenets/semantic/Typography';
import GetInTouch from '../../componenets/common/GetInTouch';

const SingleBlog = () => {
    const [selectedState, setSelectedState] = useState<any>({})
    const params = useParams()

    useEffect(() => {
        const data = blogInfo.filter((c: any) => c.id === params.id);
        setSelectedState(data.length > 0 ? data[0] : {})
    }, [])

    const renderCodePalette = (htmlContent: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const codeBlocks = doc.querySelectorAll('pre code');        
        codeBlocks.forEach((block: any) => {
            block.parentNode.classList.add('language-javascript');
            block.classList.add('language-javascript');
            block.innerHTML = Prism.highlight(block.innerHTML, Prism.languages.javascript, 'javascript');
        });
        
        return doc.body.innerHTML;
    }
    
    return (
        <div>
            <div className='flex flex-col lg:flex-row gap-5 justify-between items-center mt-24 mb-20'>
                <div className='flex-1'>
                    <Typography variant='h3'>BLOG</Typography>
                    <Typography className='text-5xl'>{selectedState.title}</Typography>
                </div>
                <div className='flex-1'>
                    <Typography>{selectedState.description}</Typography>
                </div>
            </div>
            <div className='mt-12 px-8 lg:px-20'>
                <div dangerouslySetInnerHTML={{ __html: renderCodePalette(selectedState.content) }} style={{ opacity: 0.7 }}></div>
            </div>
            {/* <GetInTouch /> */}
        </div>
    )
}

export default SingleBlog
