import { motion } from 'framer-motion';

const Bubbles = () => {
    return (
        <motion.svg
            width="77"
            height="93"
            viewBox="0 0 77 93"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            <motion.circle
                cx="24.4462"
                cy="86.4464"
                r="5.26344"
                transform="rotate(105 24.4462 86.4464)"
                fill="#F361A5"
                animate={{
                    scale: [1, 1.3, 1],
                    opacity: [1, 0.5, 1],
                    rotate: [0, 360],
                }}
                transition={{
                    duration: 6,
                    repeat: Infinity,
                    ease: "linear",
                }}
            />
            <motion.circle
                cx="5"
                cy="12"
                r="5"
                fill="#4DCB8E"
                animate={{
                    scale: [1, 1.3, 1],
                    opacity: [1, 0.5, 1],
                    rotate: [0, 360],
                }}
                transition={{
                    duration: 5,
                    repeat: Infinity,
                    ease: "linear",
                    delay: 0.5
                }}
            />
            <motion.path
                d="M39.1806 39.9454C37.9761 39.6227 37.2614 38.3846 37.5841 37.1801C37.9068 35.9756 39.1449 35.2609 40.3494 35.5836C41.5539 35.9063 42.2686 37.1444 41.9459 38.3489C41.6232 39.5534 40.3851 40.2681 39.1806 39.9454Z"
                fill="#5489FA"
                animate={{
                    scale: [1, 1.3, 1],
                    opacity: [1, 0.5, 1],
                    rotate: [0, 360],
                }}
                transition={{
                    duration: 7,
                    repeat: Infinity,
                    ease: "linear",
                    delay: 1
                }}
            />
            <motion.circle
                cx="72.2606"
                cy="4.26081"
                r="3.479"
                transform="rotate(105 72.2606 4.26081)"
                fill="#FBD95E"
                animate={{
                    scale: [1, 1.3, 1],
                    opacity: [1, 0.5, 1],
                    rotate: [0, 360],
                }}
                transition={{
                    duration: 4,
                    repeat: Infinity,
                    ease: "linear",
                    delay: 1.5
                }}
            />
        </motion.svg>
    );
}

export default Bubbles;
