import { Navigate } from 'react-router-dom';
import HocCompoenents from '../componenets/hoc/HocWrapper'


interface RouteType {
  path?: string;
  element?: React.ReactNode;
  errorElement?: React.ReactNode;
  privateRoute: boolean;
  children?: RouteType[];
  index?: any;
  loader?: () => Promise<any>
}

const routes: RouteType[] = [
  {
    privateRoute: false,
    errorElement:<Navigate to='/' />,
    children: [
      {
        index: true,
        path: "/",
        element: <HocCompoenents.Homepage />,
        privateRoute: true,
      },
      {
        index: true,
        path: "/projects",
        element: <HocCompoenents.Projects />,
        privateRoute: true,
      },
      {
        index: true,
        path: "/about",
        element: <HocCompoenents.About />,
        privateRoute: true,
      },
      {
        index: true,
        path: "/projects",
        element: <HocCompoenents.About />,
        privateRoute: true,
      },
      {
        index: true,
        path: "/contact",
        element: <HocCompoenents.Contact />,
        privateRoute: true,
      },
      {
        index: true,
        path: "/team",
        element: <HocCompoenents.Team />,
        privateRoute: true,
      },
      {
        index: true,
        path: "/services",
        element: <HocCompoenents.Services />,
        privateRoute: true,
      },
      {
        index: true,
        path: "/blogs",
        element: <HocCompoenents.Blog />,
        privateRoute: true,
      },
      {
        index: true,
        path: "/services/:id",
        element: <HocCompoenents.SingleService />,
        privateRoute: true,
      },
      {
        index: true,
        path: "/projects/:id",
        element: <HocCompoenents.SingleProject />,
        privateRoute: true,
      },
      {
        index: true,
        path: "/blogs/:id",
        element: <HocCompoenents.SingleBlog />,
        privateRoute: true,
      },
    ]
  },

];

const allRoutes: RouteType[] = routes;
export default allRoutes